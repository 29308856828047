import { Pagination } from 'core/pagination/Pagination';

export type ProductWithPagination = {
  pagination: Pagination,
  products: Product[]
};

export type Product = {
  productId: string;
  name: string;
  category: string;
  listPrice: number;
  imgLink: string;
  multipack: number;
  vendorNumber?: string;
  adServingUrl?: string;
  availability: ProductAvailability;
};

export enum ProductAvailability {
  IN_STOCK = 'in_stock',
  OUT_OF_STOCK = 'out_of_stock'
}

export type SavedTargetingProductSku = {
  skuId: string;
  description: string;
  brand: string;
} & Product;

export type SuggestKeywords = {
  suggestKeyword: string,
  bidPrice: number,
  keywordSearchVolume: number,
  last7DayGrowthRate: number,
  last30DayGrowthRate: number,
  keywordAdCompetition: number,
  keywordVersion: string
};

export type KeywordProposal = {
  searchKeyword: string;
  last7DayRequest: number;
};
