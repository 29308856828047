import { LoginBackground } from 'containers/LoginBackground/LoginBackground';
import { AuthenticationManager } from 'core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './oauthLoginPage.module.scss';
import classnames from 'classnames/bind';
import i18n from 'i18n';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { alertError, alertMessage as alertMessageCallBack } from 'components/AlertDialog';
import loginLogoSvg from 'assets/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { validateEmail, validateEmpty } from 'utils/ValidateUtils';
import { ServerError, ServerErrorStatus } from 'core/ServerError';
import Cookies from 'universal-cookie';
import { OAUTH_PCH_ERROR, OAuthPCHException } from 'core/oauth/OAuthPCH';

const cx = classnames.bind(styles);
const cookies = new Cookies();

export const OAuthLoginPage: React.FC<{
  authenticationManager: AuthenticationManager
}> = ({
  authenticationManager
}) => {

  const location = useLocation();
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [alertMessage, setAlertMessage] = useState<string | undefined>();
  const [error, setError] = useState<Error | null>(null);
  const [formError, setFormError] = useState<{ [key: string]: string | undefined }>({});
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [oauthLoginError, setOAuthLoginError] = useState<string | undefined>();
  const [loadingRedirect, setLoadingRedirect] = useState<boolean>(false);
  const {
    loading,
    callAPIs
  } = useCallAPI();

  const toLoginPage = useCallback(() => {
    setRedirectPath('/');
  }, []);

  const login = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = _.defaultTo(emailInputRef.current?.value, '');
    const password = _.defaultTo(passwordInputRef.current?.value, '');
    const emailError = validateEmail(email);
    const passwordError = validateEmpty(password);
    setFormError({
      email: emailError,
      password: passwordError
    });
    if (!emailError && !passwordError) {
      callAPIs(
        [authenticationManager.login.bind(authenticationManager, email, password)],
        () => {
          toLoginPage();
        },
        (error: any) => {
          if (error instanceof ServerError && error.code === 401) {
            setError(new Error(error.serverMessage));
          } else {
            setError(error as Error);
          }
        }
      );
    }
  }, [callAPIs, authenticationManager, toLoginPage]);

  const oauthRedirect = useCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setLoadingRedirect(true);
    callAPIs(
      [authenticationManager.oauthRedirect.bind(authenticationManager)],
      () => {},
      (error: any) => {
        if (error instanceof ServerError) {
          const message = error.serverMessage ? error.serverMessage : i18n.t<string>('oauthLogin.errors.oauthLoginRedirectFailed');
          setError(new Error(message));
          setLoadingRedirect(false);
        } else {
          setError(error as Error);
          setLoadingRedirect(false);
        }
      });
  }, [callAPIs, authenticationManager]);

  const oauthLogin = useCallback(async (code: string, state: string, hmacState: string) => {
    callAPIs(
      [authenticationManager.oauthLogin.bind(authenticationManager, code, state, hmacState)],
      () => {
        toLoginPage();
      },
      (error: any) => {
        if (error instanceof ServerError) {
          const code: number | string = error.code;
          switch (code) {
            case ServerErrorStatus.OAUTH_LOGIN_INVALID_AGENCY:
              setOAuthLoginError(i18n.t<string>('oauthLoginPage.errors.invalidAgency'));
              break;
            default:
              setOAuthLoginError(i18n.t<string>('oauthLoginPage.errors.invalidAccount'));
              break;
          }
        } else {
          setError(error as Error);
        }
      });
  }, [callAPIs, authenticationManager, toLoginPage]);

  const onEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateEmail(event.target.value);
    setFormError({
      ...formError,
      email: error
    });
  }, [formError]);

  const onPasswordChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const error = validateEmpty(event.target.value);
    setFormError({
      ...formError,
      password: error
    });
  }, [formError]);

  const triggerPasswordVisibility = useCallback(() => {
    setPasswordVisibility(prev => !prev);
  }, []);

  const dismiss = useCallback(() => {
    setError(null);
    toLoginPage();
  }, [toLoginPage]);

  useEffect(() => {
    if (authenticationManager.logined) {
      toLoginPage();
    }
  }, [authenticationManager, toLoginPage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // Return if already detected the oauth login error or alert error
    if (error || oauthLoginError) {
      return;
    }

    // Parse the exception params from the URL
    const exception: OAuthPCHException = {
      error: _.defaultTo(params.get('error'), ''),
      errorDescription: _.defaultTo(params.get('error_description'), '')
    };

    if (exception.error === OAUTH_PCH_ERROR.ACCESS_DENIED) {
      setOAuthLoginError(i18n.t<string>('oauthLoginPage.errors.accessDenied'));
      return;
    } else if (exception.error && exception.errorDescription) {
      setError(new Error(exception.errorDescription));
      return;
    }

    // Parse the code from the URL
    const code = params.get('code');
    // Parse the state from the URL
    const state = params.get('state');
    // Get the stored hmac state from Cookie
    const hmacState = cookies.get('hmacState');

    if (!code || !state || !hmacState || hmacState.length === 0) {
      // Redirect to login page if the code or state is not found or the hmac state is empty
      toLoginPage();
      return;
    }

    // Process the code to login
    oauthLogin(code, state, hmacState);
  }, [location, oauthLoginError, toLoginPage, oauthLogin, error]);

  const loginLabel: string = _.defaultTo(i18n.t<string>('login.form.buttons.login'), 'Login');
  const oauthLoginLabel: string = _.defaultTo(i18n.t<string>('login.form.buttons.oauthLogin'), 'Login with OAuth');
  const emailError = formError['email'];
  const passwordError = formError['password'];
  const emailInputGroupClass = cx(styles.inputGroup, {
    withError: !!emailError
  });
  const passwordInputGroupClass = cx(styles.inputGroup, {
    withError: !!passwordError,
    lastChild: true
  });
  const oauthLoginBtnClass = cx('btn', 'btn-primary', { oauthLogin: true });
  return (
    <LoginBackground>
      <div className={styles.loginForm}>
        {(loading || loadingRedirect) && <LoadingIndicator/>}
        {redirectPath && <Redirect to={redirectPath} />}
        {alertMessage &&
          alertMessageCallBack(
            i18n.t<string>('common.warning'),
            alertMessage,
            () => {
              setAlertMessage(undefined);
            }
          )
        }
        <form onSubmit={login}>
          <div className={styles.iconGroup}>
            <div>
              <img
                className={styles.formTitle}
                src={loginLogoSvg}
                alt='tenmax icon'
              />
            </div>
          </div>
          <div className={emailInputGroupClass}>
            <label>{i18n.t<string>('loginPage.labels.emailTitle')}</label>
            <input
              type='email'
              ref={emailInputRef}
              placeholder={i18n.t<string>('login.form.placeholders.email')}
              onChange={onEmailChange}
            />
            {!!emailError && <div className={styles.inputError}>{emailError}</div>}
          </div>
          <div className={passwordInputGroupClass}>
            <label>{i18n.t<string>('loginPage.labels.passwordTitle')}</label>
            <input
              className={styles.passwordInput}
              type={passwordVisibility ? 'text' : 'password'}
              ref={passwordInputRef}
              placeholder={i18n.t<string>('login.form.placeholders.password')}
              onChange={onPasswordChange}
            />
            <FontAwesomeIcon icon={faEye} onClick={triggerPasswordVisibility} />
            {!!passwordError && <div className={styles.inputError}>{passwordError}</div>}
          </div>
          <button
            type='submit'
            className='btn btn-primary'
          >
            {loginLabel}
          </button>
          <Link className={styles.underscoreText} to='/forgot-password'>
            {i18n.t<string>('loginPage.labels.forgotPassword')}
          </Link>
          <span className={styles.loginOrText}>
            {i18n.t<string>('loginPage.labels.orText')}
          </span>
          <button
            type='button'
            className={oauthLoginBtnClass}
            onClick={oauthRedirect}
          >
            {oauthLoginLabel}
          </button>
          {oauthLoginError &&
            <span className={styles.oauthLoginError}>
              {oauthLoginError}
            </span>
          }
        </form>
        {error && alertError(error, dismiss)}
      </div>
    </LoginBackground>
  );
};
