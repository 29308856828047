import { CloudCost } from 'core/cloudCost/CloudCost';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CloudCostFormProps } from './CloudCostForm';
import { useCreateCloudCostFormModel } from './CloustCostFormModel';
import { CloudCostManager, DefaultCloudCostManager } from 'core/cloudCost/CloudCostManager';
import { getContentWithEyeColumnn, sortableColumn } from 'components/TableColumn/TableColumn';
import i18n from 'i18n';
import styles from './cloudCostList.module.scss';

export enum CloudCostColumns {
  DATE = 'ctime',
  CURRENCY_RATE = 'currencyRate',
  CLOUD_COST = 'cloudCost',
  ACCOUNT = 'account'
}

const cloudCostManager: CloudCostManager = new DefaultCloudCostManager();

export const useCloudCostListModel = () => {

  const { loading, callAPIs } = useCallAPI();
  const [cloudCosts, setCloudCosts] = useState<CloudCost[]>([]);
  const [cloudCostFormProps, setCloudCostFormProps] = useState<CloudCostFormProps>();
  const [certificationModalProps, setCertificationModalProps] = useState<{
    title: string;
    certificationUrl: string;
  }>();

  const getCloudCosts = useCallback(() => {
    callAPIs(
      [() => cloudCostManager.getCloudCosts()],
      setCloudCosts
    );
  }, [callAPIs]);

  useEffect(() => {
    getCloudCosts();
  }, [getCloudCosts]);

  const columns = useMemo(() => {
    return [
      sortableColumn(CloudCostColumns.DATE, i18n.t<string>('cloudCostList.headers.date')),
      getContentWithEyeColumnn(
        sortableColumn(CloudCostColumns.CURRENCY_RATE, i18n.t<string>('cloudCostList.headers.currencyRate')),
        i18n.t<string>('cloudCostList.buttons.showCurrencyRateCertification'),
        (cloudCost: CloudCost) => setCertificationModalProps({
          title: i18n.t<string>('cloudCostList.headers.currencyRate'),
          certificationUrl: cloudCost.currencyRateEvidence
        }),
        styles.eyeBtn
      ),
      getContentWithEyeColumnn(
        sortableColumn(CloudCostColumns.CLOUD_COST, i18n.t<string>('cloudCostList.headers.cloudCost')),
        i18n.t<string>('cloudCostList.buttons.showCloudCostCertification'),
        (cloudCost: CloudCost) => setCertificationModalProps({
          title: i18n.t<string>('cloudCostList.headers.cloudCost'),
          certificationUrl: cloudCost.cloudCostEvidence
        }),
        styles.eyeBtn
      ),
      sortableColumn(CloudCostColumns.ACCOUNT, i18n.t<string>('cloudCostList.headers.account'))
    ];
  }, []);

  const closeCloudCostFormModal = useCallback(() => {
    setCloudCostFormProps(undefined);
  }, []);

  const onSavedCloudCost = useCallback(() => {
    getCloudCosts();
    closeCloudCostFormModal();
  }, [getCloudCosts, closeCloudCostFormModal]);

  const closeCertificationModal = useCallback(() => {
    setCertificationModalProps(undefined);
  }, []);

  const showCreateCloudCostFormModal = useCallback(() => {
    setCloudCostFormProps({
      onCancel: closeCloudCostFormModal,
      useModel: () => useCreateCloudCostFormModel(onSavedCloudCost)
    });
  }, [onSavedCloudCost, closeCloudCostFormModal]);

  return {
    loading,
    columns,
    cloudCosts,
    cloudCostFormProps,
    certificationModalProps,
    closeCertificationModal,
    showCreateCloudCostFormModal
  };
};
