import i18n from 'i18n';
import { Formik } from 'formik';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Page } from 'components/Page/Page';
import { BidAdjustmentJobFormContent } from './BidAdjustmentJobFormContent';
import { useCreateBidAdjustmentJobFormModel } from './useBidAdjustmentJobFormModel';
import { Redirect } from 'react-router-dom';

export const BidAdjustmentJobForm: React.FC = () => {

  const {
    loading,
    redirect,
    initBidAdjustmentJob,
    allVendorNumberOptions,
    onSubmit,
    validateForm
  } = useCreateBidAdjustmentJobFormModel();

  if (!initBidAdjustmentJob) {
    return <LoadingIndicator />;
  }

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Page title={i18n.t<string>('bidAdjustmentJobForm.titles.create')} spaceBetweenTopAreaAndContent={false}>
      {loading && <LoadingIndicator/>}
      <Formik
        initialValues={initBidAdjustmentJob}
        onSubmit={onSubmit}
        validate={validateForm}
        validateOnBlur={false}
        enableReinitialize
      >
        <BidAdjustmentJobFormContent vendorNumberOptions={allVendorNumberOptions}/>
      </Formik>
    </Page>
  );
};
