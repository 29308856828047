import { L1ObjectObjective } from 'core/l1Object/L1Object';
import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, DailyBudgetPlan, DeliverType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { isEmpty, omitBy } from 'lodash';
export interface DisplayFormModel extends RtbCampaignBasicFormModel {
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractDisplayFormModel extends DefaultRtbCampaignBasicFormModel
  implements DisplayFormModel {

  constructor (mode: 'create' | 'edit', ...args: RtbCampaignBasicFormModelConstructorParams) {
    super(mode, ...args);
    this.defaultDeliverType = DeliverType.STANDARD;
  }

  get campaignAdType (): AdType {
    return AdType.DISPLAY;
  }

  get needSetupAgeGender (): boolean {
    return false;
  }

  get showDeliverType (): boolean {
    return +this.modelDailyBudgetType === DailyBudgetPlan.DAILY;
  }

  getRtbOptionsMap () {
    const optionsMap = super.getRtbOptionsMap();
    return omitBy({
      ...optionsMap,
      [L1ObjectObjective.TRAFFIC]: {
        [RtbCampaignPlanType.RS_CPM]: [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      }
    }, isEmpty);
  }

  setupDefaultCampaign (campaign: any) {
    super.setupDefaultCampaign(campaign);
    campaign.dailyTargetBudget = 0;
  }
}

export class EditDisplayFormModel extends AbstractDisplayFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateDisplayFormModel extends AbstractDisplayFormModel {

  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
    this.modelDailyBudgetType = DailyBudgetPlan.DAILY;
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
