import { LoadingIndicator } from 'components/LoadingIndicator';
import { Modal } from 'components/Modal/Modal';
import { Formik, FormikProps } from 'formik';
import { useCallback } from 'react';
import i18n from 'i18n';
import { Form } from 'components/form/Form';
import { CloudCostFormContent } from './CloudCostFormContent';
import { CloudCostFormData } from 'core/cloudCost/CloudCost';
import { CloudCostFormModel } from './CloustCostFormModel';

export type CloudCostFormProps = {
  useModel: () => CloudCostFormModel;
  onCancel: () => void;
};

export const CloudCostForm: React.FC<CloudCostFormProps> = ({
  useModel,
  onCancel
}) => {

  const { loading, title, initCloudCost, onSave } = useModel();

  const renderForm = useCallback(
    (formikProps: FormikProps<CloudCostFormData>) => {
      const submitForm = () => formikProps.submitForm();
      return (
        <Modal
          fullScreen={true}
          title={title}
          primaryButton={{
            title: i18n.t<string>('common.buttons.submit'),
            callback: submitForm
          }}
          secondaryButton={{
            title: i18n.t<string>('common.buttons.close'),
            callback: onCancel
          }}
          dismiss={onCancel}
          animation={false}
        >
          <Form
            formikProps={formikProps}
            withNavigationPrompt
          >
            <CloudCostFormContent />
          </Form>
        </Modal>
      );
    },
    [title, onCancel]
  );

  if (!initCloudCost) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {loading && <LoadingIndicator />}
      <Formik
        initialValues={initCloudCost}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        {renderForm}
      </Formik>
    </>
  );
};
