import { BidAjustmentJobWebService, RestfulBidAjustmentJobWebService } from 'ws/BidAjustmentJobWebService';
import { BidAdjustmentJob, BidAdjustmentJobFormData } from './BidAdjustmentJob';
import { omit } from 'lodash';

export interface BidAdjustmentJobManager {
  getBidAdjustmentJobs (): Promise<BidAdjustmentJob[]>;
  createBidAdjustmentJob (bidAdjustmentJobFormData: BidAdjustmentJobFormData): void;
}

export class DefaultBidAdjustmentJobManager implements BidAdjustmentJobManager {

  constructor (
    private bidAdjustmentJobWebService: BidAjustmentJobWebService = new RestfulBidAjustmentJobWebService()
  ) {}

  async getBidAdjustmentJobs (): Promise<BidAdjustmentJob[]> {
    return this.bidAdjustmentJobWebService.getBidAdjustmentJobs();
  }

  async createBidAdjustmentJob (bidAdjustmentJobFormData: BidAdjustmentJobFormData): Promise<void> {
    await this.bidAdjustmentJobWebService.createBidAdjustmentJob(omit(bidAdjustmentJobFormData, 'chooseVendorStrategy'));
  }
}
