import { FormConfig } from 'components/form/FormConfig';
import { BidAdjustmentJobFormData, BidAdjustment, BidPriceConstraintType, ChooseVendorStrategy } from 'core/bidAdjustmentJob/BidAdjustmentJob';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import styles from './bidAdjustmentJobForm.module.scss';
import i18n from 'i18n';
import { Form } from 'components/form/Form';
import moment from 'moment';
import { getByteLength } from 'utils/StringUtil';
import { renderBidAdjustmentFieldArray } from './BidAdjustmentFieldArray';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { flatMap, uniq } from 'lodash';
import { adTypeOptimizeTypeMap, allAdTypeOptions, allConstraintTypeOptions } from './BidAdjustmentField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SelectOptions } from 'components/commonType';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';

const chooseVendorStrategyOptions = createSelectOptionsFromEnum(
  ChooseVendorStrategy,
  'bidAdjustmentJobForm.labels.',
  [],
  (value) => `${value.toLowerCase()}Vendors`
);

export const BidAdjustmentJobFormContent: React.FC<{
  vendorNumberOptions: SelectOptions[];
}> = ({
  vendorNumberOptions
}) => {

  const formikProps = useFormikContext<BidAdjustmentJobFormData>();

  const { values, setFieldValue } = formikProps;

  const {
    purpose,
    chooseVendorStrategy,
    adjustments,
    isInstant
   } = values;
  const purposeLength = getByteLength(purpose);

  const basicSection = useMemo(
    () =>
      new FormConfig.SectionBuilder(
        new FormConfig.FieldsBuilder()
          .addFormikInput({
            label: i18n.t<string>('bidAdjustmentJobForm.labels.purpose'),
            name: 'purpose',
            className: styles.purposeInput,
            postText: `${purposeLength}/50`,
            postTextClassName: styles.countLimit
          })
          .addFormikDatePicker({
            label: i18n.t<string>('bidAdjustmentJobForm.labels.scheduledTime'),
            name: 'scheduledTime',
            showTimePicker: false,
            inputFormat: 'YYYY-MM-DD HH:mm:ss',
            minDate: moment()
              .add(1, 'days')
              .startOf('day')
              .hour(4)
              .format('YYYY-MM-DD HH:mm:ss'),
            disabled: isInstant
          })
          .addFormikCheckbox({
            label: '',
            name: 'isInstant',
            checkboxLabel: i18n.t<string>('bidAdjustmentJobForm.labels.isInstant')
          })
          .build()
      )
        .withTitle(
          i18n.t<string>('bidAdjustmentJobForm.titles.basicSettingSection')
        )
        .build(),
    [purposeLength, isInstant]
  );

  const vendorNumbersSection = useMemo(() => {
    const fieldsBuilder = new FormConfig.FieldsBuilder();
    fieldsBuilder.addFormikRadio({
      label: i18n.t<string>('bidAdjustmentJobForm.labels.chooseVendorStrategy'),
      name: 'chooseVendorStrategy',
      options: chooseVendorStrategyOptions,
      onChange: () => {
        setFieldValue('vendorNumbers', []);
      }
    });
    if (chooseVendorStrategy === ChooseVendorStrategy.PARTIAL) {
      fieldsBuilder.addFormikTags({
        label: '',
        name: 'vendorNumbers',
        placeholder: i18n.t<string>(
          'bidAdjustmentJobForm.placeholders.vendorNumbers'
        ),
        suggestions: vendorNumberOptions,
        onChange: (tagValues: string[]): void => {
          setFieldValue('vendorNumbers', flatMap(tagValues, (tagValue) => tagValue.split(',')));
        }
      });
    }
    return new FormConfig.SectionBuilder(fieldsBuilder.build())
      .withTitle(
        i18n.t<string>('bidAdjustmentJobForm.titles.vendorNumbersSection')
      )
      .build();
  }, [chooseVendorStrategy, vendorNumberOptions, setFieldValue]);

  const availableConstraintAndAdTypeMap = useMemo(() => {
    const allAdTypes = allAdTypeOptions.map((option) => option.value);
    const allConstraintTypes = allConstraintTypeOptions.map((option) => option.value);
    const allTypesMap = allConstraintTypes.reduce((acc: { [key: string]: AdType[] }, constraintType: BidPriceConstraintType) => {
      acc[constraintType] = allAdTypes;
      return acc;
    }, {});
    const usedTypesMap = adjustments.reduce((acc: { [key: string]: AdType[] }, adjustment: BidAdjustment) => {
      const adTypes = acc[adjustment.constraintType] || [];
      acc[adjustment.constraintType] = uniq([...adTypes, adjustment.adType]);
      return acc;
    }, {});
    Object.keys(allTypesMap).forEach((constraintType: string) => {
      const adTypes = usedTypesMap[constraintType];
      allTypesMap[constraintType] = adTypes ? allTypesMap[constraintType].filter((adType) => !adTypes.includes(adType)) : allTypesMap[constraintType];
      if (allTypesMap[constraintType].length === 0) {
        delete allTypesMap[constraintType];
      }
    });
    return allTypesMap;
  }, [adjustments]);

  const bidAdjustmentsSection = useMemo(() => {
    const fieldsBuilder = new FormConfig.FieldsBuilder();
    fieldsBuilder.addFormikCustom({
      name: 'adjustments',
      withFieldLayout: false,
      render: () => renderBidAdjustmentFieldArray(availableConstraintAndAdTypeMap)
    });

    const availableConstraintTypes = Object.keys(availableConstraintAndAdTypeMap);
    if (availableConstraintTypes.length > 0) {
      const defaultConstraintType = availableConstraintTypes[0];
      const defaultAdType = availableConstraintAndAdTypeMap[defaultConstraintType][0];
      const addNewAdjustment = () => {
        setFieldValue('adjustments', [
          ...values.adjustments,
          {
            bidPrice: 0,
            constraintType: defaultConstraintType,
            adType: defaultAdType,
            optimizeType: adTypeOptimizeTypeMap[defaultAdType]
          }
        ]);
      };
      fieldsBuilder.addCustom({
        label: '',
        name: 'addBtn',
        render: () => (
          <div
            className={styles.adjustmentAdder}
            onClick={addNewAdjustment}
          >
            <FontAwesomeIcon icon={faPlus} />
            {i18n.t<string>('bidAdjustmentJobForm.buttons.addBidAdjustment')}
          </div>
        )
      });
    }

    return new FormConfig.SectionBuilder(fieldsBuilder.build())
      .withTitle(
        i18n.t<string>('bidAdjustmentJobForm.titles.bidAdjustmentsSection')
      )
      .build();
  }, [availableConstraintAndAdTypeMap, values, setFieldValue]);

  const formConfig = useMemo(
    () =>
      new FormConfig.Builder()
        .addSection(basicSection)
        .addSection(vendorNumbersSection)
        .addSection(bidAdjustmentsSection)
        .build(),
    [basicSection, vendorNumbersSection, bidAdjustmentsSection]
  );

  const renderFormBtns = useCallback(() => (
    <>
      <Form.SubmitButton>{i18n.t<string>('common.buttons.submit')}</Form.SubmitButton>
      <Form.CancelButton />
    </>
  ), []);

  return (
    <Form
      formikProps={formikProps}
      formConfig={formConfig}
      renderFormBtns={renderFormBtns}
    />
  );
};
