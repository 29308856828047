import { BidAdjustmentJob, BidAdjustmentJobFormData } from 'core/bidAdjustmentJob/BidAdjustmentJob';
import { AxiosInstance } from 'axios';
import client from './RestClient';

export type BidAdjustmentJobCreateDTO = Omit<BidAdjustmentJobFormData, 'chooseVendorStrategy'>;

export interface BidAjustmentJobWebService {
  getBidAdjustmentJobs (): Promise<BidAdjustmentJob[]>;
  createBidAdjustmentJob (bidAdjustmentJobFormData: BidAdjustmentJobCreateDTO): Promise<void>;
}

export class RestfulBidAjustmentJobWebService implements BidAjustmentJobWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getBidAdjustmentJobs (): Promise<BidAdjustmentJob[]> {
    const response = await this.restClient.get('/bid-adjustments');
    return response.data.records;
  }

  async createBidAdjustmentJob (bidAdjustmentJobCreateDTO: BidAdjustmentJobCreateDTO): Promise<void> {
    await this.restClient.post('/bid-adjustments', bidAdjustmentJobCreateDTO);
  }
}
