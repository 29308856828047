import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import styles from './bidAdjustmentJobList.module.scss';
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import { SyntheticEvent, useCallback } from 'react';
import { BidPriceConstraintType } from 'core/bidAdjustmentJob/BidAdjustmentJob';
import { Nav } from 'react-bootstrap';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { Page } from 'components/Page/Page';
import { BidAdjustmentJobColumn, useBidAdjustmentJobListModel } from './BidAdjustmentJobListModel';
import { LoadingIndicator } from 'components/LoadingIndicator';

export const BidAdjustmentJobList: React.FC = () => {

  const {
    loading,
    columns,
    adType,
    constraintType,
    filteredJobs,
    setAdType,
    setConstraintType
  } = useBidAdjustmentJobListModel();

  const renderListOperations = useCallback(() => [
    <Link
      key='new-btn'
      to={'/bid-adjustments/new'}
      className={'btn btn-tertiary btn-sm'}
    >
      {i18n.t<string>('bidAdjustmentJobList.buttons.create')}
    </Link>
  ]
  , []);

  const showAdTypeTabContent = useCallback((tabName: string | null, e: SyntheticEvent<unknown, Event>) => {
    e && e.stopPropagation();
    if (tabName === null) {
      return;
    }
    setAdType(tabName as AdType);
  }, [setAdType]);

  const showConstraintTypeTabContent = useCallback((tabName: string | null, e: SyntheticEvent<unknown, Event>) => {
    e && e.stopPropagation();
    if (tabName === null) {
      return;
    }
    setConstraintType(tabName as BidPriceConstraintType);
  }, [setConstraintType]);

  return (
    <Page title={i18n.t('bidAdjustmentJobHome.title')}>
      {loading && <LoadingIndicator />}
      <div className={styles.bidAdjustmentJobList}>
        <div className={styles.operationArea}>
          {renderListOperations()}
        </div>
        <div className={styles.adTypeNav}>
          <Nav
            activeKey={adType}
            onSelect={showAdTypeTabContent}
          >
            <Nav.Item>
              <Nav.Link eventKey={AdType.KEYWORD}>{i18n.t<string>('adType.keyword')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={AdType.DISPLAY}>{i18n.t<string>('adType.display')}</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className={styles.constraintTypeNav}>
          <Nav
            activeKey={constraintType}
            onSelect={showConstraintTypeTabContent}
          >
            <Nav.Item>
              <Nav.Link eventKey={BidPriceConstraintType.BID_FLOOR}>{i18n.t<string>('bidAdjustmentJob.constraintType.bidFloor')}</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={BidPriceConstraintType.AUTO_BID_CAP}>{i18n.t<string>('bidAdjustmentJob.constraintType.autoBidCap')}</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <StickableBootstrapTable
          customPadding={false}
          keyField={'id'}
          data={filteredJobs}
          columns={columns}
          noDataIndication={i18n.t<string>('bidAdjustmentJobList.placeholders.noData')}
          defaultSorted={[{
            dataField: BidAdjustmentJobColumn.SCHEDULED_TIME,
            order: 'desc'
          }]}
          hidePagination={true}
        />
      </div>
    </Page>
  );
};
