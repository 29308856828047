import { get } from 'lodash';
import styles from './richProductNativeMediaSummary.module.scss';
import { ImagePreview } from 'components/Image/ImagePreview';

type mediaSummaryData = {
  typeProperties: any,
  medias: any
};

export const RichProductNativeMediaSummary: React.FC<{ mediaSummary: mediaSummaryData }> = ({
  mediaSummary
}) => {

  const width = get(mediaSummary, 'medias.image.width');
  const height = get(mediaSummary, 'medias.image.height');
  const imageUrl = get(mediaSummary, 'medias.image.url');
  if (!imageUrl || !width || !height) {
    return <div/>;
  }

  const adServingUrl = get(mediaSummary, 'typeProperties.product.adServingUrl');
  if (!adServingUrl) {
    return <div/>;
  }

  // const asiaMaxUrl = process.env.REACT_APP_ASIAMAX_URL;
  // const templateUrlMap = {
  //   2440: `${asiaMaxUrl}/docs/c90394dd9faf4ed9_template.html`,
  //   960: `${asiaMaxUrl}/docs/d27d4fd5107c45e4_template.html`
  // };
  // const iframeWidthMap = {
  //   2440: 1220,
  //   960: 375
  // };
  // const templateUrl = `${templateUrlMap[width]}?productMETA=${adServingUrl}&bannerImageLink=${imageUrl}`;
  return (
    // <div className={`${styles.richProductNativeMediaSummary} ${styles[`width${width}`]}`}>
    //   <iframe
    //     width={iframeWidthMap[width]}
    //     height={125}
    //     title='richProductNativeMediaSummary'
    //     src={templateUrl}
    //   />
    //   <div className={styles.imageSize}>
    //     {`${width} x ${height}`}
    //   </div>
    // </div>
    <div className={`${styles.richProductNativeMediaSummary}`}>
      <ImagePreview
        url={imageUrl}
        border={false}
        config={{
          showName: false,
          showSize: true
        }}
      />
    </div>
  );
};
