export enum ServerErrorStatus {
  /* OAuth 1600 */
  OAUTH_LOGIN_INVALID_AGENCY = 4011601
}

export class ServerError extends Error {
  code: number | string;
  serverMessage?: string;
  constructor (code: number | string, message: string, serverMessage?: string) {
    super(message);
    this.code = code;
    this.serverMessage = serverMessage;
  }
}
