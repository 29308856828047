import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { ReportManager, DefaultReportManager } from 'core/report/ReportManager';
import { ReportType, ReportDimension, ReportGran, ReportData, Metrics, ECConvDays, ReportTableData } from 'core/report/ReportData';
import { LocaleMeta, MOMENT_TIMEZONE, Actor, AddonFeatureManager, Currency } from 'core';
import { SelectOptions } from 'components/commonType';
import { isSystemAdmin } from 'helper/ActorHelper';
import { getDataProvider } from './ReportTable/ReportDataProviderFactory';
import { ReportDataProvider } from './ReportTable/ReportDataProvider';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { OrderManager, DefaultOrderManager } from 'core/order/OrderManager';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { AgencyManager, DefaultAgencyManager } from 'core/agency/AgencyManager';
import { emptyReportRecord } from './ReportTable/ReportDataHelper';
import moment, { unitOfTime } from 'moment-timezone';
import i18n from 'i18n';
import _ from 'lodash';
import { DateRange } from 'components/DateRangePicker/DateRangePicker';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { CreativeType } from 'core/creative/Creative';
import { DefaultDmpManager, DmpManager } from 'core/dmp/DmpManager';
import { cancelRequest } from 'ws/RestClient';
import { toast } from 'react-toastify';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { ColumnDefinition } from 'components/TableColumn/TableColumn';

export const SUPPORT_TIMEZONE = [
  '+07:00',
  '+08:00'
];

export interface ReportContentModel {
  readonly state: ReportContentState;
  readonly event: UpdateEventListener<ReportContentModel>;
  readonly reportTypes: SelectOptions[];
  readonly reportGrans: SelectOptions[];
  readonly reportTimezones: SelectOptions[];
  readonly ecConvDaysOptions: SelectOptions[];
  readonly isSysAdmin: boolean;
  readonly searchString: string;
  readonly defaultReportType: ReportType;
  readonly defaultReportDimension: ReportDimension;
  readonly defaultReportGran: ReportGran;
  readonly title: string;
  readonly filterHasNameSource: string[];
  readonly dimensionsShowId: ReportDimension[];
  dateTimeShortCut: () => DateRange[];
  initReportData: () => Promise<void>;
  setUpReportTableData: () => Promise<void>;
  getFilterOptions: (filterName: string) => SelectOptions[];
  updateReportType: (type: string | number) => void;
  updateGran: (type: string | number) => void;
  updateTimezone: (type: string) => void;
  updateDayRange: (from: string | undefined, to: string | undefined) => void;
  addFilter: (filterName: string, filterValue: string | number) => void;
  updateReportData: (params) => void;
  updateSearchPath: (replace: boolean) => void;
  queryDataWithFilter: (filterName: string, filterData: SelectOptions) => void;
  queryDataWithDimension: (dimension: string) => void;
  removeFilter: (filterName: string) => void;
  handleOnSearch: (searchString: string) => void;
  handleOnTagFilterClicked: (tag: string) => void;
  toggleShowDimensionSelectArea (): void;
  toggleShowReportChart (): void;
  canFilterSelect (filterName: string): boolean;
  canFilterRemove (filterName: string): boolean;
  download: () => Promise<void>;
  // showAlertModal: () => void;
  hideModal: () => void;
  updateEcConvDays: (ecConvDays: ECConvDays | string) => void;
}

export type ReportContentProps = {
  readonly search: string;
  readonly lang: string;
  readonly customDimensionComponent?: any;
  readonly model: ReportContentModel;
};

export type ReportContentState = {
  readonly loading: boolean;
  readonly reportData?: ReportData;
  readonly reportType: ReportType;
  readonly dimension: ReportDimension;
  readonly gran: ReportGran;
  readonly timezone: string;
  readonly filter: {[key: string]: string | number};
  readonly from: string;
  readonly to: string;
  readonly searchPath: string;
  readonly tableDimensions: ReportDimension[];
  readonly tableColumnSettings: ColumnDefinition[];
  readonly tableData: ReportTableData[];
  readonly selectedTagFilter: string[];
  readonly tags: string[];
  readonly dayRangeError?: string;
  readonly showDimensionSelectArea: boolean;
  readonly showReportChart: boolean;
  readonly modalData: any;
  readonly ecConvDays?: ECConvDays;
};

const MAX_QUERY_DAYS = 93;
const MAX_QUERY_HOURS = 72;

export abstract class AbstactReportContentModel implements ReportContentModel {
  event: FireableUpdateEventListener<ReportContentModel>;
  loading: boolean = true;
  searchString: string = '';
  reportData?: ReportData;
  reportType: ReportType = ReportType.PERFORMANCE;
  dimension: ReportDimension = ReportDimension.DAY;
  gran: ReportGran = ReportGran.DAY;
  timezone: string;
  filter: any = {};
  from: string = moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
  to: string = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
  cachedName: {[key: string]: string} = {};
  filterOptions: {[key: string]: SelectOptions[]} = {};
  cachedReportQuery: any = {};
  filterNameSources: {[key: string]: (id: any) => Promise<string | null>};
  dataProvider: ReportDataProvider;
  filteredRecords: ReportTableData[] = [];
  selectedTagFilter: string[] = [];
  tableData: ReportTableData[] = [];
  tableColumnSettings: ColumnDefinition[] = [];
  reportDimensions: ReportDimension[];
  debouncedUpdateFilteredRecords: any;
  dayRangeError?: string;
  updateSearchPath: (replace: boolean) => void;
  showDimensionSelectArea: boolean = true;
  showReportChart: boolean = true;
  modalData: any;
  dimensionsShowId = [ReportDimension.PRODUCT, ReportDimension.CREATIVE];
  ecConvDays?: ECConvDays;
  ecConvDaysOptions: SelectOptions[] = createSelectOptionsFromEnum(ECConvDays, 'ecConvDays.', [], value => value.toLowerCase());

  constructor (
    private actor: Actor | null,
    protected localeMeta: LocaleMeta | undefined,
    updateSearchPath: (newSearchPath, replace) => void,
    protected addonFeatureManager: AddonFeatureManager,
    protected reportManager: ReportManager = new DefaultReportManager(),
    advertiserManager: AdvertiserManager = new DefaultAdvertiserManager(),
    orderManager: OrderManager = new DefaultOrderManager(),
    creativeManager: CreativeManager = new DefaultCreativeManager(),
    campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager(),
    protected agencyManager: AgencyManager = new DefaultAgencyManager(),
    l1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager(),
    dmpManager: DmpManager = new DefaultDmpManager()
  ) {
    this.event = new FireableUpdateEventListener<ReportContentModel>();
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.ecConvDays);
    this.timezone = this.localeMeta ? this.localeMeta.timezone : '+08:00';
    this.updateSearchPath = (replace: boolean) => {
      updateSearchPath(this.searchPath, replace);
    };
    this.filterNameSources = {
      [ReportDimension.AGENCY]: async id => agencyManager.getAgencyName(id),
      [ReportDimension.ADVERTISER]: async id => advertiserManager.getAdvertiserName(id),
      [ReportDimension.ORDER]: async id => orderManager.getOrderName(id),
      [ReportDimension.L2_CHANNEL]: async id => campaignManager.getCampaignName(id),
      [ReportDimension.L1_OBJECT]: async id => l1ObjectManager.getL1ObjectName(id),
      [ReportDimension.CAMPAIGN_TYPE]: async type => i18n.t<string>(`adType.${type.toLowerCase()}`),
      [ReportDimension.CREATIVE]: async id => creativeManager.getCreativeName(id),
      [ReportDimension.CREATIVE_TYPE]: async type => i18n.t<string>(`creativeType.${_.camelCase(CreativeType[type].toLowerCase())}`),
      [ReportDimension.PRODUCT]: async id => dmpManager.getProductName(id),
      [ReportDimension.SEARCH_KEYWORD]: async keyword => keyword
    };
    this.reportDimensions = this.dataProvider.getReportDimensions(this.actor);
    this.debouncedUpdateFilteredRecords = _.debounce(this.updateFilteredRecords.bind(this), 1000);
  }

  abstract get title ();

  get filterHasNameSource () {
    return Object.keys(this.filterNameSources);
  }

  abstract initReportData ();
  abstract download ();
  abstract get defaultReportType ();
  abstract get defaultReportDimension ();
  abstract get defaultReportGran ();
  abstract get validDimensions ();

  get isSysAdmin (): boolean {
    return isSystemAdmin(this.actor);
  }

  get tags (): Array<string> {
    return _.uniq(_.flatten(this.tableData.map(data => data.tags)));
  }

  abstract get reportTypes ();

  abstract get reportGrans ();

  get reportTimezones () {
    return SUPPORT_TIMEZONE.map(timezone => {
      return {
        label: i18n.t<string>(`report.labels.${timezone.replace(':', '')}`),
        value: timezone
      };
    });
  }

  get state (): ReportContentState {
    return {
      loading: this.loading,
      reportData: this.reportData,
      reportType: this.reportType,
      dimension: this.dimension,
      gran: this.gran,
      timezone: this.timezone,
      filter: this.filter,
      from: this.from,
      to: this.to,
      searchPath: this.searchPath,
      tableDimensions: this.reportDimensions,
      tableColumnSettings: this.tableColumnSettings,
      tableData: this.filteredRecords,
      selectedTagFilter: this.selectedTagFilter,
      tags: this.tags,
      dayRangeError: this.dayRangeError,
      showDimensionSelectArea: this.showDimensionSelectArea,
      showReportChart: this.showReportChart,
      modalData: this.modalData,
      ecConvDays: this.ecConvDays
    };
  }

  get defaultReportData (): ReportData {
    return {
      allowMetrics: [Metrics.IMPRES, Metrics.CLICKS, Metrics.SPENT],
      records: [],
      dimension: this.dimension,
      filter: {},
      from: this.getDateWithTimezone(this.from),
      to: this.getDateWithTimezone(this.to),
      currency: Currency.NTD
    };
  }

  toggleShowDimensionSelectArea = () => {
    this.showDimensionSelectArea = !this.showDimensionSelectArea;
    this.updateState(false);
  }

  toggleShowReportChart = () => {
    this.showReportChart = !this.showReportChart;
    this.updateState(false);
  }

  get searchPath (): string {
    const filterQueryParams = Object.keys(this.filter)
      .filter(key => this.filter[key] !== undefined)
      .map(key => `${key}=${this.filter[key]}`)
      .join('&');
    let searchPath = `?type=${this.reportType}&dimension=${this.dimension}&gran=${this.gran}&from=${encodeURIComponent(this.from)}&to=${encodeURIComponent(this.to)}&timezone=${encodeURIComponent(this.timezone)}`;
    this.ecConvDays && (searchPath += `&conv-days=${this.ecConvDays}`);
    return _.isEmpty(filterQueryParams) ? searchPath : `${searchPath}&${filterQueryParams}`;
  }

  async generateFilterOption (filterType, diemnsionId) {
    const catchedKey = `${filterType}_${diemnsionId}`;
    if (this.cachedName[catchedKey]) {
      this.filterOptions[filterType] = [{
        label: this.cachedName[catchedKey],
        value: diemnsionId
      }];
      return;
    }
    try {
      const dimensionName = await this.filterNameSources[filterType](diemnsionId);
      if (dimensionName) {
        this.cachedName[catchedKey] = dimensionName;
        this.filterOptions[filterType] = [{
          label: dimensionName,
          value: diemnsionId
        }];
        return;
      }
    } catch (e) {}
    delete this.filterOptions[filterType];
  }

  updateReportType = (type: string | number) => {
    this.setUpReportType(type);
    if (type === ReportType.E_COMMERCE) {
      this.setUpGran(ReportGran.DAY);
      this.setUpEcConvDays(ECConvDays.DAY7);
    }
    this.updateState(false);
  }

  updateGran = (gran: string | number) => {
    this.setUpGran(gran);
    this.updateState(false);
  }

  updateTimezone = (timezone: string) => {
    this.setUpTimezone(timezone);
    this.updateState(false);
  }

  updateDayRange = (from: string | undefined, to: string | undefined) => {
    this.setUpDayRange(from, to);
    this.updateState(false);
  }

  updateEcConvDays = (ecConvDays: ECConvDays | string) => {
    this.setUpEcConvDays(ecConvDays);
    this.updateState(false);
  }

  addFilter = (filterName: string, filterValue: string | number) => {
    this.filter = {
      ...this.filter,
      [filterName]: filterValue
    };
    this.updateState(false);
  }

  queryDataWithDimension = (dimension: string) => {
    let targetDimension = dimension;
    if (this.isDateDimension(dimension)) {
      targetDimension = this.gran;
    }
    const reportDimension = _.find(Object.values(ReportDimension), value => value === targetDimension);
    if (reportDimension) {
      this.dimension = reportDimension;
      this.updateSearchPath(false);
    }
  }

  queryDataWithFilter = async (filterType: string, filterData: SelectOptions) => {
    this.updateState(true);
    this.filter = {
      ...this.filter,
      [filterType]: filterData.value
    };
    this.cachedName[`${filterType}_${filterData.value}`] = filterData.label;
    if (!this.canFilterSelect(filterType)) {
      this.filterOptions[filterType] = [filterData];
    }
    this.updateState(false);
    this.updateSearchPath(false);
  }

  isDateDimension = (dimension) => {
    return dimension === ReportDimension.MONTH ||
      dimension === ReportDimension.DAY ||
      dimension === ReportDimension.HOUR;
  }

  abstract onDateClick (date);

  abstract canFilterSelect (filterName: string);

  abstract canFilterRemove (filterName: string);

  getFilterOptions = (filterType: string) => {
    return this.filterOptions[filterType];
  }

  setUpReportTableData = async () => {
    cancelRequest();
    this.updateState(true);
    try {
      const reportData = await this.getReportData();
      this.tableData = this.dataProvider.getReportTableData(reportData.records);
      this.reportDimensions = this.dataProvider.getReportDimensions(this.actor);
      this.reportData = reportData;
      this.updateFilteredRecords();
    } catch (e) {
      this.updateState(false);
    }
  }

  getReportData = async (): Promise<ReportData> => {
    const defaultReportData = _.cloneDeep(this.defaultReportData);
    if (this.dayRangeError) {
      return defaultReportData;
    }

    const needRefreshUrl = await this.setupSearchPathAndFilter();
    if (needRefreshUrl) {
      return defaultReportData;
    }
    const cacheKey = this.isDateDimension(this.dimension) ? ReportDimension.DAY : this.dimension;
    const useCache = this.cachedReportQuery[cacheKey] && this.cachedReportQuery[cacheKey].searchPath === this.searchPath;
    if (useCache) {
      return this.cachedReportQuery[cacheKey].result;
    }
    try {
      const from = this.getDateWithTimezone(this.from);
      const to = this.getDateWithTimezone(this.to);
      const reportData = await this.reportManager.getReportData(
        this.reportType,
        this.dimension,
        this.gran,
        _.omitBy(this.filter, _.isUndefined),
        from,
        to,
        this.ecConvDays
      );
      if (this.isDateDimension(this.dimension)) {
        this.fillDateRecords(reportData);
      }
      this.cachedReportQuery[cacheKey] = {
        searchPath: this.searchPath,
        result: reportData
      };
      return reportData;
    } catch (e) {
      if (e && e.toString() === 'CanceledError') {
        throw e;
      } else {
        e && toast.error((e as Error).message);
      }
    }
    return defaultReportData;
  }

  setupSearchPathAndFilter = async () => {
    const hasInvalidFilter = await this.hasInvalidFilters();
    if (hasInvalidFilter) {
      this.updateSearchPath(true);
      return true;
    }
    return false;
  }

  dateTimeShortCut = (): DateRange[] => {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    return [
      {
        label: i18n.t<string>('daypick.labels.today'),
        dateRange: [new Date(moment().startOf('day').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.yesterday'),
        dateRange: [new Date(moment().subtract(1, 'day').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.thisWeek'),
        dateRange: [new Date(moment().startOf('week').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.thisMonth'),
        dateRange: [new Date(moment().startOf('month').format(dateFormat)), new Date(moment().endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.last7Days'),
        dateRange: [new Date(moment().subtract(1, 'week').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.last30Days'),
        dateRange: [new Date(moment().subtract(30, 'day').startOf('day').format(dateFormat)), new Date(moment().subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.lastWeek'),
        dateRange: [new Date(moment().startOf('week').subtract(1, 'week').startOf('day').format(dateFormat)), new Date(moment().startOf('week').subtract(1, 'day').endOf('day').format(dateFormat))]
      }, {
        label: i18n.t<string>('daypick.labels.lastMonth'),
        dateRange: [new Date(moment().startOf('month').subtract(1, 'month').startOf('day').format(dateFormat)), new Date(moment().startOf('month').subtract(1, 'day').endOf('day').format(dateFormat))]
      }
    ];
  }

  fillDateRecords = (reportData: ReportData) => {
    const dataFromServer = {};
    const dataFinal = {};
    let dateFormat = 'YYYY-MM-DD';
    if (this.dimension === ReportDimension.MONTH) {
      dateFormat = 'YYYY-MM';
    } else if (this.dimension === ReportDimension.HOUR) {
      dateFormat = 'YYYY-MM-DD HH';
    }
    reportData.records.forEach(data => {
      const dimensionName = moment(data.dimensionName).format(dateFormat);
      dataFromServer[dimensionName] = {
        ...data,
        dimensionName
      };
    });
    const dimension = this.dimension as unitOfTime.DurationConstructor;
    const start = moment(this.from).startOf(dimension);
    const to = moment(this.to).endOf(dimension);
    let current = start;
    while (current.isBefore(to)) {
      const dimensionName = current.format(dateFormat);
      if (dimensionName in dataFromServer) {
        dataFinal[dimensionName] = dataFromServer[dimensionName];
      } else {
        dataFinal[dimensionName] = { ...emptyReportRecord(dimensionName) };
      }
      current = current.add(1, dimension);
    }
    reportData.records = _.values(dataFinal);
    reportData.records = reportData.records.reverse();
  }

  hideModal = () => {
    this.modalData = undefined;
    this.updateState(false);
  }

  setUpReportType (typeParam) {
    const reportTypeOption = _.find(this.reportTypes, reportTypeOption => reportTypeOption.value === typeParam);
    if (reportTypeOption) {
      this.reportType = reportTypeOption.value;
    } else {
      this.reportType = this.defaultReportType;
    }
  }

  setUpDimension (dimensionParam) {
    if (this.validDimensions.indexOf(dimensionParam) === -1) {
      this.dimension = this.defaultReportDimension;
      return;
    }
    this.dimension = dimensionParam;
    // this.showAlertModal();
  }

  setUpGran (granParam) {
    const granOption = _.find(this.reportGrans, granOption => granOption.value === granParam);
    let targetGran;
    if (granOption) {
      targetGran = granOption.value;
    } else {
      targetGran = this.defaultReportGran;
    }
    if (this.gran !== targetGran && targetGran === ReportGran.HOUR) {
      if (moment(this.to).diff(moment(this.from), 'days') >= 2) {
        this.from = moment(this.to).subtract(2, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
    }

    this.gran = targetGran;

    if (this.gran === ReportGran.DAY && this.dimension === ReportDimension.HOUR) {
      this.setUpTimezone(this.localeMeta ? this.localeMeta.timezone : '+08:00');
    }
    // dimension should same with gran
    if (this.isDateDimension(this.dimension)) {
      this.setUpDimension(this.gran);
    }

    this.setUpDayRange(this.from, this.to);
  }

  setUpTimezone (timezoneParam) {
    const actorTimezone = this.localeMeta ? this.localeMeta.timezone : '+08:00';
    const useActorTimezone =
      this.gran !== ReportGran.HOUR ||
      !this.isSysAdmin ||
      !(timezoneParam in MOMENT_TIMEZONE);
    if (useActorTimezone) {
      this.timezone = actorTimezone;
      return;
    }
    this.timezone = timezoneParam;
  }

  setUpDayRange (from, to) {
    if (from) {
      this.from = moment(from).startOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    }
    if (to) {
      this.to = moment(to).endOf(this.gran).format('YYYY-MM-DD HH:mm:ss');
    }
    if (this.gran === ReportGran.MONTH) {
      this.dayRangeError = undefined;
      return;
    }
    let start = new Date(this.from);
    let end = new Date(this.to);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    if (this.gran === ReportGran.DAY) {
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this.dayRangeError = diffDays > MAX_QUERY_DAYS ? i18n.t<string>('campaign.descriptions.moreThanDays', { days: MAX_QUERY_DAYS }) : undefined;
    } else {
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60));
      this.dayRangeError = diffDays > MAX_QUERY_HOURS ? i18n.t<string>('campaign.descriptions.moreThanHours', { hours: MAX_QUERY_HOURS }) : undefined;
    }
  }

  setUpFilter (filterParams: {[key: string]: string}) {
    const filterTypes = this.state.tableDimensions.filter(dimension => dimension !== ReportDimension.DAY).map(dimension => dimension.toString());
    this.filter = {};
    Object.keys(filterParams).forEach(key => {
      if (filterTypes.indexOf(key) !== -1) {
        this.filter[key] = filterParams[key];
      }
    });
  }

  setUpEcConvDays (ecConvDaysParam: ECConvDays | string) {
    if (this.reportType === ReportType.E_COMMERCE) {
      if (ecConvDaysParam in ECConvDays) {
        this.ecConvDays = ecConvDaysParam as ECConvDays;
      }
      return;
    }
    this.ecConvDays = undefined;
  }

  async hasInvalidFilters () {
    let hasInvalidFilters = false;
    const filterTypes = Object.keys(this.filter);
    for (let type of filterTypes) {
      hasInvalidFilters = hasInvalidFilters || await this.isFilterInvalid(type);
    }
    return hasInvalidFilters;
  }

  async isFilterInvalid (filterType) {
    if (this.filter[filterType] !== undefined) {
      if (!(filterType in this.filterOptions)) {
        await this.generateFilterOption(filterType, this.filter[filterType]);
      }
      if (!this.filterOptions[filterType]) {
        delete this.filter[filterType];
        return true;
      }
      return false;
    }
    return false;
  }

  getDateWithTimezone (time) {
    const momentTimezone = MOMENT_TIMEZONE[this.timezone] ? MOMENT_TIMEZONE[this.timezone] : MOMENT_TIMEZONE['+08:00'];
    return moment.tz(time, momentTimezone).format();
  }

  updateReportData (params) {
    const reportTypeParam = params.get('type');
    this.setUpReportType(reportTypeParam);
    params.delete('type');
    const dimensionParam = params.get('dimension');
    this.setUpDimension(dimensionParam);
    params.delete('dimension');
    const granParam = params.get('gran');
    this.setUpGran(granParam);
    params.delete('gran');
    const fromParam = params.get('from');
    const toParam = params.get('to');
    this.setUpDayRange(fromParam, toParam);
    params.delete('from');
    params.delete('to');
    const timezoneParam = params.get('timezone');
    this.setUpTimezone(timezoneParam);
    params.delete('timezone');
    const ecConvDaysParam = params.get('conv-days') === null ? undefined : params.get('conv-days');
    this.setUpEcConvDays(ecConvDaysParam);
    params.delete('conv-days');
    const filterParams = {};
    params.forEach((value, key) => {
      filterParams[key] = value;
    });
    this.setUpFilter(filterParams);
    this.dataProvider = getDataProvider(this.reportType, this.queryDataWithFilter, this.onDateClick, this.ecConvDays);
    const needRefreshUrl =
      this.reportType !== reportTypeParam ||
      this.dimension !== dimensionParam ||
      this.gran !== granParam ||
      this.from !== fromParam ||
      this.to !== toParam ||
      this.timezone !== timezoneParam ||
      this.ecConvDays !== ecConvDaysParam ||
      _.xor(Object.keys(this.filter), Object.keys(filterParams)).length > 0;
    needRefreshUrl ? this.updateSearchPath(true) : this.setUpReportTableData();
  }

  removeFilter = (filterName: string) => {
    delete this.filter[filterName];
    this.filter = {
      ...this.filter
    };
    this.updateState(false);
  }

  handleOnSearch = (searchString: string) => {
    this.searchString = searchString;
    if (this.searchString === '') {
      this.debouncedUpdateFilteredRecords && this.debouncedUpdateFilteredRecords.cancel();
      this.updateFilteredRecords();
    } else {
      this.debouncedUpdateFilteredRecords();
    }
  }

  handleOnTagFilterClicked = (tag: string) => {
    if (_.indexOf(this.selectedTagFilter, tag) === -1) {
      this.selectedTagFilter.push(tag);
    } else {
      _.remove(this.selectedTagFilter, tagFilter => tagFilter === tag);
    }
    this.updateFilteredRecords();
  }

  updateFilteredRecords () {
    if (!this.loading) {
      this.updateState(true);
    }
    const recordsShowId = this.dimensionsShowId.includes(this.dimension);
    _.defer(() => {
      this.filteredRecords = _.filter(this.tableData,
        record => {
          const recordName = _.get(record, 'name', '');
          const recordId = _.get(record, 'id', '');
          const nameIsMatch = recordName.toLowerCase().includes(this.searchString.toLowerCase());
          const idIsMatch = recordsShowId ? recordId.includes(this.searchString) : false;
          const tagsIsMatch = this.selectedTagFilter.length === 0 || _.intersection(record.tags, this.selectedTagFilter).length > 0;
          return (nameIsMatch || idIsMatch) && (this.dimension !== ReportDimension.CAMPAIGN || tagsIsMatch);
        }
      );
      const currency = _.get(this.reportData, 'currency', Currency.NTD);
      this.tableColumnSettings = this.reportData ? this.dataProvider.getReportTableColumnSettings(this.reportData.allowMetrics, this.filteredRecords, this.dimension, currency) : [];
      this.updateState(false);
    });
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
