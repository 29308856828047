import { Pagination } from 'core/pagination/Pagination';
export type Agency = {
  readonly id: number;
  readonly name: string;
  readonly vendorNumber: string;
  readonly currency: string;
  readonly timeZone: string;
  readonly addOnFeatures: any;
  readonly loginTitle: string | null;
  readonly loginDomain: string | null;
  readonly detail: AgencyDetail;
  readonly partnershipModeId: PartnershipMode;
};

export type AgencyWithPagination = {
  pagination: Pagination;
  agencies: Agency[];
};

export type AgencyDetail = {
  readonly addonFeatures: any;
  id?: number;
  addonProps: AddonProps;
  companyName: string;
  vendorNumber: string;
  language: Language;
  currency: Currency;
  timeZone: TimeZone;
  comComment: string;
  targetBudgetMinimum?: number;
  campaignBudgetMinimum?: number;
  priority: number;
  limitPublisher: Array<number>;
  partnershipModeId: PartnershipMode;
  agencyId?: number;
  noLimitSelfBuyStatus: boolean;
  selfBuy: boolean;
  isAgency: boolean;
  managedVendorNumbers?: string[];
  managedBrands?: string[];
};

export type AddonProps = {
  fixedExchangeRate: number | null;
  orderBudgetMaximum: number;
  campaignBidPrice?: {
    type: string;
    autoBidCap: {[key: string]: string};
    bidFloor: {[key: string]: string};
  }[]
};

export enum Currency {
  NTD = 'NTD'
}

export enum Language {
  ZHTW = 'zh_TW'
}

export enum TimeZone {
  TAIPEI = '+08:00'
}

export enum PartnershipMode {
  SELF_SERVING = 1,
  OUTSOURCING = 2,
  SALES = 3
}
