import { FormConfig } from 'components/form/FormConfig';
import { AdType, LegacyOptimize } from 'core/rtbCampaign/RtbCampaign';
import { useMemo } from 'react';
import i18n from 'i18n';
import { BidAdjustment, BidPriceConstraintType } from 'core/bidAdjustmentJob/BidAdjustmentJob';
import { camelCase } from 'lodash';
import { renderFields } from 'components/form/FormSection';
import { SelectOptions } from 'components/commonType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import style from './bidAdjustmentJobForm.module.scss';

export type BidAdjustmentFieldProps = {
  value: BidAdjustment;
  fieldName: string;
  adTypeOptions: SelectOptions[];
  constraintTypeOptions: SelectOptions[];
  setFieldValue: (field: string, value: any) => void;
  removeFilter?: () => void;
};

export const adTypeOptimizeTypeMap = {
  [AdType.KEYWORD]: LegacyOptimize.CPC,
  [AdType.DISPLAY]: LegacyOptimize.CPM
};

export const allConstraintTypeOptions = [
  {
    label: i18n.t<string>(
      'bidAdjustmentJob.constraintType.bidFloor'
    ),
    value: BidPriceConstraintType.BID_FLOOR
  },
  {
    label: i18n.t<string>(
      'bidAdjustmentJob.constraintType.autoBidCap'
    ),
    value: BidPriceConstraintType.AUTO_BID_CAP
  }
];

export const allAdTypeOptions = [
  {
    label: i18n.t<string>('adType.keyword'),
    value: AdType.KEYWORD
  },
  {
    label: i18n.t<string>('adType.display'),
    value: AdType.DISPLAY
  }
];

export const BidAdjustmentField: React.FC<BidAdjustmentFieldProps> = ({
  value,
  fieldName,
  adTypeOptions,
  constraintTypeOptions,
  setFieldValue,
  removeFilter
}) => {

  const fields = useMemo(() => {
    const optimizeType = adTypeOptimizeTypeMap[value.adType];
    const fieldsBuilder = new FormConfig.FieldsBuilder();
    fieldsBuilder
      .addFormikSelect({
        label: i18n.t<string>('bidAdjustmentJobForm.labels.constraintType'),
        name: `${fieldName}.constraintType`,
        options: constraintTypeOptions,
        simpleValue: true,
        postComponent: removeFilter ? (
          <div style={{ height: '100%', marginLeft: '-5px' }}>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className={style.removeFilterBtn}
              onClick={removeFilter}
            />
          </div>
        ) : undefined
      })
      .addFormikSelect({
        label: i18n.t<string>('bidAdjustmentJobForm.labels.adType'),
        name: `${fieldName}.adType`,
        options: adTypeOptions,
        simpleValue: true,
        onChange: (value: AdType) => {
          setFieldValue(
            `${fieldName}.optimizeType`,
            adTypeOptimizeTypeMap[value]
          );
        }
      });

    fieldsBuilder.addFormikInput({
      label: i18n.t<string>(`bidAdjustmentJobForm.labels.${camelCase(optimizeType)}BidPrice`),
      name: `${fieldName}.bidPrice`,
      type: 'number',
      min: 0,
      step: 0.01
    });

    return fieldsBuilder.build();
  }, [value.adType, fieldName, constraintTypeOptions, adTypeOptions, setFieldValue, removeFilter]);

  return (
    <div style={{ marginBottom: '17px' }}>
      {renderFields(fields)}
    </div>
  );
};
