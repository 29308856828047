import { SelectOptions } from 'components/commonType';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';
import styles from './radioField.module.scss';

export interface RadioProps {
  options: SelectOptions[];
  useOptionExtraAsHoverTip?: boolean;
  disabled?: boolean;
  name: string;
  value: any;
  onChange?: (value: any) => void;
}

export interface FormikRadioProps extends FormikFieldProps, Omit<RadioProps, 'value'> {}
export interface FormikRadioWithFieldLayoutProps extends FormikFieldProps, Omit<FieldLayoutProps, 'name'>, Omit<RadioProps, 'value'> {}
export type FormikRadioFieldProps = ({ withFieldLayout?: true } & FormikRadioWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikRadioProps);

const RadioContent: React.FC<RadioProps> = ({
  name,
  value,
  options,
  disabled,
  useOptionExtraAsHoverTip,
  onChange
}: RadioProps) => {

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e.target.value);
  };

  const renderOptions = () => {
    return options.map(option => (
      <div className={styles.radio} key={option.value}>
        <Form.Check
          type={'radio'}
          label={option.label}
          value={option.value}
          name={name}
          id={`radio-${name}-${option.value}`}
          onChange={onChangeRadio}
          checked={value.toString() === option.value.toString()}
          disabled={disabled}
        />
        {option.extra &&
          <span
            className={useOptionExtraAsHoverTip ? styles.hoverHint : styles.hint}
          >
            {option.extra}
          </span>
        }
      </div>
    ));
  };

  return (
    <div className={styles.radioColumn}>
      {renderOptions()}
    </div>
  );
};
RadioContent.defaultProps = {
  useOptionExtraAsHoverTip: false
};

const MemorizedRadioContent = React.memo(RadioContent);
export type RadioFieldProps = FieldLayoutProps & RadioProps;
export const RadioField = withFieldLayout<FieldLayoutProps & RadioProps>(RadioContent);
RadioField.defaultProps = {
  fieldContentWidth: 'auto'
};

const FormikRadio = withFormikField<FormikRadioProps>(MemorizedRadioContent);
const FormikRadioWithFieldLayout = withFormikField<FormikRadioWithFieldLayoutProps>(withFieldLayout(RadioContent));

export const FormikRadioField: React.FC<FormikRadioFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikRadio {...props} />;
  } else {
    return <FormikRadioWithFieldLayout {...props} />;
  }
};
FormikRadioField.defaultProps = {
  fieldContentWidth: 'auto'
};
