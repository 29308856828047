import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';
import { get } from 'lodash';
import styles from './creativePreview.module.scss';

export const RichProductNativeCreativePreview: React.FC<{creative: CreativeOfCampaign | CreativeListRecord}> = (props) => {

  const creativeValue = get(props.creative, 'creativeValues', get(props.creative, 'creativeValue'));
  if (!creativeValue) {
    return <div/>;
  }

  const width = get(creativeValue, 'images.0.w');
  const height = get(creativeValue, 'images.0.h');
  const imageUrl = get(creativeValue, 'images.0.imageUrl');
  if (!imageUrl || !width || !height) {
    return <div/>;
  }

  const adServingUrl = get(creativeValue, 'product.adServingUrl');
  if (!adServingUrl) {
    return <div/>;
  }

  // const asiaMaxUrl = process.env.REACT_APP_ASIAMAX_URL;
  // const templateUrlMap = {
  //   2440: `${asiaMaxUrl}/docs/c90394dd9faf4ed9_template.html`,
  //   960: `${asiaMaxUrl}/docs/d27d4fd5107c45e4_template.html`
  // };
  // const templateUrl = `${templateUrlMap[width]}?productMETA=${adServingUrl}&bannerImageLink=${imageUrl}`;
  return (
    // <div className={`${styles.richProductNativeCreativePreview} ${styles[`width${width}`]}`}>
    //   <iframe
    //     style={{ border: 'none' }}
    //     width={width}
    //     height={height}
    //     title='richProductNativeMediaSummary'
    //     src={templateUrl}
    //   />
    // </div>
    <div className={styles.normalCreativePreview}>
      <img src={imageUrl} alt={'preview_image_url'} />
    </div>
  );
};
