import { CloudCostWebService, RestfulCloudCostWebService } from 'ws/CloudCostWebService';
import { CloudCost, CloudCostFormData } from './CloudCost';
import { AWSS3Manager, CloudStorageManager } from 'core/cloudStorage/CloudStorageManager';
import { toast } from 'react-toastify';

export interface CloudCostManager {
  getCloudCosts: () => Promise<CloudCost[]>;
  createCloudCost: (cloudCost: CloudCostFormData) => Promise<void>;
}

export class DefaultCloudCostManager implements CloudCostManager {

  constructor (
    private webService: CloudCostWebService = new RestfulCloudCostWebService(),
    private cloudStorageManager: CloudStorageManager = new AWSS3Manager()
  ) {}

  async getCloudCosts (): Promise<CloudCost[]> {
    return this.webService.getCloudCosts();
  }

  async createCloudCost (cloudCost: CloudCostFormData): Promise<void> {
    if (!cloudCost.currencyRateEvidence || !cloudCost.cloudCostEvidence) {
      toast.error('Currency rate evidence and cloud cost evidence are required');
      return;
    }
    let {
      url: cloudCostEvidenceUrl,
      file: cloudCostEvidenceFile
    } = cloudCost.cloudCostEvidence;
    if (cloudCostEvidenceFile) {
      cloudCostEvidenceUrl = await this.cloudStorageManager.uploadFileToCloud(cloudCostEvidenceFile);
    }
    let {
      url: currencyRateEvidenceUrl,
      file: currencyRateEvidenceFile
    } = cloudCost.currencyRateEvidence;
    if (currencyRateEvidenceFile) {
      currencyRateEvidenceUrl = await this.cloudStorageManager.uploadFileToCloud(currencyRateEvidenceFile);
    }
    return this.webService.createCloudCost({
      currencyRate: +cloudCost.currencyRate,
      currencyRateEvidence: currencyRateEvidenceUrl || '',
      cloudCost: +cloudCost.cloudCost,
      cloudCostEvidence: cloudCostEvidenceUrl || ''
    });
  }
}
