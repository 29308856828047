import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getColumnSetting, getCommonColumnData } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord } from 'core/creative/Creative';
import { CreativeListModel } from './CreativeListModel';
import { get } from 'lodash';
import { ProductAvailability } from 'core/product/Product';

enum COLUMNS {
  PREVIEW = 'productPreview',
  UTIME = 'utime'
}

export class ProductNativeCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;

  constructor (
    model: CreativeListModel
  ) {
    this.formatters = getCreativeListFormatters(model);
  }

  async init (): Promise<void> {
  }

  getColumns () {
    return [
      renderColumn({
        ...commonColumnSetting[CreativeListCommonColumns.NAME_ID],
        formatExtraData: {
          renderOtherInfo: (creative) => this.formatters.productIdRenderer(creative.productId)
        }
      }, this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(getColumnSetting(COLUMNS.PREVIEW), this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.STATUS], this.formatters.productNativeStateFormatter),
      renderColumn({
        ...getColumnSetting(COLUMNS.UTIME),
        sort: true
      }),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    const availability = get(creative.bannerExtra, 'availability');
    return {
      ...getCommonColumnData(creative),
      productId: creative.product?.productId,
      creativeType: creative.creativeType,
      stockAvailable: availability === ProductAvailability.IN_STOCK,
      [CreativeListCommonColumns.NAME_ID]: {
        id: creative.creativeId,
        name: creative.product?.name
      },
      [COLUMNS.PREVIEW]: {
        ...creative,
        name: creative.product?.name
      },
      [COLUMNS.UTIME]: creative.updateTime
    };
  }
}
