import { Page } from 'components/Page/Page';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import i18n from 'i18n';
import { CloudCostColumns, useCloudCostListModel } from './CloudCostListModel';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { CloudCostForm } from './CloudCostForm';
import { useCallback } from 'react';
import { Modal } from 'components/Modal/Modal';
import { ImagePreview } from 'components/Image/ImagePreview';
import styles from './cloudCostList.module.scss';

export const CloudCostList: React.FC = () => {

  const {
    loading,
    columns,
    cloudCosts,
    cloudCostFormProps,
    certificationModalProps,
    closeCertificationModal,
    showCreateCloudCostFormModal
  } = useCloudCostListModel();

  const renderListOperations = useCallback(() => {
    return [
      <button
        key={'new-beacon-connection'}
        className={'btn btn-tertiary btn-sm'}
        onClick={showCreateCloudCostFormModal}
      >
        {i18n.t<string>(
          'cloudCostList.buttons.new'
        )}
      </button>
    ];
  }, [showCreateCloudCostFormModal]);

  return (
    <div className={styles.cloudCostList}>
      <Page title={i18n.t<string>('cloudCostList.title')}>
        {loading && <LoadingIndicator />}
        <StickableBootstrapTable
          customPadding={false}
          keyField={'id'}
          data={cloudCosts}
          columns={columns}
          defaultSorted={[{ dataField: CloudCostColumns.DATE, order: 'desc' }]}
          noDataIndication={i18n.t<string>('cloudCostList.placeholders.noData')}
          renderListOperations={renderListOperations}
        />
        {cloudCostFormProps && <CloudCostForm {...cloudCostFormProps} />}
        {certificationModalProps && (
          <Modal
            fullScreen={true}
            title={certificationModalProps.title}
            secondaryButton={{
              title: i18n.t<string>('common.buttons.close'),
              callback: closeCertificationModal
            }}
            dismiss={closeCertificationModal}
            animation={false}
          >
            <div className={styles.certificatePreview}>
              <ImagePreview
                border={false}
                url={certificationModalProps.certificationUrl}
                config={{
                  showName: false,
                  showSize: false
                }}
              />
            </div>
          </Modal>
        )}
      </Page>
    </div>
  );
};
