import { AdType } from 'core/rtbCampaign/RtbCampaign';

type AddonFeatures = {[category: string]: {[key: string]: boolean}};

type AgencyProps = {
  targetBudgetMinimum: number;
  campaignBudgetMinimum: number;
  addonProps: {
    fixedExchangeRate: number;
    defaultMonitorBudget: number;
    orderBudgetMaximum: number;
    campaignBidPrice: {
      type: AdType;
      autoBidCap: {[key: string]: number};
      bidFloor: {[key: string]: number};
    };
  }
};

export type LocaleMeta = {
  readonly agencyId?: number,
  readonly timezone: string;
  readonly currency: string;
  readonly currencyRate: number;
  readonly language: string;
  readonly maxOrderBudget: number;
  readonly addonFeatures: AddonFeatures;
  readonly property: AgencyProps;
  readonly vendorNumber: string;
  readonly limitSpaceType: {[key: string]: number};
  readonly isAgency: boolean;
  readonly agencyName: string;
};

export const MOMENT_TIMEZONE = {
  '+09:00': 'Asia/Tokyo',
  '+08:00': 'Asia/Taipei',
  '+07:00': 'Asia/Ho_Chi_Minh'
};
