import _ from 'lodash';
import {
  LimitationWebService,
  RestfulLimitationWebService
} from 'ws/LimitationWebService';
import { SavedTargeting, SavedTargetingCreateDTO } from './SavedTargeting';

const defaultlimitationJson = {
  'include': [],
  'exclude': [],
  'preferred': [],
  'nonPreferred': [],
  'other': []
};

export interface LimitationManager {
  getLimitationPreSet (type: string): Promise<Object>;
  createSavedTargeting (dto: SavedTargetingCreateDTO): Promise<void>;
  deleteSavedTargeting (id: number): Promise<void>;
  getSavedTargeting (id: number): Promise<SavedTargeting>;
  getSavedTargetings (advertiserId: number): Promise<SavedTargeting[]>;
}

export class DefaultLimitationManager implements LimitationManager {
  webService: LimitationWebService;

  constructor (
    webService: LimitationWebService = new RestfulLimitationWebService()
  ) {
    this.webService = webService;
  }

  async getLimitationPreSet (type: string): Promise<Object> {
    const opMap = {
      inc: 'include',
      exc: 'exclude',
      Preferred: 'preferred',
      NonPreferred: 'nonPreferred'
    };

    let limitationPreSet: any = _.cloneDeep(defaultlimitationJson);
    if (type === 'creative') {
      return limitationPreSet;
    }
    const agencyLimitationPreSet = await this.webService.getAgencyLimitationPreSet(type);
    agencyLimitationPreSet.forEach(element => {
      element.value = element.limits ?
        element.limits.map(limit => ({
          ...limit,
          isGroup: element.isGroup ? element.isGroup : false
        })) :
        [];
      delete element['limits'];
      let setIndex = limitationPreSet[opMap[element.op]].findIndex(set => set.type === element.type);
      if (setIndex === -1) {
        limitationPreSet[opMap[element.op]].push(element);
        return;
      }
      limitationPreSet[opMap[element.op]][setIndex] = element;
    });
    return limitationPreSet;
  }

  async createSavedTargeting (dto: SavedTargetingCreateDTO): Promise<void> {
    return this.webService.createSavedTargeting(dto);
  }

  async deleteSavedTargeting (id: number): Promise<void> {
    return this.webService.deleteSavedTargeting(id);
  }

  async getSavedTargeting (id: number): Promise<SavedTargeting> {
    return this.webService.getSavedTargeting(id);
  }

  async getSavedTargetings (advertiserId: number): Promise<SavedTargeting[]> {
    return this.webService.getSavedTargetings(advertiserId);
  }
}
