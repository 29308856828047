import React from 'react';
import styles from './CreativeList.module.scss';
import i18n from 'i18n';
import { Status } from 'components/Status/Status';
import { CampaignBannerMapState, CreativeType } from 'core/creative/Creative';
import { CreativePreview } from 'components/CreativePreview/CreativePreview';
import { DefaultCreativePreviewModel } from 'components/CreativePreview/CreativePreviewModel';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faChartArea, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { addOnEnabled, hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Link } from 'react-router-dom';
import _, { get } from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import { CreativeReviews } from 'components/CreativeReview/CreativeReviews';
import { CREATIVES } from 'core/agency/AddonFeature';
import { ColumnFormatter } from 'react-bootstrap-table-next';
import { PermissionAware } from 'core';
import { CreativeListRow } from './CreativeListRow';
import { ProductAvailability } from 'core/product/Product';

const nameHeaderFormatter = (allSelected, selectedCreativesLength, modifyPermissionAware, handleOnSelectAll, column): any => {
  const permissionAware = modifyPermissionAware ?
    modifyPermissionAware :
    hasFuncs(Permission.CREATIVE_WRITE).or(hasFuncs(Permission.CAMPAIGN_WRITE));
  return (
    <div className={styles.idHeader} >
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={permissionAware}>
          <input
            type='checkbox'
            checked={allSelected}
            ref={el => el && (el.indeterminate = (!allSelected && selectedCreativesLength > 0))}
            onChange={handleOnSelectAll}
            id={'creativeListInput0'}
          />
          <label htmlFor='creativeListInput0'/>
        </PermissionChecker>
      </div>
      {i18n.t<string>(column.text)}
    </div>
  );
};

type CreatvieIDFormatterExtraData = {
  handleOnSelect: (id: number) => void,
  modifyPermissionAware: PermissionAware
};

const creatvieIDFormatter: ColumnFormatter<CreativeListRow, CreatvieIDFormatterExtraData, number> = (
  cell: number,
  row: CreativeListRow,
  _1,
  formatExtraData?: CreatvieIDFormatterExtraData
) => {
  const handleOnSelect = (e) => {
    formatExtraData && formatExtraData.handleOnSelect(row.id);
  };
  const permissionAware = _.get(formatExtraData, 'modifyPermissionAware',
    hasFuncs(Permission.CREATIVE_WRITE).or(hasFuncs(Permission.CAMPAIGN_WRITE)));
  return (
  cell ?
    <div className={styles.creativeCell}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={permissionAware}>
          <input
            type='checkbox'
            checked={row.selected}
            onChange={handleOnSelect}
            id={`creativeListInput${row.id}`}
          />
          <label htmlFor={`creativeListInput${row.id}`}/>
        </PermissionChecker>
      </div>
      <div className={styles.info}>
        <div className={styles.creativeName}>
          {row.name}
        </div>
        {row.rtbCreativeId &&
          <div>
            {`ID: ${row.rtbCreativeId}`}
          </div>
        }
        {row.product && row.product.productId &&
          <div>
            {i18n.t<string>('campaignCreativeList.labels.productId', { id: row.product.productId })}
          </div>
        }
        </div>
    </div>
  : <div/>
  );
};

const percentageFormatter = (cell: any): any => {
  return (
    <div className={styles.creativeCell}>
      {(cell * 100).toFixed(2) + '%'}
    </div>
  );
};

const numberFormatter = (cell: any): any => {
  return (
    <div className={styles.creativeCell}>
      {numberWithCommas(cell)}
    </div>
  );
};

const stateFormatter = (bindingState: any, creative: any): any => {
  const active = bindingState === CampaignBannerMapState.ENABLE;

  let color;
  let label;
  label = i18n.t<string>(`campaignCreativeList.status.${active ? 'enable' : 'disable'}`);
  color = active ? 'success' : 'black';
  return (
    creative.id !== 0 ?
    <>
      <Status
        color={color}
        label={label}
      />
    </> : <div/>
  );
};

const getCreativeDeliveryStatus = (effectiveStatus, creative: any) => {
  const stockAvailable = get(creative, 'bannerExtra.availability', 'in_stock') === ProductAvailability.IN_STOCK;
  const active = effectiveStatus === 'ACTIVE';
  const waiting = effectiveStatus === 'IN_PROCESS';
  let color;
  let label;
  let extraInfo;
  if (!stockAvailable) {
    label = i18n.t<string>('campaignCreativeList.status.outOfStock');
    color = 'black';
  } else if (effectiveStatus === 'WITH_ISSUES') {
    label = i18n.t<string>('campaignList.labels.withIssues');
    color = 'danger';
    extraInfo = creative.issuesInfo ?
      creative.issuesInfo.error_summary
      : undefined;
  } else if (active) {
    label = i18n.t<string>('campaignCreativeList.status.enable');
    color = 'theme1';
  } else if (waiting) {
    color = 'whiteTheme4';
    label = creative.isActiveBinding ?
    i18n.t<string>(`campaignCreativeList.status.waitingActivate`) :
    i18n.t<string>(`campaignCreativeList.status.waitingDeactivate`);
  } else {
    label = i18n.t<string>('campaignCreativeList.status.disable');
    color = 'black';
    extraInfo = _.startCase(_.lowerCase(effectiveStatus));
  }
  return {
    label,
    color,
    extraInfo
  };
};

const deliveryFormatter = (effectiveStatus: any, row: any): any => {
  const {
    label,
    color,
    extraInfo
  } = getCreativeDeliveryStatus(effectiveStatus, row);

  if (row.id === 0) {
    return <div/>;
  }
  return (
    <Status
      label={label}
      color={color}
      renderExtraInfo={extraInfo ? () => extraInfo : undefined}
    />
  );
};

const typeAndSizeFormatter = (cell: any, row: any): any => {
  if (row.id === 0) {
    return <div />;
  }
  return (
    <div className={styles.creativeCell}>
      <div className={styles.info}>
      {row.creativeType ?
        <>
          <div>
            {i18n.t<string>('agency.addonItem.creatives.option_' + _.camelCase(CreativeType[row.creativeType]))}
          </div>
          {row.creativeType !== CreativeType.PRODUCT_NATIVE &&
            <div>
              {row.size}
            </div>
          }
        </> :
        <div>
          {i18n.t<string>('creativeType.custom')}
        </div>
      }
      </div>
    </div>

  );
};

const approvalFormatter = (value, creativeData: any, _2, formatExtraData) => {
  if (!creativeData || creativeData.id === 0) {
    return <div/>;
  }

  if (creativeData.creativeType === CreativeType.PRODUCT_NATIVE) {
    return (
      <div style={{ width: '44px', display: 'flex', justifyContent: 'center' }}>
        N/A
      </div>
    );
  }

  if (_.isEmpty(value)) {
    return <div />;
  }

  return (
    <CreativeReviews
      showCreativeType={false}
      creativeData={creativeData}
      reviewCreative={formatExtraData.reviewCreative}
    />
  );
};

const resultsFormatter = (value, creativeData: any, _1, formatExtraData) => {
  if (!creativeData || creativeData.id === 0) {
    return <div/>;
  }
  const l1Object = formatExtraData.l1Object;
  const objective = _.get(l1Object, 'objective');
  return (
    <div>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {objective}
      </div>
    </div>
  );
};

const uuFormatter = (value: any) => {
  const isSummary = value === '';
  if (isSummary) {
    return '';
  }
  return value !== undefined ? numberFormatter(value) : i18n.t<string>('common.labels.noData');
};

const floatingEditBtnsFormatter = (cell: any, creative: any, _1, formatExtraData: any): any => {
  const goReportPage = () => formatExtraData.goReportPage(creative);
  const definedCreativeAddons: any[] = Object.values(CREATIVES);
  const needCheckAddon = definedCreativeAddons.includes(`option_${creative.creativeType}`);
  const editPermissionAware = needCheckAddon ?
    hasFuncs(Permission.CREATIVE_WRITE).and(addOnEnabled(`option_${creative.creativeType}`)) :
    hasFuncs(Permission.CREATIVE_WRITE);
  return (
    <TableRowToolBar className={styles.creativeFloatingEditArea}>
      <PermissionChecker permissionAware={editPermissionAware}>
        <Link
          to={formatExtraData.getEditPath(creative.rtbCreativeId)}
        >
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignCreativeListReportTip-${creative.id}`,
              tooltip: i18n.t<string>('campaignCreativeList.labels.editHint')
            }}
          />
        </Link>
      </PermissionChecker>
      <IconWithTooltip
        icon={faChartArea}
        onClick={goReportPage}
        tooltipProps={{
          id: `campaignCreativeListReportTip-${creative.id}`,
          tooltip: i18n.t<string>('campaignCreativeList.labels.reportHint')
        }}
      />
    </TableRowToolBar>
  );
};

const creativePreviewFormatter = (cell: any, row: any): any => {
  if (row.id !== 0) {
    return (
      <div className={styles.creativeReview}>
        <CreativePreview model={new DefaultCreativePreviewModel(row)}/>
      </div>
    );
  } else {
    return <div/> ;
  }
};

const formatters = {
  nameHeaderFormatter,
  creatvieIDFormatter,
  percentageFormatter,
  numberFormatter,
  stateFormatter,
  deliveryFormatter,
  typeAndSizeFormatter,
  approvalFormatter,
  resultsFormatter,
  uuFormatter,
  floatingEditBtnsFormatter,
  creativePreviewFormatter
};

export default formatters;
