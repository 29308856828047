import i18n from 'i18n';
import { ServerErrorStatus } from './ServerError';

const errorNeedAlert: number[] = [400010, 401010, 400013, 403010, 404010, 404012, 406010];
const hasI18n = [
  /* System/Service level */
  400000,
  401000,
  403000,
  404000,

  405000,
  408000,// 'Connection Time out',
  409000,// 'Conflict',

  500000,// 'Unknown Error',

  /* Role level 500 */
  401500,// 'Function not authorized',
  402500,// 'No actor in used or using invalid actor',

  /* Resource level 600 */
  400600,// 'Invalid operation',
  404600,// 'Resource not found',

  /* Account level 010 */
  400010,// 'Email already in use',
  400013,
  401010,// 'Account not login',
  403010,// 'Account cannot access the resource',
  404010,// 'Account not found',
  404012,// 'Token invalid',
  406010,// 'Account is disable',

  /* Company Level 020 */
  404020,// 'Company not found',

  /* Agency Level 030 */
  400030,
  404030,
  400031,

  /* Advertiser Level 040 */
  404040,

  /* AdsOrder Level 050 */
  400050,
  404050,
  409050,

  /* Campaign Level 060 */
  404060,
  400061,
  404062,

  /* Creative Level 070 */
  400070,
  400071,
  400072,
  400073,
  404070,
  400074,

  /* AdXCreativeReview Level 080 */
  400080,
  408080,

  /* Segment Level 090 */
  404090,
  404091,

  /* Common Level 100 */
  400100,

  /* Conversion Level 110 */
  404120,
  400120,

  500080,
  500081,

  /* Google API 960 */
  500960,
  500961
];

export const customServerError: (number | string)[] = [
  /* OAuth 1600 */
  ServerErrorStatus.OAUTH_LOGIN_INVALID_AGENCY
];

export const getServerErrorMessageConfig = (
  errorCode: number,
  defaultMessage: string = i18n.t<string>('serverResponse.500000')
) => {

  return {
    message: errorCode !== 500000 && hasI18n.includes(errorCode) ?
      i18n.t<string>(`serverResponse.${errorCode}`) :
      defaultMessage,
    showAlert: errorNeedAlert.indexOf(errorCode) !== -1
  };
};
