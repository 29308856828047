import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import i18n from 'i18n';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import styles from './chooseProductsStep.module.scss';
import { ProductListDataContext } from 'containers/RetailRMN/Products/ProductListDataContext';
import { Product, ProductAvailability } from 'core/product/Product';
import { ProductHome } from 'containers/RetailRMN/Products/ProductHome';
import { defaultTo, get, includes, isNil, omitBy, uniq, xor } from 'lodash';
import { Modal } from 'components/Modal/Modal';
import { OPERATE } from 'enum/Operate';
import { SelectOptions } from 'components/commonType';

export type BindProduct = {
  productId: string;
  name: string;
  category: string;
  imgLink: string;
};

export type ChooseProductsStepProps = {
  goNext: () => void;
  goLast: () => void;
  lockRemainSteps: () => void;
  flowModel: RtbCampaignSetupFlowPageModel;
  bindProducts?: Product[];
};

export const ChooseProductsStep: React.FunctionComponent<ChooseProductsStepProps> = ({
  goNext,
  goLast,
  lockRemainSteps,
  flowModel
}) => {

  const campaign = flowModel.state.campaign;
  const [selectedProducts, setSelectedProducts] = useState<Product[]>(defaultTo(campaign.products, []));
  const [showResetKeywordsAlert, setShowResetKeywordsAlert] = useState<boolean>(false);
  const [showProductsOutOfStockAlert, setShowProductsOutOfStockAlert] =
    useState(false);

  useEffect(() => {
    lockRemainSteps();
  }, [lockRemainSteps]);

  const hasSetupKeywordTA = useMemo(() => {
    const limitationModel = flowModel.limitationModel;
    const keywordTAError = limitationModel ? (get(limitationModel.state.errors, `${OPERATE.INCLUDE}.searchKeywords`) || get(limitationModel.state.errors, `${OPERATE.INCLUDE}.recommendedKeywords`)) : undefined;
    const includeTA = get(campaign.limitations, OPERATE.INCLUDE, []);
    const searchKeywordsTA = includeTA.find(ta => ta.type === 'searchKeywords');
    const recommendedKeywordsTA = includeTA.find(ta => ta.type === 'recommendedKeywords');
    return (!!searchKeywordsTA && searchKeywordsTA.value.length > 0) || (!!recommendedKeywordsTA && recommendedKeywordsTA.value.length > 0) || !!keywordTAError;
  }, [flowModel.limitationModel, campaign]);

  const needUpdateProducts = useMemo(() => {
    const oriProducts = defaultTo(campaign.products, []);
    const oriProductIds = uniq(oriProducts.map(p => p.productId));
    const selectedProductIds = uniq(selectedProducts.map(p => p.productId));
    return xor(oriProductIds, selectedProductIds).length > 0;
  }, [campaign.products, selectedProducts]);

  const needResetKeywords = useMemo(() => {
    return hasSetupKeywordTA && needUpdateProducts;
  }, [needUpdateProducts, hasSetupKeywordTA]);

  const needShowProductsOutOfStockAlert = useMemo(() => {
    return (
      selectedProducts.length > 0 &&
      selectedProducts.every(
        (p) => p.availability === ProductAvailability.OUT_OF_STOCK
      )
    );
  }, [selectedProducts]);

  const updateCampaignAndGoNext = useCallback(() => {
    const campaign = flowModel.state.campaign;
    const limitationModel = flowModel.limitationModel;
    const includeTA = campaign.limitations.include;
    const keywords: SelectOptions[] = uniq(includeTA ? includeTA.filter(limitation => includes(['searchKeywords', 'recommendedKeywords'], limitation.type)).map(limitation => limitation.value).flat() : []);
    let newCampaign = {
      ...campaign,
      products: selectedProducts
    };
    if (limitationModel) {
      limitationModel.setLimitation(OPERATE.INCLUDE, 'searchKeywords', keywords);
      limitationModel.clearLimitation(OPERATE.INCLUDE, 'recommendedKeywords', true);
      newCampaign = {
        ...newCampaign,
        limitations: omitBy({
          ...campaign.limitations,
          include: includeTA ? includeTA.filter(limitation => !includes(['searchKeywords', 'recommendedKeywords'], limitation.type)) : undefined
        }, isNil)
      };
    } else {
      newCampaign = {
        ...newCampaign,
        limitations: omitBy({
          ...campaign.limitations,
          include: includeTA ? includeTA
            .filter(limitation => !includes(['recommendedKeywords'], limitation.type))
            .map(limitation => 'searchKeywords' === limitation.type ? { ...limitation, value: keywords } : limitation) : undefined
        }, isNil)
      };
    }
    flowModel.setCampaign(newCampaign);
    setShowResetKeywordsAlert(false);
    goNext();
  }, [goNext, flowModel, selectedProducts]);

  const onGoNext = useCallback(() => {
    if (needResetKeywords) {
      setShowResetKeywordsAlert(true);
      return;
    }
    if (needShowProductsOutOfStockAlert) {
      setShowProductsOutOfStockAlert(true);
      return;
    }
    if (needUpdateProducts) {
      const campaign = flowModel.state.campaign;
      flowModel.setCampaign({
        ...campaign,
        products: selectedProducts
      });
    }
    goNext();
  }, [
    flowModel,
    needResetKeywords,
    selectedProducts,
    needUpdateProducts,
    needShowProductsOutOfStockAlert,
    goNext,
    setShowResetKeywordsAlert
  ]);

  const closeResetKeywordsAlert = useCallback(() => {
    setShowResetKeywordsAlert(false);
  }, []);

  const closeProductsOutOfStockAlert = useCallback(() => {
    setShowProductsOutOfStockAlert(false);
  }, []);

  return (
    <div className={styles.chooseProductsStep}>
      {
        showProductsOutOfStockAlert && (
          <Modal
            title={i18n.t<string>('common.warning')}
            primaryButton={{
              title: i18n.t<string>('common.buttons.confirm'),
              callback: closeProductsOutOfStockAlert
            }}
            dismiss={closeProductsOutOfStockAlert}
          >
            {i18n.t<string>('campaign.messages.productsOutOfStock')}
          </Modal>
        )
      }
      <div className={styles.productList}>
        {showResetKeywordsAlert &&
          <Modal
            title={i18n.t<string>('common.warning')}
            primaryButton={{
              title: i18n.t<string>('common.labels.yes'),
              callback: updateCampaignAndGoNext
            }}
            secondaryButton={{
              title: i18n.t<string>('common.labels.no'),
              callback: goNext
            }}
            dismiss={closeResetKeywordsAlert}
          >
            {i18n.t<string>('campaign.messages.clearSearchKeywords')}
          </Modal>
        }
        <ProductListDataContext.Provider
          value={{
            selectedProducts,
            handleSelectedProducts: (fn: (selectedProducts: Product[]) => Product[]): void => {
              setSelectedProducts(fn(selectedProducts));
            },
            handleFilterChanged: () => {}
          }}
        >
          <ProductHome
            defaultFilters={{
              advertiser: flowModel.order.advertiserId
            }}
            title={i18n.t<string>('campaign.labels.selectProduct')}
          />
        </ProductListDataContext.Provider>
      </div>
      <div className={styles.buttonArea}>
        {selectedProducts.length === 0 ?
          <OverlayTrigger
            placement={'right'}
            trigger={['hover', 'focus']}
            overlay={
              <Tooltip id={`onGoNext-tooltip`}>
                {i18n.t<string>('campaign.messages.productCannotEmpty')}
              </Tooltip>
            }
          >
            <Button variant='primary' size='sm' onClick={onGoNext} disabled>
              {i18n.t<string>('campaign.buttons.completeAndCheck')}
            </Button>
          </OverlayTrigger> :
          <Button variant='primary' size='sm' onClick={onGoNext}>
            {i18n.t<string>('campaign.buttons.completeAndCheck')}
          </Button>
        }
        {flowModel.type === 'create' && <Button variant='secondary' size='sm' onClick={goLast}>
          {i18n.t<string>('campaign.buttons.back')}
        </Button>}
      </div>
    </div>
  );
};
