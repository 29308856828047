import {
  DmpWebService,
  RestfulDmpWebService
} from 'ws/DmpWebService';
import { Product, ProductWithPagination } from 'core/product/Product';
import { Pageable } from 'ws/Pageable';

export interface DmpManager {
  getRetailProducts (pageable: Pageable, vendorNumber: string, queries?: any): Promise<ProductWithPagination>;
  getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]>;
  getProductName (productId: string): Promise<string | null>;
  getAdServingUrl (productId: string): Promise<string>;
}

export class DefaultDmpManager implements DmpManager {
  webService: DmpWebService;

  constructor (webService: DmpWebService = new RestfulDmpWebService()) {
    this.webService = webService;
  }

  async getRetailProducts (pageable: Pageable, vendorNumber: string, queries: any = {}): Promise<ProductWithPagination> {
    return this.webService.getRetailProducts(pageable, vendorNumber, queries);
  }

  async getProductCaches (agencyId: number, advertiser: number, productIds: string[]): Promise<Product[]> {
    return this.webService.getProductCaches(agencyId, advertiser, productIds);
  }

  async getProductName (productId: string): Promise<string | null> {
    return this.webService.getProductName(productId);
  }

  async getAdServingUrl (productId: string): Promise<string> {
    return this.webService.getAdServingUrl(productId);
  }
}
