import React, { useCallback, useMemo } from 'react';
import { connect, FormikContextType } from 'formik';

import i18next from 'i18n';
import { SelectOptions } from 'components/commonType';
import { selectOptionsAddI18n } from 'utils/I18nUtils';
import styles from './agencyForm.module.scss';
import { FormikField } from 'components/form/field/FormikField';
import { DefaultAgencyManager } from 'core';
import { LabelField } from 'components/form/field/LabelField';

type AgencyBasicFormProps = {
  isNew: boolean;
  timeZoneOptions: SelectOptions[];
  languageOptions: SelectOptions[];
  currencyOptions: SelectOptions[];
  priorityOptions: SelectOptions[];
  partnershipModeOptions: SelectOptions[];
  vendorOptions: SelectOptions[];
  brandOptions: SelectOptions[];
  handleVendorNumbersChange: (newVendorNumbers?: string[]) => Promise<void>;
  updateInvalidBrandOptions: (newBrands?: string[]) => void;
};

const agencyManager = new DefaultAgencyManager();

const AgencyBasicForm: React.FC<AgencyBasicFormProps & { formik: FormikContextType<any> }> = ({
  timeZoneOptions,
  priorityOptions,
  languageOptions,
  currencyOptions,
  partnershipModeOptions,
  vendorOptions,
  brandOptions,
  isNew,
  formik,
  handleVendorNumbersChange,
  updateInvalidBrandOptions
}) => {

  const {
    setFieldValue,
    setFieldTouched
   } = formik;

  const labelLower = (options: SelectOptions) => {
    return {
      label: options.label.toLowerCase(),
      value: options.value,
      options: options.options
    };
  };

  const timeZoneDom = useMemo(() =>
    timeZoneOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.timeZone.'])),
    [timeZoneOptions]
  );
  const languageDom = useMemo(() =>
    languageOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.language.'])),
    [languageOptions]
  );
  const currencyDom = useMemo(() =>
    currencyOptions
      .map(labelLower)
      .map(options => selectOptionsAddI18n(options, ['common.currency.'])),
    [currencyOptions]
  );
  const partnershipModeDom = useMemo(() => partnershipModeOptions, [partnershipModeOptions]);

  const onCurrencyChange = useCallback((newCurrency) => {
    setFieldValue('targetBudgetMinimum', agencyManager.getRTBDefaultMinBudgetPerDay(newCurrency));
    setFieldValue('campaignBudgetMinimum', agencyManager.getRTBDefaultMinBudget(newCurrency));
  }, [setFieldValue]);

  const onSelfBuyChange = useCallback((newSelfBuy) => {
    if (!newSelfBuy) {
      setFieldValue('noLimitSelfBuyStatus', false);
    }
  }, [setFieldValue]);

  const partnershipFormatter = useCallback((value: string | number) => {
    const option = partnershipModeDom.find(option => option.value.toString() === value.toString());
    return option ? option.label : value.toString();
  }, [partnershipModeDom]);

  const isSupplierAgency = formik.values.isAgency;

  const onVendorNumbersChange = useCallback(async (newVendorNumbers?: string[]) => {
    await handleVendorNumbersChange(newVendorNumbers);
    updateInvalidBrandOptions(formik.values.managedBrands);
    // revalidate brands because the options may have changed
    setFieldTouched('managedBrands', true, true);
  }, [formik.values.managedBrands, setFieldTouched, handleVendorNumbersChange, updateInvalidBrandOptions]);

  return (
    <div className={styles.agencyFrom}>
      <fieldset>
        <legend>
          <span>{i18next.t<string>('agency.information')}</span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.Input
            label={i18next.t<string>('agency.companyName')}
            name='companyName'
          />
          <FormikField.Select
            label={i18next.t<string>('agency.language')}
            simpleValue
            name='language'
            options={languageDom}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.currency')}
            simpleValue
            disabled={!isNew}
            name='currency'
            options={currencyDom}
            onChange={onCurrencyChange}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.timeZone')}
            simpleValue
            disabled={!isNew}
            name='timeZone'
            options={timeZoneDom}
          />
          <FormikField.Select
            label={i18next.t<string>('agency.priority')}
            simpleValue
            name='priority'
            options={priorityOptions}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.selfBuy')}
            disabled={!isNew}
            name='selfBuy'
            onChange={onSelfBuyChange}
          />
          <FormikField.Tags
            label={i18next.t<string>('agency.limitPublisherStr')}
            name='limitPublisher'
            placeholder={i18next.t<string>('agencyForm.placeholders.tags')}
          />
          <FormikField.Label
            label={i18next.t<string>('agency.partnershipMode')}
            name='partnershipModeId'
            formatter={partnershipFormatter}
          />
          <FormikField.Switch
            label={i18next.t<string>('agency.noLimitSelfBuyStatus')}
            disabled={!isNew || !formik.values.selfBuy}
            name='noLimitSelfBuyStatus'
          />
          <FormikField.TextArea
            label={i18next.t<string>('agency.comComment')}
            name='comComment'
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>
            {i18next.t<string>('agency.form.titles.rtbDeliverySetting')}
          </span>
        </legend>
        <div className={styles.fieldContent}>
          <FormikField.InputGroup
            label={i18next.t<string>('agency.targetBudgetMinimum')}
            name='targetBudgetMinimum'
            type='number'
            min={1}
            prefix={
              formik.values.currency
                ? formik.values.currency
                : currencyOptions[0].value
            }
          />
          <FormikField.InputGroup
            label={i18next.t<string>('agency.campaignBudgetMinimum')}
            name='campaignBudgetMinimum'
            type='number'
            min={1}
            prefix={
              formik.values.currency
                ? formik.values.currency
                : currencyOptions[0].value
            }
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>
            {i18next.t<string>('agency.form.titles.vendorAndBrandSetting')}
          </span>
        </legend>
        <div className={styles.fieldContent}>
          {isSupplierAgency ? (
            <>
              <FormikField.Select
                label={i18next.t<string>('agency.managedVendorNumbers')}
                name='managedVendorNumbers'
                isMulti
                simpleValue
                options={vendorOptions}
                onChange={onVendorNumbersChange}
                menuPlacement='top'
              />
              <FormikField.Select
                label={i18next.t<string>('agency.managedBrands')}
                name='managedBrands'
                isMulti
                simpleValue
                options={brandOptions}
                menuPlacement='top'
                onChange={updateInvalidBrandOptions}
              />
            </>
          ) : (
            <>
              <FormikField.Label
                label={i18next.t<string>('agency.managedVendorNumbers')}
                name='vendorNumber'
              />
              <LabelField
                label={i18next.t<string>('agency.managedBrands')}
                name='managedBrands'
                value='ALL'
              />
            </>
          )}
        </div>
      </fieldset>
    </div>
  );
};

export default connect<AgencyBasicFormProps>(AgencyBasicForm);
