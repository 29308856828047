import { Switch, Route } from 'react-router-dom';
import { BidAdjustmentJobForm } from './BidAdjustmentJobForm/BidAdjustmentJobForm';
import { BidAdjustmentJobList } from './BidAdjustmentJobList/BidAdjustmentJobList';

export const BidAdjustmentJobs: React.FC = () => {

  const renderBidAdjustmentJobList = () => {
    return <BidAdjustmentJobList />;
  };

  const renderCreateBidAdjustmentJobForm = () => {
    return <BidAdjustmentJobForm />;
  };

  return (
    <Switch>
      <Route
        path={'/bid-adjustments/new'}
        render={renderCreateBidAdjustmentJobForm}
      />
      <Route
        render={renderBidAdjustmentJobList}
      />
    </Switch>
  );
};
