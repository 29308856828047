import React from 'react';
import { CreateRtbCampaignSetupFlowPageModel, RtbCampaignSetupFlowPageProps, RtbCampaignSetupFlowPageState } from './RtbCampaignSetupFlowPageModel';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import NavigationPrompt from 'components/NavigationPrompt/NavigationPrompt';
import { StepPage } from 'containers/StepPage/StepPage';
import { ChooseAdTypeStep } from './FlowSteps/ChooseAdTypeStep';
import { RtbCampaignSetupStep } from './FlowSteps/RtbCampaignSetupStep';
import { RtbCampaignSummaryStep } from './FlowSteps/RtbCampaignSummaryStep';
import { DefaultChooseAdTypeStepModel } from './FlowSteps/ChooseAdTypeStepModel';
import { DefaultRtbCampaignSetupStepModel } from './FlowSteps/RtbCampaignSetupStepModel';
import styles from './rtbCampaignSetupFlowPage.module.scss';
import i18n from 'i18n';
import { AppFooter } from 'components/AppFooter';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import _ from 'lodash';
import { ChooseProductsStep } from './FlowSteps/ChooseProductsStep';
import { alertMessage } from 'components/AlertDialog';
import { SelectPmpStep } from './FlowSteps/SelectPmpStep';
import { Pmp } from 'core/pmp/Pmp';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { CreativeSetupStep } from './FlowSteps/CreativeSetupStep';
import { CreateCreativeInL2ObjectSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { CampaignSetupFlowDataContext } from './CampaignSetupFlowDataContext';
import { ChooseCreatingMethodStep } from './FlowSteps/ChooseCreatingMethodStep';

export class RtbCampaignSetupFlowPage extends React.Component<RouteComponentProps<{}> & RtbCampaignSetupFlowPageProps, RtbCampaignSetupFlowPageState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.initWithErrorHandler();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.initWithErrorHandler();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  onPromptCancel = () => {
    this.props.model.setRedirectPath(undefined);
  }

  renderTitle = () => {
    return (
      <div className={styles.topArea}>
        <TitleWithBreadcrumbs
          title={this.props.model.getTitle()}
          routes={[
            { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
            {
              path: '/orders/:orderNumber',
              breadcrumb: DynamicBreadcrumb,
              props: {
                label: _.get(this.props.model.order, 'projectName'),
                matchParam: 'orderNumber'
              }
            },
            {
              path: '/orders/:orderNumber/campaign-groups/:l1ObjectId',
              breadcrumb: DynamicBreadcrumb,
              props: {
                label: _.get(this.props.model.l1Object, 'name'),
                matchParam: 'l1ObjectId'
              }
            },
            { path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns/new', breadcrumb: this.props.model.getTitle() },
            {
              path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns/:campaignId/edit',
              breadcrumb: DynamicBreadcrumb,
              props: {
                prefix: this.props.model.getTitle(),
                label: _.get(this.props.model.state.campaign, 'basic.name'),
                matchParam: 'campaignId'
              }
            }
          ]}
        />
      </div>
    );
  }

  renderAdTypeStep = ({ goNext }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <ChooseAdTypeStep model={new DefaultChooseAdTypeStepModel(this.props.model, goNext)}/>
        </div>
      </>
    );
  }

  renderCreatingMethodStep = ({ goNext }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <ChooseCreatingMethodStep goNext={goNext} campaignSetupFlowPageModel={this.props.model as CreateRtbCampaignSetupFlowPageModel}/>
        </div>
      </>
    );
  }

  renderProductList = ({ goNext, goLast, lockRemainSteps }) => {
    return (
      <ChooseProductsStep
        goNext={goNext}
        goLast={goLast}
        lockRemainSteps={lockRemainSteps}
        flowModel={this.props.model}
      />
    );
  }

  renderMainStep = ({
    subStepIndex,
    goSubStep,
    goNext,
    goLast,
    registerValidateMethod
  }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <RtbCampaignSetupStep
            model={new DefaultRtbCampaignSetupStepModel(
              this.props.model,
              subStepIndex,
              goSubStep,
              goNext,
              goLast,
              registerValidateMethod
            )}
          />
        </div>
      </>
    );
  }

  renderPmpStep = ({ goLast, goNext }) => {
    const {
      pmp,
      pmpSpaceOptions,
      pmpListOfAgency
    } = this.props.model.state;

    const goSummary = (pmp: Pmp, campaign: { basic: Partial<RtbCampaignBasic>, limitations: any }) => {
      this.props.model.setPmp(pmp);
      this.props.model.setCampaign({
        basic: {
          ...this.props.model.state.campaign.basic,
          ...campaign.basic
        },
        limitations: campaign.limitations
      });
      goNext();
    };

    return (
      <>
        {this.renderTitle()}
        <div className={`${styles.contentContainer} ${styles.selectPmpStep}`}>
          <SelectPmpStep goLast={goLast} goNext={goSummary} spaceOptions={pmpSpaceOptions} defaultPmp={pmp} pmpListOfAgency={pmpListOfAgency}/>
        </div>
      </>
    );
  }

  renderSetupCreativeStep = (
    createCreativeModel: CreateCreativeInL2ObjectSetupFlowPageModel,
    {
      goNext,
      goLast
    }
  ) => {
    const creative = createCreativeModel.state.creative;
    if (!creative) {
      return <div />;
    }
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <CreativeSetupStep
            goNext={goNext}
            goLast={goLast}
            model={createCreativeModel}
          />
        </div>
      </>
    );
  }

  renderSummaryStep = ({ goLast, goStep, goSubStep, subStepIndex }) => {
    const createCreativeModel = this.props.model.getCreativeSetupFlowModel();
    const creativeSummaryModel = createCreativeModel ? createCreativeModel.getCreativeSummaryModel(goLast, goStep) : undefined;
    if (createCreativeModel && creativeSummaryModel) {
      creativeSummaryModel.initContextData({
        creative: createCreativeModel.creative,
        initCreative: createCreativeModel.initCreative,
        advertisers: createCreativeModel.advertisers,
        supportedCreativeType: createCreativeModel.supportedCreativeType,
        setCreative: createCreativeModel.setCreative,
        setFinishedRedirectData: createCreativeModel.setFinishedRedirectData,
        getFormContentModelOfType: createCreativeModel.getFormContentModelOfType,
        getSummaryModel: createCreativeModel.getSummaryModel
      });
    }
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <RtbCampaignSummaryStep
            model={this.props.model.getRtbCampaignSummaryStepModel(goLast, goStep, goSubStep, subStepIndex, creativeSummaryModel)}
          />
        </div>
      </>
    );
  }

  render () {
    const model = this.props.model;
    const {
      loading,
      finished,
      showRetry,
      redirectPath,
      campaign,
      campaignAnalytics,
      savedTargetingList
    } = model.state;

    if (!campaign) {
      return <LoadingIndicator />;
    }
    const createCreativeModel = model.getCreativeSetupFlowModel();
    return (
      <CampaignSetupFlowDataContext.Provider
        value={{
          campaignAnalytics
        }}
      >
        <div className={styles.campaignSetupFlowPages}>
          {loading && <LoadingIndicator />}
          {showRetry && alertMessage(i18n.t<string>('common.titles.error'), i18n.t<string>('common.messages.retryFetchData'), this.props.model.initWithErrorHandler)}
          <NavigationPrompt when={!finished} onCancel={this.onPromptCancel}/>
          <StepPage noLock={model.type !== 'create'} cancel={model.cancel}>
            {model.type === 'create' &&
              <StepPage.Step
                label={i18n.t<string>('campaign.labels.adType')}
                renderStepContent={this.renderAdTypeStep}
              />
            }
            {
              model.type === 'create' && savedTargetingList.length > 0 &&
              <StepPage.Step
                label={i18n.t<string>('campaign.labels.creatingMethodPageTitle')}
                renderStepContent={this.renderCreatingMethodStep}
              />
            }
            {model.needSelectProduct &&
              <StepPage.Step
                label={i18n.t<string>('campaign.labels.selectProduct')}
                renderStepContent={this.renderProductList}
              />
            }
            {model.isPmp ?
              <StepPage.Step
                label={i18n.t<string>('campaign.labels.pmpSelect')}
                renderStepContent={this.renderPmpStep}
              /> :
              <StepPage.Step
                label={i18n.t<string>('campaign.labels.campaignSetting')}
                renderStepContent={this.renderMainStep}
              >
                <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.targeting')}/>
                <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.basic')}/>
              </StepPage.Step>

            }
            {createCreativeModel &&
              <StepPage.Step
                label={i18n.t<string>('stepSideBar.labels.creativeSetUp')}
                renderStepContent={_.partial(this.renderSetupCreativeStep, createCreativeModel)}
              />
            }
            {createCreativeModel ?
              <StepPage.Step
                label={i18n.t<string>('campaign.labels.summary')}
                renderStepContent={this.renderSummaryStep}
              >
                <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.campaignSettingSummary')}/>
                <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.creativeSettingSummary')}/>
              </StepPage.Step> :
              <StepPage.Step
                label={i18n.t<string>('campaign.labels.summary')}
                renderStepContent={this.renderSummaryStep}
              />
            }
            <StepPage.SideBarFooter>
              <AppFooter/>
            </StepPage.SideBarFooter>
          </StepPage>
          {redirectPath && <Redirect to={redirectPath} />}
        </div>
      </CampaignSetupFlowDataContext.Provider>
    );
  }
}
