import _ from 'lodash';
import { toast } from 'react-toastify';
import i18n from 'i18n';

import { AgencyDetail, PartnershipMode } from 'core';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AgencyManager, DefaultAgencyManager } from 'core/agency/AgencyManager';
import { SelectOptions } from 'components/commonType';
import { validateEmpty, validateMinimum } from 'utils/ValidateUtils';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import agencyData from './agencyData';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { DefaultProductFilterManager, ProductFilterManager } from 'core/product/ProductFilterManager';

export type AgencyFormState = {
  inited: boolean;
  isLoading: boolean;
  redirectUrl?: string;
  currentTab: string;
  errors?: any;
  selectBrand?: string;
  vendorOptions: SelectOptions[];
  brandOptions: SelectOptions[];
};
export interface AgencyFormModel {
  readonly agencyId?: number;
  readonly defaultAgency: AgencyDetail;
  readonly timeZoneOptions: SelectOptions[];
  readonly languageOptions: SelectOptions[];
  readonly currencyOptions: SelectOptions[];
  readonly priorityOptions: SelectOptions[];
  readonly partnershipModeOptions: SelectOptions[];
  readonly validVendorOptions: SelectOptions[];
  readonly isNew: boolean;
  readonly cancelPath: string;
  readonly title: string;
  readonly breadcrumbs: any;
  state: AgencyFormState;
  event: UpdateEventListener<AgencyFormModel>;
  validate (basic: AgencyDetail): any;
  save (value: any): void;
  init (): Promise<void>;
  onUnmount (handler): void;
  onChangeTab (newTab: string | null): void;
  setErrors (errors?: any): void;
  onVendorNumbersChange (newVendorNumbers?: string[]): Promise<void>;
  updateInvalidBrandOptions (newBrands?: string[]): void;
}

export interface AgencyFormProps {
  readonly model: AgencyFormModel;
}

abstract class DefaultAgencyFormModel implements AgencyFormModel {
  agencyDetail: AgencyDetail;
  modelEvent: FireableUpdateEventListener<AgencyFormModel>;
  isLoading: boolean;
  redirectUrl?: string;
  currentTab: string = 'basic';
  errors?: any;
  validVendorOptions: SelectOptions[] = [];
  invalidVendorOptions: SelectOptions[] = [];
  validBrandOptions: SelectOptions[] = [];
  invalidBrandOptions: SelectOptions[] = [];
  inited = false;

  constructor (
    protected agencyManager: AgencyManager = new DefaultAgencyManager(),
    protected productFilterManager: ProductFilterManager = new DefaultProductFilterManager()
  ) {
    this.modelEvent = new FireableUpdateEventListener<AgencyFormModel>();
    this.isLoading = false;
    this.agencyDetail = agencyData.defaultAgency;
  }

  abstract get cancelPath (): string;
  abstract get isNew (): boolean;
  abstract get breadcrumbs (): any[];
  abstract init (): Promise<void>;
  abstract save (agencyDetail: any): void;

  get event (): UpdateEventListener<AgencyFormModel> {
    return this.modelEvent;
  }

  onChangeTab = (newTab: string | null) => {
    if (newTab === null) {
      return;
    }
    this.currentTab = newTab;
    this.updateState(false);
  }

  get defaultAgency (): AgencyDetail {
    return this.agencyDetail;
  }

  getInvalidValues = (options: SelectOptions[], values?: string[]): string[] => {
    const validValues = options.map((option) => option.value);
    return values ? values.filter(
      (value) => !validValues.includes(value)
    ) : [];
  }

  onVendorNumbersChange = async (newVendorNumbers?: string[]) => {
    this.updateState(true);
    this.validBrandOptions =
      await this.productFilterManager.getBrandOptionsByVendorNumbers(
        newVendorNumbers
      );
    this.updateInvalidVendorOptions(newVendorNumbers);
  }

  updateInvalidVendorOptions = (newVendorNumbers?: string[]): void => {
    const invalidVendors = this.getInvalidValues(this.validVendorOptions, newVendorNumbers);
    this.invalidVendorOptions = newVendorNumbers
      ? invalidVendors.map(vendor => ({ value: vendor, label: vendor }))
      : [];
    this.updateState(false);
  }

  updateInvalidBrandOptions = (newBrands?: string[]): void => {
    // reset invalid brands
    const invalidBrands = this.getInvalidValues(this.validBrandOptions, newBrands);
    this.invalidBrandOptions = newBrands
      ? invalidBrands.map(brand => ({ value: brand, label: brand }))
      : [];
    this.updateState(false);
  }

  validateManagedVendorNumbers = (value: AgencyDetail): any => {
    if (!value.isAgency) {
      return {};
    }
    const invalidVendors = this.getInvalidValues(this.validVendorOptions, value.managedVendorNumbers);
    return invalidVendors.length > 0
      ? i18n.t<string>('agency.form.errors.invalidVendors', {
        vendorNumbers: invalidVendors.join(', ')
      })
      : undefined;
  }

  validateManagedBrands = (value: AgencyDetail): any => {
    if (!value.isAgency) {
      return {};
    }
    const invalidBrands = this.getInvalidValues(this.validBrandOptions, value.managedBrands);
    return invalidBrands.length > 0
      ? i18n.t<string>('agency.form.errors.invalidBrands', {
        brands: invalidBrands.join(', ')
      })
      : undefined;
  }

  validate = (basicValue: AgencyDetail): any => {
    return _.omitBy(
      {
        companyName: validateEmpty(basicValue.companyName),
        targetBudgetMinimum: validateMinimum(basicValue.targetBudgetMinimum, 1),
        campaignBudgetMinimum: validateMinimum(
          basicValue.campaignBudgetMinimum,
          1
        ),
        managedVendorNumbers: this.validateManagedVendorNumbers(basicValue),
        managedBrands: this.validateManagedBrands(basicValue)
      },
      _.isEmpty
    );
  }

  get title (): string {
    return 'agency.form.titles.edit';
  }

  get state (): AgencyFormState {
    return {
      isLoading: this.isLoading,
      redirectUrl: this.redirectUrl,
      currentTab: this.currentTab,
      errors: this.errors,
      vendorOptions: this.validVendorOptions.concat(this.invalidVendorOptions),
      brandOptions: this.validBrandOptions.concat(this.invalidBrandOptions),
      inited: this.inited
    };
  }

  get timeZoneOptions (): SelectOptions[] {
    return agencyData.timeZoneOptions;
  }

  get currencyOptions (): SelectOptions[] {
    return agencyData.currencyOptions;
  }

  get languageOptions (): SelectOptions[] {
    return agencyData.languageOptions;
  }

  get partnershipModeOptions (): SelectOptions[] {
    return createSelectOptionsFromEnum(
      PartnershipMode,
      'common.partnershipMode.'
    );
  }

  get priorityOptions (): SelectOptions[] {
    return Array.from(Array(11).keys())
      .map((x) => x - 5)
      .reverse()
      .reduce((all: any, currentValue) => {
        return [...all, { value: currentValue, label: '' + currentValue }];
      }, []);
  }

  setErrors (errors?: any): void {
    this.errors = errors;
    this.updateState(false);
  }

  updateState (isLoading: boolean, redirectUrl?: string, inited: boolean = true) {
    this.isLoading = isLoading;
    this.redirectUrl = redirectUrl;
    this.inited = inited;
    this.modelEvent.fireEvent(this);
  }

  onUnmount (handler): void {
    handler && this.event.remove(handler);
    this.redirectUrl = undefined;
  }
}

export class EditAgencyFormModel extends DefaultAgencyFormModel {
  agencyId: number;

  constructor (
    agencyId: number,
    private updateLocalMeta: () => Promise<void>,
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    super(agencyManager);
    this.agencyId = agencyId;
  }

  async init (): Promise<void> {
    this.updateState(true, undefined, false);
    const { detail } = await this.agencyManager.fetchAgency(this.agencyId);
    this.agencyDetail = detail;
    if (detail.isAgency) {
      this.validVendorOptions = await this.productFilterManager.getVendorNumberOptions(undefined, true);
      this.updateInvalidVendorOptions(detail.managedVendorNumbers);
      this.validBrandOptions =
        await this.productFilterManager.getBrandOptionsByVendorNumbers(
          detail.managedVendorNumbers ? detail.managedVendorNumbers : undefined
        );
      this.updateInvalidBrandOptions(detail.managedBrands);
    }
    this.updateState(false, undefined, true);
  }

  get title (): string {
    return 'agency.form.titles.edit';
  }

  get cancelPath (): string {
    return `/agencies/${this.agencyId}`;
  }

  get isNew (): boolean {
    return false;
  }

  get breadcrumbs () {
    return [
      { path: '/agencies', breadcrumb: i18n.t<string>('agencies.home.title') },
      { path: '/agencies/:agencyId', breadcrumb: DynamicBreadcrumb, props: { label: _.get(this.agencyDetail, 'companyName'), matchParam: 'agencyId' } },
      { path: '/agencies/:agencyId/edit', breadcrumb: DynamicBreadcrumb, props: { prefix: i18n.t<string>('common.labels.edit'), label: _.get(this.agencyDetail, 'companyName'), matchParam: 'agencyId' } }
    ];
  }

  async save (agencyDetail: any) {
    this.updateState(true);
    try {
      await this.agencyManager.updateAgency(agencyDetail);
      await this.updateLocalMeta();
    } catch (e) {}
    toast.success(i18n.t<string>('common.messages.succeeded'));
    this.updateState(false, `/agencies/${this.agencyId}`);
  }
}

export class CreateAgencyFormModel extends DefaultAgencyFormModel {

  constructor (
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    super(agencyManager);
  }

  async init (): Promise<void> {
    this.agencyDetail.targetBudgetMinimum = this.agencyManager.getRTBDefaultMinBudgetPerDay(this.agencyDetail.currency);
    this.agencyDetail.campaignBudgetMinimum = this.agencyManager.getRTBDefaultMinBudget(this.agencyDetail.currency);
    this.updateState(false, undefined, true);
  }

  get cancelPath (): string {
    return '/agencies';
  }

  // readonly by field get
  get isNew (): boolean {
    return true;
  }

  get title (): string {
    return 'agency.form.titles.new';
  }

  get breadcrumbs () {
    return [
      { path: '/agencies', breadcrumb: i18n.t<string>('agencies.home.title') },
      { path: '/agencies/new', breadcrumb:  i18n.t<string>('agency.form.titles.new') }
    ];
  }

  async save (agencyDetail: any) {
    this.updateState(true);
    try {
      const agencyId = await this.agencyManager.createAgency(agencyDetail);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.updateState(false, `/agencies/${agencyId}`);
    } catch (e) {
      this.updateState(false);
    }
  }
}
