import React, { useCallback, useMemo, useState } from 'react';
import i18n from 'i18n';
import { Col, Form, Row } from 'react-bootstrap';
import styles from './savedTAManagement.module.scss';
import { DefaultLimitationManager, LimitationManager } from 'core/limitation/LimitationManager';
import { useCallAPI } from 'hooks/useCallAPI';
import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import { wrapLimitationsFromServer } from 'utils/LimitationUtil';
import { toast } from 'react-toastify';
import { SavedTargeting } from 'core/limitation/SavedTargeting';
import { Modal } from 'components/Modal/Modal';
import { LoadingIndicator } from 'components/LoadingIndicator';
import Select from 'components/Select/Select';
import { LimitationData } from 'core/limitation/Limitation';
import { isNil } from 'lodash';

const defaultLimitationManager: LimitationManager = new DefaultLimitationManager();

export const SavedTAManagement: React.FC<{
  onImport: (savedTargeting: SavedTargeting) => void;
  onClose: (needRefreshSavedTargeting: boolean) => void;
  goNext: () => void;
  getLimitationsSummaryData (limitations: LimitationData): any;
  savedTargetingList: SavedTargeting[];
  limitationManager?: LimitationManager;
}> = ({
  onImport,
  onClose,
  goNext,
  getLimitationsSummaryData,
  savedTargetingList,
  limitationManager = defaultLimitationManager
}) => {

  const [taOptions, setTaOptions] = useState(savedTargetingList.map(savedTargeting => ({
    label: savedTargeting.name,
    value: savedTargeting.id
  })));
  const { loading, callAPIs } = useCallAPI();
  const [selectedTA, setSelectedTA] = useState<number | null>(null);
  const [dialogData, setDialogData] = useState<{
    title: string,
    message: string,
    primaryBtnData: {
      title: string,
      callback: () => void
    }
  }>();
  const [needRefreshSavedTargeting, setNeedRefreshSavedTargeting] = useState(false);

  const selectTargetingSummary = useMemo(() => {
    const savedTargeting = savedTargetingList.find(ta => ta.id === selectedTA);
    if (savedTargeting) {
      const limitationSummary = getLimitationsSummaryData(wrapLimitationsFromServer(savedTargeting.targeting.limitations));
      if (!savedTargeting.targeting.products) {
        return limitationSummary;
      }

      const products = savedTargeting.targeting.products;
      return {
        ...limitationSummary,
        include: {
          content: [
            ...limitationSummary.include.content,
            {
              label: i18n.t<string>('savedTAManagement.labels.products'),
              value: products.map(product => product.name).join(', ')
            }
          ]
        }
      };
    }
    return undefined;
  }, [savedTargetingList, selectedTA, getLimitationsSummaryData]);

  const closeDialog = () => setDialogData(undefined);

  const onModalClose = useCallback(() => {
    onClose(needRefreshSavedTargeting);
  }, [onClose, needRefreshSavedTargeting]);

  const onImportBtnClicked = useCallback(() => {
    const savedTargeting = savedTargetingList.find(ta => ta.id === selectedTA);
    if (!savedTargeting) {
      return;
    }
    setDialogData({
      title: i18n.t<string>('savedTAManagement.titles.import'),
      message: i18n.t<string>('savedTAManagement.messages.import'),
      primaryBtnData: {
        title: i18n.t<string>('common.buttons.confirm'),
        callback: () => {
          closeDialog();
          onImport(savedTargeting);
          onModalClose();
          goNext();
        }
      }
    });
  }, [selectedTA, savedTargetingList, onImport, onModalClose, goNext]);

  const onDeleteBtnClicked = useCallback(() => {
    if (isNil(selectedTA)) {
      return;
    }
    setDialogData({
      title: i18n.t<string>('savedTAManagement.titles.delete'),
      message: i18n.t<string>('savedTAManagement.messages.delete'),
      primaryBtnData: {
        title: i18n.t<string>('common.buttons.delete'),
        callback: () => {
          callAPIs([() => limitationManager.deleteSavedTargeting(selectedTA)], () => {
            toast.success(i18n.t<string>('savedTAManagement.messages.deleteSuccess'));
            closeDialog();
            setTaOptions(taOptions.filter(ta => ta.value !== selectedTA));
            setSelectedTA(null);
            setNeedRefreshSavedTargeting(true);
          }, () => {
            toast.error(i18n.t<string>('savedTAManagement.messages.deleteFailed'));
            closeDialog();
          });
        }
      }
    });
  }, [selectedTA, taOptions, limitationManager, callAPIs]);

  const renderSummaryDetails = (data) => {
    return Object.keys(data).map((key) =>
      data[key] ?
      <SummaryDetail
        key={key}
        title={data[key].title}
        data={data[key].content}
        prefixColor={data[key].titlePrefixColor}
      /> : <div key={key}/>
    );
  };

  return (
    <Modal
      fullScreen={true}
      animation={false}
      title={i18n.t<string>('savedTAManagement.labels.title')}
      primaryButton={{
        title: i18n.t<string>('savedTAManagement.buttons.import'),
        callback: onImportBtnClicked,
        disabled: isNil(selectedTA)
      }}
      secondaryButton={{
        title: i18n.t<string>('common.buttons.cancel'),
        callback: onModalClose
      }}
      dangerButton={{
        title: i18n.t<string>('savedTAManagement.buttons.delete'),
        callback: onDeleteBtnClicked,
        disabled: isNil(selectedTA)
      }}
      dismiss={onModalClose}
    >
      <div className={styles.savedTAManagement}>
        {loading && <LoadingIndicator/>}
        {dialogData &&
          <Modal
            title={dialogData.title}
            dismiss={closeDialog}
            primaryButton={{ ...dialogData.primaryBtnData }}
            secondaryButton={{
              title: i18n.t<string>('common.buttons.cancel'),
              callback: closeDialog
            }}
          >
            {dialogData.message}
          </Modal>
        }
        <Form.Group as={Row}>
          <Col sm={3}>
            <Form.Label column>
              {i18n.t<string>('savedTAManagement.labels.taOptions')}
            </Form.Label>
          </Col>
          <Col sm={9}>
            <Select
              options={taOptions}
              name='taOptions'
              simpleValue
              value={selectedTA}
              onChange={setSelectedTA}
              placeholder={i18n.t<string>('savedTAManagement.placeholders.taOptions')}
            />
          </Col>
        </Form.Group>
        {
          selectTargetingSummary &&
            <div className={styles.taDetail}>
              {renderSummaryDetails(selectTargetingSummary)}
            </div>
        }
      </div>
    </Modal>
  );
};
