import React, { Fragment } from 'react';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router';
import _ from 'lodash';
import './MainPage.scss';

import { Reports } from 'containers/Reports';
import { Accounts } from 'containers/Accounts';
import { Agencies } from 'containers/Agencies';
import { AppHeader } from 'containers/App/AppHeader';
import { AppMenuBar } from 'containers/App/AppMenuBar';
import { LoadingIndicator } from 'components/LoadingIndicator';

import { MainPageProps, MainPageState } from './MainPageModel';
import { Orders } from 'containers/Orders/Orders';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import MenuBarExpandContext from 'contexts/menuBarExpandContext';
import { Creatives } from 'containers/Creatives/Creatives';
import Swagger from 'containers/Swagger/Swagger';
import { CampaignRedirect } from 'containers/L2Objects/CampaignRedirect';
import { L1ObjectRedirect } from 'containers/L1Objects/L1ObjectRedirect';
import { CreativeRedirect } from 'containers/Creatives/CreativeRedirect';
import { BindingRedirect } from 'containers/L1Objects/BindingRedirect';
import { Advertisers } from 'containers/Advertisers/Advertisers';
import PermissionContext from 'contexts/permissionContext';
import { addOnEnabled, hasFuncs, hasRoles, notRoles } from 'core/permission/PermissionDSL';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { RoleNames } from 'core';
import CoreContext from 'contexts/coreContext';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { StoredValue } from 'containers/StoredValue/StoredValue';
import { ElementScrollControl } from 'components/ElementScrollControl/ElementScrollControl';
import { RetailRMN } from 'containers/RetailRMN';
import { BidAdjustmentJobs } from 'containers/BidAdjustmentJob/BidAdjustmentJobs';
import { CloudCostList } from 'containers/CloudCost/CloudCostList';

export class MainPage extends React.Component<RouteComponentProps<{}> & MainPageProps, MainPageState> {

  handler?: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
  }

  componentDidMount () {
    this.props.model.init();
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  componentDidUpdate (prevProps, prevState: MainPageState) {
    if (prevState.permissionContext.actor !== this.props.model.state.permissionContext.actor) {
      this.removeErrorCodeOnActorChange();
    }
  }

  removeErrorCodeOnActorChange () {
    const errorCodeReg = /\/(?:error403|error404)$/;
    if (this.props.location.pathname.match(errorCodeReg)) {
      this.props.history.replace(this.props.location.pathname.replace(errorCodeReg, ''));
    }
  }

  renderAccount = (props) => {
    return (
      <Accounts
        model={this.props.model.accountsModel}
      />
    );
  }

  renderOrders = (props) => {
    return (
      <Orders
        model={this.props.model.ordersModel}
      />
    );
  }

  renderCreatives = (props) => {
    return (
      <Creatives
        {...props}
        model={this.props.model.creativesModel}
      />
    );
  }

  renderReports = (props) => {
    return (
      <Reports
        {...props}
        core={this.props.model.core}
      />
    );
  }

  renderRetailRMN = (props) => {
    const mainPagePath = this.props.model.mainPagePath;

    return (
      <RetailRMN
        {...props}
        model={this.props.model.retailRMNModel}
        mainPagePath={mainPagePath}
      />
    );
  }

  renderSwagger = (props) => {
    const authenticationManager = this.props.model.core && this.props.model.core.authenticationManager;
    return (
      <Swagger authenticationManager={authenticationManager} />
    );
  }

  renderAgencies = (props) => {
    return (
      <Agencies
        model={this.props.model.agenciesModel}
      />
    );
  }

  renderCampaignDetail = (props) => {
    const campaignId = props.match.params.campaignId;
    return (
      <CampaignRedirect
        campaignId={campaignId}
      />
    );
  }

  renderL1ObjectDetail = (props) => {
    const l1ObjectId = props.match.params.l1ObjectId;
    return (
      <L1ObjectRedirect
        l1ObjectId={l1ObjectId}
      />
    );
  }

  renderCreativeDetail = (props) => {
    const creativeId = props.match.params.creativeId;
    return (
      <CreativeRedirect
        creativeId={creativeId}
      />
    );
  }

  renderBindingDetail = (props) => {
    const query = new URLSearchParams(props.location.search);
    const l1ObjectId = query.get('l1ObjectId');
    const campaignId = query.get('campaignId');
    if (!l1ObjectId || !campaignId) {
      return <Redirect to='/'/>;
    }
    return (
      <BindingRedirect
        l1ObjectId={l1ObjectId}
        campaignId={campaignId}
      />
    );
  }

  renderAppMenuBar = () => {
    return (
      <AppMenuBar model={this.props.model.barModel} />
    );
  }

  renderAdvertisers = () => {
    return (
      <Advertisers model={this.props.model.advertisersModel} />
    );
  }

  renderErrorPage = (errorCode) => {
    const onBackClicked = (path: string) => {
      this.props.history.push(path);
    };
    return (
      <ErrorPage
        errorCode={errorCode}
        onBackClicked={onBackClicked}
        currentPath={this.props.location.pathname}
      />
    );
  }

  renderMainPage = () => {
    const mainPagePath = this.props.model.mainPagePath;
    return <Redirect to={mainPagePath} />;
  }

  renderStoredValue = () => {
    return <StoredValue />;
  }

  renderBidAdjustmentJobs = () => {
    return <BidAdjustmentJobs />;
  }

  renderCloudCosts = () => {
    return <CloudCostList />;
  }

  renderMainPageContent = () => {
    const indicator = this.state.loading ? <LoadingIndicator /> : null;
    const model = this.props.model;
    const account = model.account;
    const { sysAdmin, agencyReport, adsReport, agencyAdmin, agencyManager } = RoleNames;
    const renderEmpty = () => (<div />);

    return (
      <div className='main-page'>
        <AppHeader model={model.headerModel} />
        <div className='main-page-container'>
          {
            account === null || indicator ?
              indicator :
              <Fragment>
                <Switch>
                  <Route path={`*/${ROUTE_PATH.ERROR403}`} render={this.renderAppMenuBar} />
                  <Route path={`*/${ROUTE_PATH.ERROR404}`} render={this.renderAppMenuBar} />
                  <Route path='/orders/:orderNumber/campaigns' render={renderEmpty} />
                  <Route path='/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns' render={renderEmpty} />
                  <Route path='/creatives/new' render={renderEmpty} />
                  <Route path='/creatives/:creativeId/edit' render={renderEmpty} />
                  <Route render={this.renderAppMenuBar} />
                </Switch>
                <div className='content'>
                  <ElementScrollControl>
                    <Switch>
                      <Route path={`*/${ROUTE_PATH.ERROR403}`} component={_.partial(this.renderErrorPage, 403)} />
                      <Route path={`*/${ROUTE_PATH.ERROR404}`} component={_.partial(this.renderErrorPage, 404)} />
                      <Route path='/reports' render={this.renderReports} />
                      <RouteWithPermission
                        path='/accounts'
                        permissionAware={hasFuncs(Permission.ACCOUNT_READ)}
                        redirectPath={`/accounts/${ROUTE_PATH.ERROR403}`}
                        render={this.renderAccount}
                      />
                      <RouteWithPermission
                        path='/agencies'
                        permissionAware={hasFuncs(Permission.AGENCY_READ)}
                        redirectPath={`/agencies/${ROUTE_PATH.ERROR403}`}
                        render={this.renderAgencies}
                      />
                      <RouteWithPermission
                        path='/orders'
                        permissionAware={hasFuncs(Permission.ORDER_READ)}
                        redirectPath={`/orders/${ROUTE_PATH.ERROR403}`}
                        render={this.renderOrders}
                      />
                      <RouteWithPermission
                        exact
                        path='/creatives/:creativeId/redirectToDetail'
                        permissionAware={hasFuncs(Permission.CREATIVE_READ)}
                        redirectPath={`/${ROUTE_PATH.ERROR403}`}
                        render={this.renderCreativeDetail}
                      />
                      <RouteWithPermission
                        path='/creatives'
                        permissionAware={hasFuncs(Permission.CREATIVE_READ).and(notRoles(sysAdmin, agencyReport, adsReport))}
                        redirectPath={`/creatives/${ROUTE_PATH.ERROR403}`}
                        render={this.renderCreatives}
                      />
                      <RouteWithPermission
                        path='/advertisers'
                        permissionAware={hasFuncs(Permission.ADVERTISER_READ)}
                        redirectPath={`/advertisers/${ROUTE_PATH.ERROR403}`}
                        render={this.renderAdvertisers}
                      />
                      <RouteWithPermission
                        path='/campaigns/:campaignId/redirectToDetail'
                        permissionAware={hasFuncs(Permission.ORDER_READ, Permission.CAMPAIGN_READ)}
                        redirectPath={`/${ROUTE_PATH.ERROR403}`}
                        render={this.renderCampaignDetail}
                      />
                      <RouteWithPermission
                        path='/campaign-groups/:l1ObjectId/redirectToDetail'
                        permissionAware={hasFuncs(Permission.ORDER_READ, Permission.CAMPAIGN_READ)}
                        redirectPath={`/${ROUTE_PATH.ERROR403}`}
                        render={this.renderL1ObjectDetail}
                      />
                      <RouteWithPermission
                        path='/bindings/:bindingId/redirectToDetail'
                        permissionAware={hasFuncs(Permission.ORDER_READ, Permission.CAMPAIGN_READ, Permission.CREATIVE_READ)}
                        redirectPath={`/${ROUTE_PATH.ERROR403}`}
                        render={this.renderBindingDetail}
                      />
                      <RouteWithPermission
                        path='/stored-value'
                        permissionAware={hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager).and(addOnEnabled(ADDONFEATURE.COMPANY.STORED_VALUE)))}
                        redirectPath={`/stored-value/${ROUTE_PATH.ERROR403}`}
                        render={this.renderStoredValue}
                      />
                      <RouteWithPermission
                        path='/bid-adjustments'
                        permissionAware={hasRoles(sysAdmin)}
                        redirectPath={`/bid-adjustments/${ROUTE_PATH.ERROR403}`}
                        render={this.renderBidAdjustmentJobs}
                      />
                      <RouteWithPermission
                        path='/cloud-costs'
                        permissionAware={hasRoles(sysAdmin)}
                        redirectPath={`/cloud-costs/${ROUTE_PATH.ERROR403}`}
                        render={this.renderCloudCosts}
                      />
                      <Route path='/api-swagger' render={this.renderSwagger} />
                      <Route render={this.renderRetailRMN} />
                      <Route render={this.renderMainPage} />
                    </Switch>
                  </ElementScrollControl>
                </div>
              </Fragment>
          }
        </div>
      </div>
    );
  }

  render () {
    const model = this.props.model;
    return (
      <MenuBarExpandContext.Provider
        value={this.props.model.barModel.state.expanded}
      >
        <PermissionContext.Provider
          value={model.state.permissionContext}
        >
          <CoreContext.Provider
            value={model.core}
          >
            {this.renderMainPageContent()}
          </CoreContext.Provider>
        </PermissionContext.Provider>
      </MenuBarExpandContext.Provider>
    );
  }
}
