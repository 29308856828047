import _ from 'lodash';

import client from './RestClient';
import { Creative, CreativeBasic, CreativeListRecord, CreativeOfCampaign, CreativeStatus, CreativeType } from 'core/creative/Creative';
import { Pageable } from './Pageable';
import { Pagination } from 'core/pagination/Pagination';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { AxiosInstance } from 'axios';
import { Product, ProductAvailability } from 'core/product/Product';

export interface CreativeWebService {
  getCreativesByCampaignId (campaignId: string | number, report?: boolean): Promise<Array<CreativeOfCampaign>>;
  getWaitingBindCreatives (payload: WaitingBindGetParams): Promise<CreativeOfCampaign[]>;
  reviewCreatives (creativeIds: Array<number>, adxs: Array<string>): Promise<void>;
  getCreative (creativeId: number): Promise<any>;
  createCreative (creativeformData: FormData): Promise<{
    creativeId: number
  }>;
  createCreativesByJson (creatives: any): Promise<{
    data: any[],
    error: {
      error_message: string | null,
      name: string
    }[]
  } | undefined>;
  updateCreative (creativeformData: FormData): Promise<number>;
  getCreatives (creativeType: CreativeType, advertiserId: number | undefined, pageable: Pageable, search?: string): Promise<CreativesWithPagination>;
  cloneCreatives (creatives: Array<number>): Promise<void>;
  deleteCreative (creativeIds: Array<number>): Promise<void>;
  getCreativeName (creativeId: number): Promise<string | null>;
}

export type WaitingBindGetParams = {
  channel: L1ObjectChannel;
  advertiserId: number;
  adType: string;
};

const completeProduct = (product: Partial<Product>): Product | undefined => {
  if (!product) {
    return undefined;
  }
  return {
    name: _.defaultTo(product.name, ''),
    category: _.defaultTo(product.category, ''),
    imgLink: _.defaultTo(product.imgLink, ''),
    productId: _.defaultTo(product.productId, ''),
    listPrice: _.defaultTo(product.listPrice, 0),
    multipack: _.defaultTo(product.multipack, 0),
    adServingUrl: _.defaultTo(product.adServingUrl, ''),
    availability: _.defaultTo(product.availability, ProductAvailability.OUT_OF_STOCK)
  };
};

function wrapWaitingBindCreative (json: any): CreativeOfCampaign {
  const impression = _.get(json, 'report.impres', 0);
  const clicks = _.get(json, 'report.clicks', 0);
  const ctr = impression > 0 ? clicks / impression : 0;
  const creativeType = _.get(json, 'creativeType');
  const creativeValues = _.get(json, 'creativeValues', {});
  const creativeValuesJSON = typeof creativeValues === 'string' ? JSON.parse(creativeValues) : creativeValues;
  let size = _.get(json, 'width', 0) + 'X' + _.get(json, 'height', 0);
  const creativeId = _.get(json, 'creativeId');
  const status = _.get(json, 'status');
  const deilivery = _.get(json, 'deilivery');
  const rtbCreativeId = _.get(json, 'rtbCreativeId');
  return {
    report: _.get(json, 'report'),
    rtbCreativeId: rtbCreativeId ? rtbCreativeId : _.get(json, 'creativeId'),
    id: creativeId ? creativeId : _.get(json, 'l3ChannelId'),
    name: _.get(json, 'creativeName'),
    status:  _.get(json, 'creativeState', CreativeStatus.ACTIVE),
    ctr: ctr,
    imps: impression,
    clicks: clicks,
    size,
    creativeType,
    approvalStatus: _.get(json, 'approvalStatus'),
    bannerImageUrl: _.get(json, 'bannerImageUrl'),
    creativeValue: creativeValues,
    createTime: _.get(json, 'ctime'),
    landingPageUrl: _.get(json, 'landingPageUrl', ''),
    isActiveBinding: _.get(json, 'isActiveBinding', true),
    bindingState: status ? status : _.get(json, 'bindingState', 0),
    tenmaxCategory: _.get(json, 'tenmaxCategory'),
    origTenmaxCategory: _.get(json, 'origTenmaxCategory'),
    effectiveStatus: deilivery ? deilivery : _.get(json, 'effectiveStatus'),
    issuesInfo: _.get(json, 'issuesInfo[0].error_message', ''),
    l3ChannelId: _.get(json, 'l3ChannelId', ''),
    bannerExtra: _.get(json, 'bannerExtra'),
    previewLink: _.get(json, 'previewLink'),
    retail: _.get(json, 'retail'),
    product: completeProduct(_.get(creativeValuesJSON, 'product'))
  };
}

function wrapCreativeBasic (json: any): CreativeBasic {
  const creativeValues = _.get(json, 'creativeValues');
  const creativeValuesJSON = typeof creativeValues === 'string' ? JSON.parse(creativeValues) : creativeValues;
  const basic: CreativeBasic = {
    advertiserId: _.get(json, 'advertiserId'),
    agencyId: _.get(json, 'agencyId'),
    bannerUrl: _.get(json, 'bannerUrl'),
    creativeId: _.get(json, 'creativeId'),
    creativeType: _.get(json, 'creativeType'),
    creativeValues: _.get(json, 'creativeValues'),
    landingPageUrl: _.get(json, 'landingPageUrl'),
    name: _.get(json, 'name'),
    tenmaxCategory: _.get(json, 'tenmaxCategory'),
    bannerExtra: _.get(json, 'bannerExtra'),
    bannerImageUrl: _.get(json, 'bannerImageUrl'),
    retail: _.get(json, 'retail'),
    product: completeProduct(_.get(creativeValuesJSON, 'product'))
  };

  return basic;
}

function wrapCreative (json: any): Creative {
  return {
    basic: wrapCreativeBasic(_.get(json, 'creative'))
  };
}

function wrapCreativeListRecord (json: any): CreativeListRecord {
  const creativeValues = _.get(json, 'creativeValues');
  const creativeValuesJSON = typeof creativeValues === 'string' ? JSON.parse(creativeValues) : creativeValues;
  return {
    advertiserId: _.get(json, 'advertiserId'),
    approvalStatus: _.get(json, 'approvalStatus'),
    bannerImageUrl: _.get(json, 'bannerImageUrl'),
    bannerUrl: _.get(json, 'bannerUrl'),
    bindingCampaign: _.get(json, 'bindingCampaign'),
    bindingCount: _.get(json, 'bindingCount'),
    createTime: _.get(json, 'createTime'),
    creativeId: _.get(json, 'creativeId'),
    creativeState: _.get(json, 'creativeState'),
    creativeType: _.get(json, 'creativeType'),
    creativeValues: creativeValues,
    duration: _.get(json, 'duration'),
    name: _.get(json, 'name'),
    tenmaxCategory: _.get(json, 'tenmaxCategory'),
    origTenmaxCategory: _.get(json, 'origTenmaxCategory'),
    bannerExtra: _.get(json, 'bannerExtra'),
    retail: _.get(json, 'retail'),
    product: _.get(creativeValuesJSON, 'product'),
    updateTime: _.get(json, 'updateTime')
  };
}

function wrapCreativeWatingBindList (json: any): Array<CreativeOfCampaign> {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapWaitingBindCreative(json)
  );
}

function wrapCreativList (json: any): Array<CreativeListRecord> {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapCreativeListRecord(json)
  );
}

function wrapPagination (json: any): Pagination {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
}

export type CreativesWithPagination = {
  pagination: Pagination,
  creatives: Array<CreativeListRecord>
};

export class RestfulCreativeWebService implements CreativeWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getCreativesByCampaignId (campaignId: string | number, report: boolean = false): Promise<Array<CreativeOfCampaign>> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}/creatives?report=${report}`);
    return wrapCreativeWatingBindList(response.data.records).filter(creative => creative.status !== CreativeStatus.DELETED);
  }

  async getWaitingBindCreatives (payload: WaitingBindGetParams): Promise<CreativeOfCampaign[]> {
    const {
      channel,
      advertiserId,
      adType
    } = payload;
    let apiApth = `/v2/l3-object/${channel}/waiting-bind?advertiser_id=${advertiserId}&ad_type=${adType}`;
    const response = await this.restClient.get(apiApth);
    return wrapCreativeWatingBindList(response.data.records).filter(creative => creative.creativeType in CreativeType);
  }

  async reviewCreatives (creativeIds: Array<number>, adxs: Array<string>): Promise<void> {
    return this.restClient.post(`/v2/creatives/review`, {
      creativeIds: creativeIds,
      adxs: adxs
    });
  }

  async getCreative (creativeId: number): Promise<Creative> {
    const response = await this.restClient.get(`v2/creatives/${creativeId}`);
    return wrapCreative(response.data);
  }

  async createCreative (creativeformData: FormData): Promise<{
    creativeId: number
  }> {
    const response = await this.restClient.post('v2/creatives/', creativeformData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return {
      creativeId: _.get(response.data, 'creative.creativeId', 0)
    };
  }

  async createCreativesByJson (creatives: any): Promise<{
    data: any[],
    error: {
      error_message: string | null,
      name: string
    }[]
  } | undefined> {
    const response = await this.restClient.post('v2/creatives/batch-create', creatives);
    return response.data;
  }

  async updateCreative (creativeformData: FormData): Promise<number> {
    const response = await this.restClient.post(`v2/creatives/${creativeformData.get('creative.id')}`, creativeformData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return _.get(response.data, 'creative.creativeId', 0);
  }

  async getCreatives (creativeType: CreativeType, advertiserId: number | undefined, pageable: Pageable, search?: string): Promise<CreativesWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    direction = direction ? direction : 'desc';
    let apiPath = `/v2/creatives?banner_type=${creativeType}&page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    if (advertiserId) {
      apiPath = `/v2/advertisers/${advertiserId}/creatives?banner_type=${creativeType}&page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    }
    if (search) {
      apiPath = `${apiPath}&search=${encodeURIComponent(search)}`;
    }
    const response = await this.restClient.get(apiPath);
    return {
      creatives: wrapCreativList(response.data.records),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async cloneCreatives (creatives: Array<number>): Promise<void> {
    return this.restClient.post(`/v2/creatives/${creatives.join(',')}/copy`);
  }

  async deleteCreative (creativeIds: Array<number>): Promise<void> {
    return this.restClient.delete(`/v2/creatives?creativeIds=${creativeIds.join(',')}`);
  }

  async getCreativeName (creativeId: number): Promise<string | null> {
    const response = await this.restClient.get(`/v2/creatives/${creativeId}/name`);
    return response.data.result;
  }
}
