import { SearchBar } from 'components/SearchBar';
import styles from './listOperationArea.module.scss';

export type ListOperationAreaWithSearchBarProps = {
  defaultSearchString?: string;
  searchbarPlaceholder: string;
  onHandleSearch: (searchString: string) => void;
};

export const ListOperationArea: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  return <div className={styles.operationArea}>{children}</div>;
};

export const ListOperationAreaWithSearchBar: React.FC<
  ListOperationAreaWithSearchBarProps & React.PropsWithChildren
> = ({
  children,
  defaultSearchString,
  searchbarPlaceholder,
  onHandleSearch
}) => {
  return (
    <ListOperationArea>
      {children}
      <SearchBar
        model={{
          placeholder: searchbarPlaceholder,
          search: onHandleSearch,
          defaultValue: defaultSearchString
        }}
      />
    </ListOperationArea>
  );
};
