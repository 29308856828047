import { FieldArray } from 'formik';
import { defaultTo, get } from 'lodash';
import { allAdTypeOptions, allConstraintTypeOptions, BidAdjustmentField } from './BidAdjustmentField';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { useCallback } from 'react';
import { BidAdjustment } from 'core/bidAdjustmentJob/BidAdjustmentJob';
import styles from './bidAdjustmentJobForm.module.scss';

export const renderBidAdjustmentFieldArray = (availableConstraintAndAdTypeMap: {
  [constraintType: string]: AdType[];
}) => {
  return (
    <BidAdjustmentFieldArray
      availableConstraintAndAdTypeMap={availableConstraintAndAdTypeMap}
    />
  );
};

export const BidAdjustmentFieldArray: React.FC<{
  availableConstraintAndAdTypeMap: {
    [constraintType: string]: AdType[];
  };
}> = ({
  availableConstraintAndAdTypeMap
}) => {

  const getConstraintTypeOptions = useCallback(
    (adjustment: BidAdjustment) => {
      const availableConstraintTypes = [
        ...Object.keys(availableConstraintAndAdTypeMap).filter(
          (constraintType) => {
            return availableConstraintAndAdTypeMap[constraintType].includes(
              adjustment.adType
            );
          }
        ),
        adjustment.constraintType
      ];
      return allConstraintTypeOptions.filter((option) => {
        return availableConstraintTypes.includes(option.value);
      });
    },
    [availableConstraintAndAdTypeMap]
  );

  const getAdTypeOptions = useCallback(
    (adjustment: BidAdjustment) => {
      const availableAdTypes = [
        ...defaultTo(
          availableConstraintAndAdTypeMap[adjustment.constraintType],
          []
        ),
        adjustment.adType
      ];
      return allAdTypeOptions.filter((option) => {
        return availableAdTypes.includes(option.value);
      });
    },
    [availableConstraintAndAdTypeMap]
  );

  return (
    <FieldArray name='adjustments'>
      {({ form, name }) => {
        const adjustments = get(form.values, name);
        const fields = adjustments.map((adjustment, index) => {
          const removeAdjustment = () => {
            const newAdjustments = [...adjustments];
            newAdjustments.splice(index, 1);
            form.setFieldValue(name, newAdjustments);
          };
          return (
            <BidAdjustmentField
              key={`${adjustment.constraintType}-${adjustment.adType}`}
              fieldName={`${name}.${index}`}
              value={adjustment}
              setFieldValue={form.setFieldValue}
              adTypeOptions={getAdTypeOptions(adjustment)}
              constraintTypeOptions={getConstraintTypeOptions(adjustment)}
              removeFilter={index > 0 ? removeAdjustment : undefined}
            />
          );
        });
        return <div className={styles.adjustmentFieldArray}>{fields}</div>;
      }}
    </FieldArray>
  );
};
