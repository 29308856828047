import { BidAdjustmentJobFormData, BidPriceConstraintType, ChooseVendorStrategy } from 'core/bidAdjustmentJob/BidAdjustmentJob';
import { useCallAPI } from 'hooks/useCallAPI';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { validateEmpty, validateStringMaxLength } from 'utils/ValidateUtils';
import i18n from 'i18n';
import { BidAdjustmentJobManager, DefaultBidAdjustmentJobManager } from 'core/bidAdjustmentJob/BidAdjustmentJobManager';
import { isEmpty, omitBy } from 'lodash';
import { AdType, LegacyOptimize } from 'core/rtbCampaign/RtbCampaign';
import { DefaultProductFilterManager, ProductFilterManager } from 'core/product/ProductFilterManager';
import { SelectOptions } from 'components/commonType';

export type BidAdjustmentJobFormModel = {
  loading: boolean;
  redirect?: string;
  initBidAdjustmentJob?: BidAdjustmentJobFormData;
  allVendorNumberOptions: SelectOptions[];
  validateForm: (values: BidAdjustmentJobFormData) => void;
  onSubmit: (values: BidAdjustmentJobFormData) => void;
};

const defaultBidAdjustmentJobManager: BidAdjustmentJobManager = new DefaultBidAdjustmentJobManager();
const defaultProductFilterManager: ProductFilterManager = new DefaultProductFilterManager();
export const useCreateBidAdjustmentJobFormModel = (): BidAdjustmentJobFormModel => {

  const { loading, callAPIs } = useCallAPI();
  const [redirect, setRedirect] = useState<string>();
  const [allVendorNumberOptions, setAllVendorNumberOptions] = useState<SelectOptions[]>([]);

  const validateVendorNumbers = useCallback(
    (chooseVendorStrategy: ChooseVendorStrategy, vendorNumbers: string[]) => {
      if (chooseVendorStrategy === ChooseVendorStrategy.ALL) {
        return;
      }

      const error = validateEmpty(vendorNumbers);
      if (error) {
        return error;
      }
      const allVendorNumbers = allVendorNumberOptions.map(
        (option) => option.value
      );
      return vendorNumbers.some(
        (vendorNumber: string) => !allVendorNumbers.includes(vendorNumber)
      )
        ? i18n.t<string>('bidAdjustmentJobForm.errors.vendorNumbersInvalid')
        : undefined;
    },
    [allVendorNumberOptions]
  );

  const validateForm = useCallback((values: BidAdjustmentJobFormData) => {
    return omitBy(
      {
        purpose: validateStringMaxLength(
          values.purpose,
          50,
          i18n.t<string>('bidAdjustmentJobForm.errors.purposeLength')
        ),
        vendorNumbers: validateVendorNumbers(values.chooseVendorStrategy, values.vendorNumbers)
      },
      isEmpty
    );
  }, [validateVendorNumbers]);

  const onSubmit = useCallback((values: BidAdjustmentJobFormData) => {
    callAPIs([() => defaultBidAdjustmentJobManager.createBidAdjustmentJob(values)], () => {
      setRedirect('/bid-adjustments');
    });
  }, [callAPIs]);

  const initBidAdjustmentJob: BidAdjustmentJobFormData = useMemo(() => ({
    purpose: '',
    scheduledTime: moment().add(1, 'days').startOf('day').hour(4).format('YYYY-MM-DD HH:mm:ss'),
    vendorNumbers: [],
    isInstant: false,
    adjustments: [{
      bidPrice: 0,
      constraintType: BidPriceConstraintType.BID_FLOOR,
      adType: AdType.KEYWORD,
      optimizeType: LegacyOptimize.CPC
    }],
    chooseVendorStrategy: ChooseVendorStrategy.ALL
  }), []);

  useEffect(() => {
    callAPIs([() => defaultProductFilterManager.getVendorNumberOptions(undefined, true)], (vendorNumberOptions: SelectOptions[]) => {
      setAllVendorNumberOptions(vendorNumberOptions);
    });
  }, [callAPIs]);

  return {
    loading,
    redirect,
    initBidAdjustmentJob,
    allVendorNumberOptions,
    validateForm,
    onSubmit
  };
};
