import React from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { Link, Redirect } from 'react-router-dom';

import style from './agencyForm.module.scss';
import { AgencyFormModel, AgencyFormProps, AgencyFormState } from '../AgencyForm/AgencyFormModel';

import { LoadingIndicator } from 'components/LoadingIndicator';
import i18n from 'i18n';
import AgencyBasicForm from './AgencyBasicForm';
import AgencyAddonForm from './AgencyAddonForm';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { ErrorHandler } from 'components/form/FormErrorHandler/FormErrorHandler';
import _ from 'lodash';

export class AgencyForm extends React.Component<AgencyFormProps, AgencyFormState> {
  model: AgencyFormModel;
  handler: number;
  mainDomRef: any;
  constructor (props: any) {
    super(props);
    this.model = this.props.model;
    this.state = this.model.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handler = this.model.event.add(model => {
      this.setState(model.state);
    });
    this.mainDomRef = React.createRef();
  }

  componentDidMount () {
    this.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      this.model = this.props.model;
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit (request: any) {
    request = _.omit(request, ['partnershipModeId']);
    this.model.save(request);
  }

  afterValidateForm = (formErrors) => {
    if (!formErrors || _.isEmpty(formErrors)) {
      this.model.setErrors(undefined);
      return;
    }

    this.model.onChangeTab(formErrors['addonProps'] || formErrors['bidPriceBase'] ? 'addon' : 'basic');
    this.model.setErrors(formErrors);
  }

  render () {
    const {
      defaultAgency,
      validate,
      cancelPath,
      timeZoneOptions,
      languageOptions,
      currencyOptions,
      priorityOptions,
      partnershipModeOptions,
      title,
      isNew,
      onVendorNumbersChange,
      updateInvalidBrandOptions
    } = this.model;
    const { isLoading, redirectUrl, inited, vendorOptions, brandOptions } =
      this.state;
    if (!inited) {
      return <LoadingIndicator />;
    }

    if (redirectUrl) {
      return (
        <Redirect to={redirectUrl} push />
      );
    }

    return (
      <div className={style.container}>
        {isLoading && <LoadingIndicator />}
        <header className={style.topPanel}>
          <TitleWithBreadcrumbs
            title={i18n.t<string>(title)}
            routes={this.props.model.breadcrumbs}
          />
        </header>
        <div className={style.innerContent} ref={this.mainDomRef}>
          <Formik
            initialValues={defaultAgency}
            enableReinitialize
            onSubmit={this.handleSubmit}
            validate={validate}
            validateOnBlur={false}
          >
            {
              (formikContext) => {
                const onSubmitButtonClick = () => {
                  formikContext.validateForm().then(this.afterValidateForm);
                };

                return (
                  <>
                    <ErrorHandler
                      parentRef={this.mainDomRef}
                      isSubmitting={formikContext.isSubmitting}
                      submitCount={formikContext.submitCount}
                    />
                    <Form>
                      <Tabs activeKey={this.model.state.currentTab} id='agency-detail' onSelect={this.model.onChangeTab}>
                        <Tab eventKey='basic' title={i18n.t<string>('agencyDetail.labels.basicTab')}>
                          <AgencyBasicForm
                            timeZoneOptions={timeZoneOptions}
                            languageOptions={languageOptions}
                            currencyOptions={currencyOptions}
                            priorityOptions={priorityOptions}
                            vendorOptions={vendorOptions}
                            brandOptions={brandOptions}
                            partnershipModeOptions={partnershipModeOptions}
                            isNew={isNew}
                            handleVendorNumbersChange={onVendorNumbersChange}
                            updateInvalidBrandOptions={updateInvalidBrandOptions}
                          />
                        </Tab>
                        <Tab eventKey='addon' title={i18n.t<string>('agencyDetail.labels.addonFeatureTab')} >
                          <AgencyAddonForm />
                        </Tab>
                      </Tabs>
                      <div className={style.buttonArea}>
                        <Button size='sm' type='submit' onClick={onSubmitButtonClick}>
                          {i18n.t<string>('common.buttons.submit')}
                        </Button>
                        <Link to={cancelPath} className='btn btn-secondary btn-sm'>
                          {i18n.t<string>('common.buttons.cancel')}
                        </Link>
                      </div>
                    </Form>
                  </>
                );
              }
            }
          </Formik>
        </div>
      </div>
    );
  }
}
