import { CloudCost, CloudCostCreateDTO } from 'core/cloudCost/CloudCost';
import { AxiosInstance } from 'axios';
import client from './RestClient';

export interface CloudCostWebService {
  getCloudCosts: () => Promise<CloudCost[]>;
  createCloudCost: (cloudCost: CloudCostCreateDTO) => Promise<void>;
}

export class RestfulCloudCostWebService implements CloudCostWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getCloudCosts (): Promise<CloudCost[]> {
    const response = await this.restClient.get('/v2/cloud-costs');
    return response.data.records;
  }

  async createCloudCost (cloudCost: CloudCostCreateDTO): Promise<void> {
    return this.restClient.post('/v2/cloud-costs', cloudCost);
  }
}
