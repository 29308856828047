import { getLabelValueColumn, getStatusDesColumn, renderColumn } from 'components/TableColumn/TableColumn';
import { BidAdjustmentJob, BidPriceConstraintType } from 'core/bidAdjustmentJob/BidAdjustmentJob';
import { BidAdjustmentJobManager, DefaultBidAdjustmentJobManager } from 'core/bidAdjustmentJob/BidAdjustmentJobManager';
import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import { camelCase } from 'lodash';
import styles from './bidAdjustmentJobList.module.scss';

export enum BidAdjustmentJobColumn {
  PURPOSE = 'purpose',
  AD_TYPE = 'adType',
  VENDOR_NUMBERS = 'vendorNumbers',
  FINISHED = 'finished',
  SCHEDULED_TIME = 'scheduledTime',
  ADJUSTMENTS = 'adjustments',
  CREATOR = 'creator'
}

const defaultBidAdjustmentJobManager: BidAdjustmentJobManager = new DefaultBidAdjustmentJobManager();
export const useBidAdjustmentJobListModel = () => {

  const { loading, callAPIs } = useCallAPI();
  const [ bidAdjustmentJobs, setBidAdjustmentJobs ] = useState<BidAdjustmentJob[]>([]);
  const [adType, setAdType] = useState<AdType>(AdType.KEYWORD);
  const [constraintType, setConstraintType] = useState<BidPriceConstraintType>(BidPriceConstraintType.BID_FLOOR);

  useEffect(() => {
    callAPIs(
      [() => defaultBidAdjustmentJobManager.getBidAdjustmentJobs()],
      (results: BidAdjustmentJob[]) => {
        setBidAdjustmentJobs(results);
      }
    );
  }, [callAPIs]);

  const getColumnDefinition = useCallback((column: BidAdjustmentJobColumn) => {
    return {
      dataField: column,
      text: i18n.t<string>(`bidAdjustmentJobList.headers.${column}`),
      classes: styles[column]
    };
  }, []);

  const columns = useMemo(() => {
    return [
      getColumnDefinition(BidAdjustmentJobColumn.PURPOSE),
      renderColumn(
        getColumnDefinition(BidAdjustmentJobColumn.AD_TYPE),
        (value: string) => i18n.t<string>(`adType.${camelCase(value)}`)
      ),
      renderColumn(
        getColumnDefinition(BidAdjustmentJobColumn.VENDOR_NUMBERS),
        (value?: string[]) =>
          value && value.length > 0
            ? value.join(', ')
            : i18n.t<string>('bidAdjustmentJobList.labels.allSuppliers')
      ),
      getStatusDesColumn(
        getColumnDefinition(BidAdjustmentJobColumn.FINISHED),
        (value: BidAdjustmentJob) => {
          return value.finished
            ? {
              des: i18n.t<string>('bidAdjustmentJobList.labels.finished'),
              color: 'black'
            }
            : {
              des: i18n.t<string>('bidAdjustmentJobList.labels.preparing'),
              color: 'light'
            };
        }
      ),
      getColumnDefinition(BidAdjustmentJobColumn.SCHEDULED_TIME),
      getLabelValueColumn(
        getColumnDefinition(BidAdjustmentJobColumn.ADJUSTMENTS),
        (record: BidAdjustmentJob) => ([{
          label: i18n.t<string>('bidAdjustmentJobList.labels.constraintType'),
          value: i18n.t<string>(`bidAdjustmentJob.constraintType.${camelCase(record.constraintType)}`)
        }, {
          label: i18n.t<string>('bidAdjustmentJobList.labels.optimizeType'),
          value: record.optimizeType
        }, {
          label: i18n.t<string>('bidAdjustmentJobList.labels.bidPrice'),
          value: `NTD ${record.bidPrice.toString()}`
        }])
      ),
      getColumnDefinition(BidAdjustmentJobColumn.CREATOR)
    ];
  }, [getColumnDefinition]);

  const filteredJobs = useMemo(() => {
    return bidAdjustmentJobs.filter(job => job.adType === adType && job.constraintType === constraintType);
  }, [bidAdjustmentJobs, adType, constraintType]);

  return {
    loading,
    columns,
    adType,
    constraintType,
    filteredJobs,
    setAdType,
    setConstraintType
  };
};
