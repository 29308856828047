import { FormContent } from 'components/form/Form';
import { FormConfig } from 'components/form/FormConfig';
import { useMemo } from 'react';
import i18n from 'i18n';
import { validateEmpty } from 'utils/ValidateUtils';

export const CloudCostFormContent = () => {

  const fields = useMemo(() => {
    return new FormConfig.FieldsBuilder()
      .addFormikInputGroup({
        name: 'currencyRate',
        label: i18n.t<string>('cloudForm.labels.currencyRate'),
        type: 'number',
        prefix: i18n.t<string>('cloudForm.labels.currencyRatePrefix'),
        postfix: 'NTD',
        validate: validateEmpty
      })
      .addFormikFileInput({
        type: 'image',
        name: 'currencyRateEvidence',
        label: i18n.t<string>('cloudForm.labels.currencyRateEvidence'),
        validTypes: ['image/*'],
        hints: [],
        validate: validateEmpty
      })
      .addFormikInputGroup({
        name: 'cloudCost',
        label: i18n.t<string>('cloudForm.labels.cloudCost'),
        type: 'number',
        prefix: i18n.t<string>('cloudForm.labels.cloudCostPrefix'),
        postfix: 'USD',
        validate: validateEmpty
      })
      .addFormikFileInput({
        type: 'image',
        name: 'cloudCostEvidence',
        label: i18n.t<string>('cloudForm.labels.cloudCostEvidence'),
        validTypes: ['image/*'],
        hints: [],
        validate: validateEmpty
      })
      .build();
  }, []);

  const formConfig = useMemo(() => {
    return new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(fields).withShadow(false).build()
      )
      .build();
  }, [fields]);

  return (
    <FormContent formConfig={formConfig}/>
  );
};
