import { CloudCostFormData } from 'core/cloudCost/CloudCost';
import { CloudCostManager, DefaultCloudCostManager } from 'core/cloudCost/CloudCostManager';
import { useCallAPI } from 'hooks/useCallAPI';
import i18n from 'i18n';
import { useCallback, useMemo } from 'react';

export type CloudCostFormModel = {
  title: string;
  loading: boolean;
  initCloudCost?: CloudCostFormData;
  onSave: (values: CloudCostFormData) => void;
};

const cloudCostManager: CloudCostManager = new DefaultCloudCostManager();

export const useCreateCloudCostFormModel = (onSaved: () => void): CloudCostFormModel => {

  const { loading, callAPIs } = useCallAPI();

  const initCloudCost: CloudCostFormData = useMemo(() => {
    return {
      currencyRate: '',
      cloudCost: ''
    };
  }, []);

  const onSave = useCallback(
    (values: CloudCostFormData) => {
      callAPIs([() => cloudCostManager.createCloudCost(values)], onSaved);
    },
    [callAPIs, onSaved]
  );

  return {
    title: i18n.t<string>('cloudForm.titles.create'),
    loading,
    initCloudCost,
    onSave
  };
};
