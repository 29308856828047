import React, { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import styles from './keywordsProposalsModal.module.scss';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { partial, union } from 'lodash';
import { Modal } from 'components/Modal/Modal';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import Button from 'components/Button/Button';
import { SearchBar } from 'components/SearchBar';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { KeywordProposal } from 'core/product/Product';
import { AdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';

export type keywordsProposalsModalProps = {
  dispatchShowModal: Dispatch<SetStateAction<boolean>>;
  addKeyword: (keywords: string[]) => void;
  inputKeywords: string[];
  preselectedKeywords: string[];
  trendingKeywords: string[];
  adRequestSourceManager: AdRequestSourceManager;
};

export enum KeywordsProposalsListColumns {
  KEYWORD = 'searchKeyword'
}

export const KeywordsProposalsModal: React.FC<keywordsProposalsModalProps> = ({
  dispatchShowModal,
  addKeyword,
  inputKeywords,
  preselectedKeywords,
  trendingKeywords,
  adRequestSourceManager
}) => {
  const [keywordsProposals, setKeywordsProposals] = useState<(KeywordProposal)[]>([]);
  const [selectedProposals, setSelectedProposals] = useState<string[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const { callAPIs, loading } = useCallAPI();
  const hasSelectedProposals = selectedProposals.length > 0;

  const fetchKeywordsProposals = useCallback(async () => {
    // fetch keywords proposals
    callAPIs([
      () => (adRequestSourceManager.getKeywordProposals(inputKeywords))
    ], (keywordProposals: KeywordProposal[]) => {
      const proposals = keywordProposals.filter((keywordProposal: KeywordProposal) => {
        return preselectedKeywords.indexOf(keywordProposal.searchKeyword) === -1 &&
          trendingKeywords.indexOf(keywordProposal.searchKeyword) === -1;
      });
      setKeywordsProposals(proposals);
    });
  }, [callAPIs, adRequestSourceManager, inputKeywords, preselectedKeywords, trendingKeywords]);

  const handleAutoFill = useCallback(() => {
    const keywords = keywordsProposals.map((keywordProposal: KeywordProposal) => keywordProposal.searchKeyword);
    addKeyword(keywords.slice(0, 50 - inputKeywords.length));
    dispatchShowModal(false);
  }, [addKeyword, dispatchShowModal, keywordsProposals, inputKeywords]);

  const handleSaveProposals = useCallback(() => {
    addKeyword(selectedProposals);
    dispatchShowModal(false);
  }, [addKeyword, dispatchShowModal, selectedProposals]);

  const renderKeywordsProposalsList = useMemo(() => {
    const filteredKeywordsProposals = keywordsProposals.filter((keywordProposal: KeywordProposal) => {
      const nameMatch = keywordProposal.searchKeyword.toLowerCase().includes(searchString.toLowerCase());
      return nameMatch;
    });
    const nameHeaderFormatter = (
      column: any,
      _1: string | number,
      { sortElement }
    ) => {
      return (
        <div className={styles.nameHeader}>
          {i18n.t<string>(column.text)}
          {sortElement}
        </div>
      );
    };
    const nameFormatter = (keyword: string, _1: object, _2: string | number, formatExtraData: any): any => {
      const { selectedProposals } = formatExtraData;
      const objectIsChecked = selectedProposals.indexOf(keyword) > -1;
      const handleOnSelect = () => {
        if (objectIsChecked) {
          setSelectedProposals(prev => prev.filter((item) => item !== keyword));
        } else {
          setSelectedProposals(prev => union(prev, [keyword]));
        }
      };
      return (
        <div className={styles.nameCell}>
          <div className={styles.selectCheckBox}>
            <input
              type='checkbox'
              checked={objectIsChecked}
              onChange={handleOnSelect}
              id={`input_${keyword}`}
            />
            <label htmlFor={`input_${keyword}`} />
          </div>
          <div className={styles.info}>
            <div className={styles.name}>
              {keyword}
            </div>
          </div>
        </div>
      );
    };
    const columnDefinition = (columnName: string, sortable: boolean = false, customLabel?: string): ColumnDefinition => ({
      ...sortableColumn(columnName, customLabel ? customLabel : `keywordsProposalList.labels.${columnName}`, sortable),
      classes: () => styles[columnName],
      headerClasses : () => styles[columnName]
    });
    const keywordColumn = renderColumn({
      ...columnDefinition(KeywordsProposalsListColumns.KEYWORD),
      formatExtraData: {
        selectedProposals
      }
    },
    nameFormatter,
    partial(nameHeaderFormatter));
    const columns = [keywordColumn];
    return (
      <div className={styles.contentArea}>
        <StickableBootstrapTable<KeywordProposal>
          hover
          stickFirstColumn
          stickLastColumn
          keyField={KeywordsProposalsListColumns.KEYWORD}
          data={filteredKeywordsProposals}
          columns={columns}
          noDataIndication={i18n.t<string>('limitation.labels.keywordsProposalsNoData')}
          hidePagination
        />
      </div>
    );
  }, [keywordsProposals, selectedProposals, searchString]);

  useEffect(() => {
    fetchKeywordsProposals();
  }, [fetchKeywordsProposals]);

  return (
    <Fragment>
      {loading && <LoadingIndicator/>}
      <Modal
        sliderScreen={true}
        title={i18n.t<string>('limitation.labels.keywordsProposalsModalTitle')}
        primaryButton={{
          title: i18n.t<string>('limitation.buttons.keywordsProposalsModalConfirm'),
          callback: handleSaveProposals,
          disabled: !hasSelectedProposals
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: partial(dispatchShowModal, false)
        }}
        dismiss={partial(dispatchShowModal, false)}
        animation={true}
      >
        <div className={styles.keywordsProposalsListArea}>
          <div className={styles.titleArea}>
            <div className={styles.editArea}>
              {hasSelectedProposals ?
                <ListEditBar
                  selectedItems={selectedProposals}
                  handleRemoveSelect={partial(setSelectedProposals, [])}
                /> :
                <Button
                  className={styles.editButton}
                  primary
                  onClick={handleAutoFill}
                >
                  {i18n.t<string>('limitation.buttons.keywordsProposalsModalAutoFill')}
                </Button>
              }
            </div>
            <div className={styles.searchArea}>
              <SearchBar
                model={{
                  placeholder: i18n.t<string>('keywordsProposalList.placeholders.searchbar'),
                  defaultValue: searchString,
                  search: setSearchString
                }}
              />
            </div>
          </div>
          {renderKeywordsProposalsList}
        </div>
      </Modal>
    </Fragment>
  );
};
