import { Modal } from 'components/Modal/Modal';
import styles from './saveTAModel.module.scss';
import i18n from 'i18n';
import { camelCase, isEmpty } from 'lodash';
import { SavedTargeting } from 'core/limitation/SavedTargeting';
import { useCallback, useMemo, useState } from 'react';
import { DefaultLimitationManager, LimitationManager } from 'core/limitation/LimitationManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { RtbCampaign } from 'core/rtbCampaign/RtbCampaign';
import { toServerStructure } from 'utils/LimitationUtil';
import { toast } from 'react-toastify';
import { Col, Form, Row } from 'react-bootstrap';
import classnames from 'classnames/bind';
import { LoadingIndicator } from 'components/LoadingIndicator';

const cx = classnames.bind(styles);

export type SaveTAModalProps = {
  campaign: RtbCampaign;
  savedTargetingList: SavedTargeting[];
  closeAddSaveTargetingModal: () => void;
};

const defaultLimitationManager: LimitationManager = new DefaultLimitationManager();

export const SaveTAModal: React.FC<SaveTAModalProps> = (
  {
    campaign,
    savedTargetingList,
    closeAddSaveTargetingModal
  }
) => {

  const { loading, callAPIs } = useCallAPI();
  const [newTAName, setNewTAName] = useState('');
  const { adType, advertiserId } = campaign.basic;
  const targetingValue = campaign.limitations;
  const products = useMemo(() => campaign.products || [], [campaign.products]);

  const namePrefix = i18n.t<string>(
    `saveTAModal.labels.${camelCase(adType)}TaNamePrefix`
  );

  const taNameHasBeenUsed = useMemo(() => {
    return savedTargetingList.some(ta => ta.name === `${namePrefix} ${newTAName}`);
  }, [namePrefix, newTAName, savedTargetingList]);

  const saveTargeting = useCallback(() => {
    const namePrefix = i18n.t<string>(
      `saveTAModal.labels.${camelCase(adType)}TaNamePrefix`
    );
    callAPIs(
      [
        () =>
          defaultLimitationManager.createSavedTargeting({
            name: `${namePrefix} ${newTAName}`,
            adType,
            advertiserId,
            targeting: {
              limitations: toServerStructure(targetingValue),
              products
            }
          })
      ],
      () => {
        toast.success(i18n.t<string>('saveTAModal.messages.createSuccess'));
        closeAddSaveTargetingModal();
      },
      () => {
        toast.error(i18n.t<string>('saveTAModal.messages.createFailed'));
      }
    );
  }, [
    adType,
    advertiserId,
    newTAName,
    targetingValue,
    products,
    callAPIs,
    closeAddSaveTargetingModal
  ]);

  const onChangeNewTAName = useCallback((e) => {
    setNewTAName(e.target.value);
  }, []);

  const renderNameInput = useCallback(() => {
    const inputClassName = cx({
      error: taNameHasBeenUsed
    });
    return (
      <div className={styles.nameInput}>
        <Form.Control
          className={inputClassName}
          type='text'
          value={newTAName}
          onChange={onChangeNewTAName}
          placeholder={i18n.t<string>('saveTAModal.placeholders.taName')}
        />
        {taNameHasBeenUsed &&
          <span className={styles.errorMessage}>
            {i18n.t<string>('saveTAModal.errors.taNameHasBeenUsed')}
          </span>
        }
      </div>
    );
  }, [newTAName, taNameHasBeenUsed, onChangeNewTAName]);

  return (
    <Modal
      className={styles.saveTAModal}
      title={i18n.t<string>('saveTAModal.title')}
      primaryButton={{
        title: i18n.t<string>('common.buttons.save'),
        callback: saveTargeting,
        disabled: taNameHasBeenUsed || isEmpty(newTAName)
      }}
      secondaryButton={{
        title: i18n.t<string>('common.buttons.close'),
        callback: closeAddSaveTargetingModal
      }}
      dismiss={closeAddSaveTargetingModal}
    >
      {loading && <LoadingIndicator/>}
      <Form.Group as={Row} controlId={'taName'}>
        <Col sm={3}>
          <Form.Label column >{i18n.t<string>('saveTAModal.labels.taName')}</Form.Label>
        </Col>
        <Col sm={9}>
          {renderNameInput()}
        </Col>
      </Form.Group>
    </Modal>
  );
};
