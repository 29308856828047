import React, { ReactElement } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import classnames from 'classnames/bind';
import styles from './withFieldLayout.module.scss';
import Tips from 'components/Tips/Tips';

const cx = classnames.bind(styles);

export interface FieldLayoutProps {
  direction?: any;
  labelColSm?: number;
  inputColSm?: number;
  hoverHint?: string;
  hint?: string | ReactElement | ReactElement[];
  fieldContentWidth?: number | string;
  required?: boolean;
  isFlexibleContent?: boolean;
  preText?: string;
  postText?: string;
  postComponent?: ReactElement;
  permanentHint?: any;
  className?: string;
  formGroupClassName?: string;
  label?: string;
  name?: string;
  error?: any;
  disabled?: boolean;
  postTextClassName?: string;
}

export function withFieldLayout<T> (WrapperComponent: React.FC<any> | typeof React.Component): React.FC<T & FieldLayoutProps> {
  const FormikField: React.FC<T & FieldLayoutProps> = ({
    className,
    labelColSm = 3,
    inputColSm = 9,
    label,
    hoverHint,
    hint,
    direction = Row,
    fieldContentWidth = 424,
    required,
    permanentHint,
    isFlexibleContent = false,
    preText,
    postText,
    error,
    postComponent,
    formGroupClassName,
    postTextClassName,
    ...props
  }: T & FieldLayoutProps) => {
    const fieldContentClass = cx(isFlexibleContent ? styles.flexibleFieldContent : styles.fieldContent, {
      containAdditionalTexts: preText && postText
    });
    const fieldComponentClass = isFlexibleContent ? styles.fieldComponent : styles.flexibleFieldComponent;
    const postTextClass = cx(styles.postText, postTextClassName);
    const hasError = error !== undefined;
    const fieldContent = (
      <div className={fieldContentClass}>
        {preText && <span className={styles.preText}>{preText}</span>}
        <div className={fieldComponentClass} style={{ width: fieldContentWidth }}>
          <WrapperComponent {...props} className={className} error={error}/>
        </div>
        {hasError && <div className={styles.error}><Tips>{error}</Tips></div>}
        {postText && <span className={postTextClass}>{postText}</span>}
        {postComponent &&
          <div className={styles.postComponent}>
            {postComponent}
          </div>
        }
        {hoverHint && <span className={styles.hoverHint}>{hoverHint}</span>}
        {hint && !hasError &&
          <span className={styles.hint}>
            {hint}
          </span>
        }
        {permanentHint && <span className={styles.permanentHint}>{permanentHint}</span>}
      </div>
    );

    const labelClassName = cx({
      flexible: isFlexibleContent,
      required
    });

    const labelContent = label !== undefined ? (
      <>
        {direction === Row ?
          <Col sm={isFlexibleContent ? undefined : labelColSm}>
            {label === '' ?
              <div/> :
              <Form.Label column className={labelClassName}>{label}</Form.Label>
            }
          </Col> :
          <Row className={styles.fullWidth}>
            <Form.Label className={labelClassName}>{label}</Form.Label>
          </Row>
        }
      </>
    ) : null;

    const formGroupClass = cx(formGroupClassName, 'form-group');
    return (
      <Form.Group controlId={props.name} as={direction} className={formGroupClass}>
        {
          direction === Row ?
            <>
              {labelContent}
              <Col sm={isFlexibleContent ? undefined : inputColSm}>{fieldContent}</Col>
            </> :
            <>
              {labelContent}
              <Row className={styles.fullWidth}>{fieldContent}</Row>
            </>
        }
      </Form.Group>
    );
  };
  const MemoedFormikField = React.memo(FormikField);
  MemoedFormikField.displayName = `${WrapperComponent.name}(withFieldLayout)`;
  return MemoedFormikField;
}
