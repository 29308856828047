import { SavedTargeting, SavedTargetingCreateDTO } from 'core/limitation/SavedTargeting';
import client from './RestClient';
import { AxiosInstance } from 'axios';

export interface LimitationWebService {
  getAgencyLimitationPreSet (type: string): Promise<Array<any>>;
  createSavedTargeting (dto: SavedTargetingCreateDTO): Promise<void>;
  deleteSavedTargeting (id: number): Promise<void>;
  getSavedTargeting (id: number): Promise<SavedTargeting>;
  getSavedTargetings (advertiserId: number): Promise<SavedTargeting[]>;
}

export class RestfulLimitationWebService implements LimitationWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getAgencyLimitationPreSet (type: string): Promise<Array<Object>> {
    const resp = await this.restClient.get(`v2/default-limitations/${type}`);
    return resp.data.records;
  }

  async createSavedTargeting (dto: SavedTargetingCreateDTO): Promise<void> {
    return this.restClient.post('/saved-targeting', dto);
  }

  async deleteSavedTargeting (id: number): Promise<void> {
    const resp = await this.restClient.delete(`/saved-targeting/${id}`);
    return resp.data;
  }

  async getSavedTargeting (id: number): Promise<SavedTargeting> {
    const resp = await this.restClient.get(`/saved-targeting/${id}`);
    return resp.data;
  }

  async getSavedTargetings (advertiserId: number): Promise<SavedTargeting[]> {
    const resp = await this.restClient.get(`/saved-targeting?advertiser_id=${advertiserId}`);
    return resp.data.records;
  }
}
