import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { SelectOptions } from 'components/commonType';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import _ from 'lodash';

import { Product, ProductWithPagination } from 'core/product/Product';
import { ProductFilter } from './ProductFilter';
import { DmpManager, DefaultDmpManager } from 'core/dmp/DmpManager';

import { useCallAPI } from 'hooks/useCallAPI';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { useHistory, useLocation } from 'react-router-dom';
import { useCoreContext } from 'contexts/coreContext';
import { Pagination } from 'core/pagination/Pagination';
import { DefaultProductFilterManager, ProductFilterManager } from 'core/product/ProductFilterManager';

type EditAction = {
  label: string;
  onClick: (selectedProducts: string[]) => void | Promise<void>;
};

const defaultDmpManager: DmpManager = new DefaultDmpManager();
const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();
const defaultProductFilterManager: ProductFilterManager = new DefaultProductFilterManager();
export type ProductHomeModel = {
  loading: boolean;
  filters: ProductFilter;
  productsData: Product[];
  pagination: Pagination;
  advertiserOptions: SelectOptions[];
  vendorOptions: SelectOptions[];
  brandOptions: SelectOptions[];
  defaultOrderOptions: SelectOptions[];
  minPrice: number | undefined;
  maxPrice: number | undefined;
  allProducts: Product[];
  vendorNumber: string;
  isAgency: boolean;
  editActions: () => EditAction[];
  handleSearchString: (searchString: string) => void;
  handleMinPriceInput: (e: any) => void;
  handleMaxPriceInput: (e: any) => void;
  handleSubmit: () => void;
  onProductListChange: (type: string, props: any) => void;
  category1Options: SelectOptions[];
  category2Options: SelectOptions[];
  category3Options: SelectOptions[];
  category4Options: SelectOptions[];
  handleCategory1Change: (category1: string) => void;
  handleCategory2Change: (category1: string | undefined, category2: string) => void;
  handleCategory3Change: (category1: string | undefined, category2: string | undefined, category3: string) => void;
  handleCategory4Change: (category4: string) => void;
  selectedFilterTypes: string[];
  filterTypeOptions: string[];
  handleAddFilterType: (filterType: string) => void;
  onVendorNumberChange: (vendorNumber: string) => void;
  onProductOwnerChange: (advertiser: number) => void;
  onBrandChange: (brand: string) => void;
  onDefaultOrderChange: (defaultOrder: string) => void;
  handleSearchQueries: (filters: ProductFilter) => any;
};

const allBrandOption: SelectOptions = { label: 'ALL', value: 'all' };

export const useProductHomeModel = (
  defaultFilters?: ProductFilter
): ProductHomeModel => {

  const allProductList = useRef<Product[]>([]);
  const categories = useRef<any>({});
  const [filters, setFilters] = useState<ProductFilter>(_.defaultTo(defaultFilters, {}));
  const [category1Options, setCategory1Options] = useState<SelectOptions[]>([]);
  const [category2Options, setCategory2Options] = useState<SelectOptions[]>([]);
  const [category3Options, setCategory3Options] = useState<SelectOptions[]>([]);
  const [category4Options, setCategory4Options] = useState<SelectOptions[]>([]);
  const [productsData, setProductsData] = useState<Product[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    size: 25,
    totalCount: 0
  });
  const [advertiserOptions, setAdvertiserOptions] = useState<SelectOptions[]>([]);
  const [vendorOptions, setVendorOptions] = useState<SelectOptions[]>([]);
  const [brandOptions, setBrandOptions] = useState<SelectOptions[]>([]);
  const [minPrice, setMinPrice] = useState<number>();
  const [maxPrice, setMaxPrice] = useState<number>();
  const [selectedFilterTypes, setSelectedFilterTypes] = useState<string[]>([]);
  const [filterTypeOptions, setFilterTypeOptions] = useState<string[]>([
    'priceMargin',
    'defaultOrder'
  ]);

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const defaultOrderOptions = createSelectOptions([
    'unlimited',
    'listPriceDesc',
    'listPriceAsc'
  ], 'productHome.filters.options.');

  const history = useHistory();
  const location = useLocation();
  const pathname = _.get(location, 'pathname');
  const core = useCoreContext();
  const localeMeta = useMemo(() => core?.accountManager.localeMeta, [core]);
  const isAgency = _.defaultTo(localeMeta?.isAgency, false);
  const agencyVendorNumber = localeMeta?.vendorNumber;
  const agencyId = localeMeta?.agencyId;
  const agencyName = _.defaultTo(localeMeta?.agencyName, '');
  const [vendorNumber, setVendorNumber] = useState<string>('');

  useEffect(() => {
    // clear location state to prevent user refresh page and used cached filter
    if (pathname === '/products') {
      history.replace(pathname, undefined);
    }
  }, [history, pathname]);

  const resetFilters = useCallback((resetBrand: boolean = true) => {
    setFilters(filter => ({
      ...filter,
      category1: '',
      category2: '',
      category3: '',
      category4: '',
      search: '',
      defaultOrder: '',
      minPrice: undefined,
      maxPrice: undefined,
      brand: resetBrand ? undefined : filter.brand
    }));
    setMinPrice(undefined);
    setMaxPrice(undefined);
    setCategory1Options([]);
    setCategory2Options([]);
    setCategory3Options([]);
    setCategory4Options([]);
  }, []);

  const updateSearchFilter = useCallback((searchString) => {
    setFilters(filter => ({
      ...filter,
      search: searchString
    }));
  }, [setFilters]);

  const updateMinPriceFilter = useCallback((minPrice) => {
    setFilters(filter => ({
      ...filter,
      minPrice: minPrice
    }));
  }, [setFilters]);

  const updateMaxPriceFilter = useCallback((maxPrice) => {
    setFilters(filter => ({
      ...filter,
      maxPrice: maxPrice
    }));
  }, [setFilters]);

  const handleCategory1Change = useCallback((category1: string) => {
    if (!filters.brand) {
      return;
    }
    setFilters(filter => ({
      ...filter,
      category1,
      category2: '',
      category3: '',
      category4: ''
    }));
    const categoriesOfBrand = _.defaultTo(categories.current[filters.brand], {});
    if (categoriesOfBrand[category1] === undefined) {
      setCategory2Options([]);
    } else {
      setCategory2Options(createSelectOptions(Object.keys(categoriesOfBrand[category1]), 'productHome.filters.options.'));
    }
    setCategory3Options([]);
    setCategory4Options([]);
  }, [filters.brand, setFilters]);

  const handleCategory2Change = useCallback((category1: string | undefined, category2: string) => {
    if (!filters.brand) {
      return;
    }
    setFilters(filter => ({
      ...filter,
      category2,
      category3: '',
      category4: ''
    }));
    const categoriesOfBrand = _.defaultTo(categories.current[filters.brand], {});
    if (category1 === undefined) {
      setCategory2Options([]);
    } else if (categoriesOfBrand[category1][category2] === undefined) {
      setCategory3Options([]);
    } else {
      setCategory3Options(createSelectOptions(Object.keys(categoriesOfBrand[category1][category2]), 'productHome.filters.options.'));
    }
    setCategory4Options([]);
  }, [filters.brand, setFilters]);

  const handleCategory3Change = useCallback((category1: string | undefined, category2: string | undefined, category3: string) => {
    if (!filters.brand) {
      return;
    }
    setFilters(filter => ({
      ...filter,
      category3,
      category4: ''
    }));
    const categoriesOfBrand = _.defaultTo(categories.current[filters.brand], {});
    if (category1 === undefined || category2 === undefined) {
      setCategory3Options([]);
    } else if (categoriesOfBrand[category1][category2][category3] === undefined) {
      setCategory4Options([]);
    } else {
      setCategory4Options(createSelectOptions(Object.keys(categoriesOfBrand[category1][category2][category3]), 'productHome.filters.options.'));
    }
  }, [filters.brand, setFilters]);

  const handleCategory4Change = useCallback((category4: string) => {
    setFilters(filter => ({
      ...filter,
      category4
    }));
  }, [setFilters]);

  const fetchAdvertiserOptions = useCallback(async () => {
    callAPIs(
      [defaultAdvertiserManager.getAdvertiserOptions.bind(defaultAdvertiserManager)],
      (advertisers: SelectOptions[]) => {
        setAdvertiserOptions(advertisers);
      }
    );
  }, [callAPIs]);

  useEffect(() => {
    fetchAdvertiserOptions();
  }, [fetchAdvertiserOptions]);

  const handleSearchQueries: any = useCallback((filters: ProductFilter) => {
    let category: string = '';
    if (filters.brand) {
      const categoryOfBrand = _.defaultTo(categories.current[filters.brand], undefined);
      const category1 = _.get(filters, 'category1', '');
      const category2 = _.get(filters, 'category2', '');
      const category3 = _.get(filters, 'category3', '');
      const category4 = _.get(filters, 'category4', '');
      if (!categoryOfBrand || categoryOfBrand[category1] === undefined) {
        category = '';
      } else if (categoryOfBrand[category1][category2] === undefined) {
        category = category1;
      } else if (categoryOfBrand[category1][category2][category3] === undefined) {
        category = `${category1} > ${category2}`;
      } else if (categoryOfBrand[category1][category2][category3][category4] === undefined) {
        category = `${category1} > ${category2} > ${category3}`;
      } else {
        category = _.compact([filters.category1, filters.category2, filters.category3, filters.category4]).join(' > ');
      }
    }
    const defaultOrder = _.get(filters, 'defaultOrder');
    let order;
    let sort;
    if (defaultOrder === 'listPriceDesc') {
      order = 'desc';
      sort = 'listPrice';
    } else if (defaultOrder === 'listPriceAsc') {
      order = 'asc';
      sort = 'listPrice';
    }

    return _.omitBy({
      search: filters.search,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice,
      category: !_.isEmpty(category) ? category : null,
      order,
      sort,
      brand: filters.brand === allBrandOption.value ? null : filters.brand
    }, _.isNil);
  }, []);

  const fetchProductsData = useCallback(async (page: number | undefined = 1) => {
    const canFetchProductData =
      !_.isEmpty(filters) &&
      !_.isNil(filters.advertiser) &&
      !_.isNil(vendorNumber) &&
      !_.isNil(filters.brand);
    if (canFetchProductData) {
      const queries = handleSearchQueries(filters, categories.current);
      const pageable = { page, sizePerPage: 25 };
      callAPIs(
        [() => defaultDmpManager.getRetailProducts(pageable, vendorNumber, queries)],
        (productWithPagination: ProductWithPagination) => {
          setPagination(productWithPagination.pagination);
          allProductList.current = _.uniqBy(allProductList.current.concat(productWithPagination.products), 'productId');
          setProductsData(productWithPagination.products);
        }
      );
    } else {
      setProductsData([]);
      setPagination({
        page: 1,
        size: 25,
        totalCount: 0
      });
    }
  }, [filters, vendorNumber, callAPIs, handleSearchQueries]);

  useEffect(() => {
    fetchProductsData();
  }, [fetchProductsData]);

  const onProductListChange = useCallback((type, props) => {
    if (type === 'sort') {
      return;
    }
    fetchProductsData(props.page);
  }, [fetchProductsData]);

  const editActions = (): EditAction[] => [];

  const debouncedUpdateSearchFilter = useMemo(() => _.debounce(updateSearchFilter, 1000), [updateSearchFilter]);

  const handleSearchString = (searchString: string): void => {
    if (searchString === '') {
      debouncedUpdateSearchFilter && debouncedUpdateSearchFilter.cancel();
      updateSearchFilter('');
    } else {
      debouncedUpdateSearchFilter(searchString);
    }
  };

  const debouncedUpdateMinPriceFilter = useMemo(() => _.debounce(updateMinPriceFilter, 1000), [updateMinPriceFilter]);

  const handleMinPriceInput = (e): void => {
    const minPrice = e.target.value;
    setMinPrice(minPrice);
    if (!minPrice) {
      debouncedUpdateMinPriceFilter && debouncedUpdateMinPriceFilter.cancel();
      updateMinPriceFilter(undefined);
    } else {
      debouncedUpdateMinPriceFilter(minPrice);
    }
  };

  const debounceUpdateMaxPriceFilter = useMemo(() => _.debounce(updateMaxPriceFilter, 1000), [updateMaxPriceFilter]);

  const handleMaxPriceInput = (e): void => {
    const maxPrice = e.target.value;
    setMaxPrice(maxPrice);
    if (!maxPrice) {
      debounceUpdateMaxPriceFilter && debounceUpdateMaxPriceFilter.cancel();
      updateMaxPriceFilter(undefined);
    } else {
      debounceUpdateMaxPriceFilter(maxPrice);
    }
  };

  const handleSubmit = () => {
    // This is intentional
  };

  const handleAddFilterType = (filterType: string) => {
    const newOptions = filterTypeOptions.filter(option => option !== filterType);
    setFilterTypeOptions(newOptions);
    setSelectedFilterTypes(selectedFilterTypes.concat(filterType));
  };

  const initCategory1Options = useCallback((vendorNumber: string, brand: string) => {
    if (categories.current[brand]) {
      setCategory1Options(createSelectOptions(Object.keys(categories.current[brand]), 'productHome.filters.options.'));
      return;
    }
    callAPIs(
      [() => defaultProductFilterManager.getCategories(vendorNumber, brand === allBrandOption.value ? undefined : brand)],
      (categoriesOfBrand: string[]) => {
        if (_.isEmpty(categoriesOfBrand)) {
          return;
        }
        categoriesOfBrand.forEach(path => {
          const keys = path.split('>').map(_.trim);
          const resultPath = keys.reduce((acc, key) => `${acc}.${key}`, '');
          _.set(categories.current, `${brand}${resultPath}`, {});
        });
        setCategory1Options(createSelectOptions(Object.keys(categories.current[brand]), 'productHome.filters.options.'));
      }
    );
  }, [callAPIs]);

  const onProductOwnerChange = useCallback((advertiser: number) => {
    if (advertiser === filters.advertiser) {
      return;
    }
    resetFilters(false);
    isAgency && setVendorNumber('');
    setFilters(filter => ({
      ...filter,
      advertiser
    }));
    if (!_.isEmpty(filters.brand) && !_.isEmpty(vendorNumber)) {
      initCategory1Options(vendorNumber, allBrandOption.value.toString());
    }
  }, [isAgency, filters.advertiser, filters.brand, vendorNumber, resetFilters, initCategory1Options]);

  const getBrands = useCallback((vendorNumber: string) => {
    callAPIs(
      [() => defaultProductFilterManager.getBrandOptionsByVendorNumber(vendorNumber)],
      (brandOptions: SelectOptions[]) => {
        if (!isAgency) {
          setBrandOptions([allBrandOption, ...brandOptions]);
          return;
        }
        setBrandOptions(brandOptions);
      }
    );
  }, [isAgency, callAPIs]);

  const onVendorNumberChange = useCallback(
    (newVendorNumber: string) => {
      if (vendorNumber === newVendorNumber) {
        return;
      }
      resetFilters();
      setVendorNumber(newVendorNumber);
      getBrands(newVendorNumber);
    },
    [vendorNumber, getBrands, resetFilters]
  );

  const onBrandChange = useCallback((brand: string) => {
    if (brand === filters.brand) {
      return;
    }

    resetFilters();
    setFilters(filter => ({
      ...filter,
      brand
    }));

    initCategory1Options(vendorNumber, brand);
  }, [filters.brand, vendorNumber, resetFilters, initCategory1Options]);

  useEffect(() => {
    if (isAgency) {
      callAPIs(
        [() => defaultProductFilterManager.getVendorNumberOptions(agencyId)],
        (vendors: SelectOptions[]) => {
          setVendorOptions(vendors);
        }
      );
      return;
    }

    if (agencyVendorNumber) {
      setVendorOptions([{ label: agencyName, value: agencyVendorNumber }]);
      setVendorNumber(agencyVendorNumber);
      getBrands(agencyVendorNumber);
      setFilters(filter => ({
        ...filter,
        brand: allBrandOption.value.toString()
      }));
      initCategory1Options(agencyVendorNumber, allBrandOption.value.toString());
    } else {
      setVendorOptions([]);
      setVendorNumber('');
      setBrandOptions([]);
    }
  }, [isAgency, agencyId, agencyName, agencyVendorNumber, resetFilters, getBrands, initCategory1Options, callAPIs]);

  const onDefaultOrderChange = useCallback((defaultOrder: string) => {
    setFilters((filter) => ({
      ...filter,
      defaultOrder
    }));
  }, []);

  return {
    loading,
    filters,
    isAgency,
    vendorNumber,
    productsData,
    pagination,
    advertiserOptions,
    vendorOptions,
    brandOptions,
    defaultOrderOptions,
    minPrice,
    maxPrice,
    allProducts: allProductList.current,
    editActions,
    handleSearchString,
    handleMinPriceInput,
    handleMaxPriceInput,
    handleSubmit,
    onProductListChange,
    category1Options,
    category2Options,
    category3Options,
    category4Options,
    handleCategory1Change,
    handleCategory2Change,
    handleCategory3Change,
    handleCategory4Change,
    selectedFilterTypes,
    filterTypeOptions,
    handleAddFilterType,
    onVendorNumberChange,
    onProductOwnerChange,
    onBrandChange,
    onDefaultOrderChange,
    handleSearchQueries
  };
};
