import _ from 'lodash';
import { AdType, CreativeDeliverType, RtbCampaignPlanType, DeliverType } from 'core/rtbCampaign/RtbCampaign';
import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import { Currency } from 'core';
import { toast } from 'react-toastify';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { RtbCampaignManager, DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { Order } from 'core/order/Order';
import { CampaignSetupTab } from './RtbCampaignSetupStepModel';
import i18n from 'i18next';
import {
  CreateKeywordCampaignSetupFlowStep,
  CreateRtbCampaignSetupFlowStep,
  EditKeywordCampaignSetupFlowStep,
  EditRtbCampaignSetupFlowStep,
  CreateRtbCampaignSetupFlowWithSavedTAStep,
  CreateKeywordCampaignSetupFlowWithSavedTAStep
} from '../RtbCampaignSetupFlowStep';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { BidStrategy } from 'core/l2Object/L2Object';
import { CreativeSummaryStepModel } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/CreativeSummaryStepModel';
import { getNumberRangeIndexHint } from 'utils/StringUtil';

export type RtbCampaignSummaryStepState = {
  readonly loading: boolean;
  readonly showPublishBindingFailed: boolean;
  readonly showAddSaveTargetingModal: boolean;
};

export interface RtbCampaignSummaryStepModel {
  readonly flowModel: RtbCampaignSetupFlowPageModel;
  readonly event: UpdateEventListener<RtbCampaignSummaryStepModel>;
  readonly state: RtbCampaignSummaryStepState;
  readonly targetingValue: any;
  readonly order: Order;
  readonly l1Object?: L1Object;
  readonly showLegacyEstimation: boolean;
  readonly subStepIndex: number;
  readonly creativeSummaryModel?: CreativeSummaryStepModel;
  updateState (loading: boolean): void;
  getAdTypeSummaryData: () => any;
  getSetUpSummaryData: () => any;
  getSmartHelperData: () => any;
  getLimitationsSummaryData: () => any;
  submit: (afterSubmit?: (campaignId: number) => void) => void;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  goSubStep: (subStepIndex: number) => void;
  redirectToLastPage: () => void;
  onShowAddSaveTargetingModal: (showModal: boolean) => void;
}

export type RtbCampaignSummaryStepProps = {
  readonly model: RtbCampaignSummaryStepModel;
};

export class DefaultRtbCampaignSummaryStepModel implements RtbCampaignSummaryStepModel {

  event: FireableUpdateEventListener<RtbCampaignSummaryStepModel>;
  loading: boolean;
  showPublishBindingFailed: boolean = false;
  showAddSaveTargetingModal: boolean = false;
  stepChangeListener?: (stepIndex, subStepName?: string) => void;

  constructor (
    public flowModel: RtbCampaignSetupFlowPageModel,
    public goLast: () => void,
    public goStep: (stepIndex: number, subStepIndex: number) => void,
    public goSubStep: (subStepIndex: number) => void,
    public subStepIndex: number,
    public creativeSummaryModel?: CreativeSummaryStepModel,
    protected campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager()
  ) {
    this.event = new FireableUpdateEventListener<RtbCampaignSummaryStepModel>();
    this.loading = false;
  }

  get state () {
    return {
      loading: this.loading,
      showPublishBindingFailed: this.showPublishBindingFailed,
      showAddSaveTargetingModal: this.showAddSaveTargetingModal
    };
  }

  get showLegacyEstimation () {
    return _.includes([
      L1ObjectChannel.RTB
    ], this.flowModel.l1Object.channel);
  }

  get targetingValue () {
    return this.flowModel.limitationModel ? this.flowModel.limitationModel.limitationValue : [];
  }

  get order () {
    return this.flowModel.order;
  }

  get l1Object () {
    return this.flowModel.l1Object;
  }

  getAdTypeSummaryData () {
    return {
      title: i18n.t<string>('campaignSummary.titles.adType'),
      backStep: CreateRtbCampaignSetupFlowStep.CHOOSE_ADTYPE,
      data: {
        adType: {
          title: undefined,
          content: [
            {
              label: i18n.t<string>('campaign.labels.adType'),
              value: this.getAdTypeValue(this.flowModel.state.campaign.basic.adType)
            }
          ]
        }
      }
    };
  }

  get campaignSetupStepIndex () {
    const isCreate = this.flowModel.type === 'create';
    const withChooseCreatingMethodStep = this.flowModel.state.savedTargetingList.length > 0;
    const isKeyword = [AdType.KEYWORD].includes(this.flowModel.state.campaign.basic.adType);
    if (withChooseCreatingMethodStep) {
      return isKeyword ?
        CreateKeywordCampaignSetupFlowWithSavedTAStep.SETUP_CAMPAIGN :
        CreateRtbCampaignSetupFlowWithSavedTAStep.SETUP_CAMPAIGN;
    }
    if (isKeyword) {
      return isCreate ? CreateKeywordCampaignSetupFlowStep.SETUP_CAMPAIGN : EditKeywordCampaignSetupFlowStep.SETUP_CAMPAIGN;
    }
    return isCreate ? CreateRtbCampaignSetupFlowStep.SETUP_CAMPAIGN : EditRtbCampaignSetupFlowStep.SETUP_CAMPAIGN;
  }

  getSmartHelperData () {
    const { campaign, campaignAnalytics } = this.flowModel.state;
    const getField = (field: string) => _.get(campaignAnalytics, field, 0);
    const toPercentages = (value: number) => value > 0 ? `+${(value * 100).toFixed(0)}%` : `${(value * 100).toFixed(0)}%`;
    const toRangeIndexHint = (rangeIndex: number) => getNumberRangeIndexHint(rangeIndex, 500, 10000);
    const adType = campaign.basic.adType;
    const adTypeConfigMap = {
      [AdType.KEYWORD]: [{
        field: 'brandAwarenessGrowth',
        handler: (field: string) => _.flow(getField, toPercentages)(field)
      }, {
        field: 'trafficGrowth',
        handler: (field: string) => _.flow(getField, toPercentages)(field)
      }, {
        field: 'keywordSearchVolume',
        handler: (field: string) => _.flow(getField, toRangeIndexHint)(field)
      }]
    };
    const configs: {
      field: string;
      handler: (field: string) => string;
    }[] = adTypeConfigMap[adType];
    return configs ? configs.map(config => {
      return {
        title: i18n.t<string>(`campaignSummary.hints.${config.field}`),
        value: config.handler(config.field)
      };
    }) : [];
  }

  getSetUpSummaryData () {
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const campaignBasicModel = this.flowModel.getRtbCampaignBasicFormModel(campaignSetUpData.adType);
    const priceModelRelatedSummaryData = this.getPriceModelRelatedSummaryData(campaignSetUpData.priceModel);
    const showDeliverType = _.get(campaignBasicModel, 'showDeliverType', true);
    const showOptimizeSection = _.get(campaignBasicModel, 'showOptimizeSection', true);
    const showTags = _.get(campaignBasicModel, 'showTags', true);
    const showBidPriceInGeneral = campaignSetUpData.priceModel === RtbCampaignPlanType.RS_CPC || campaignSetUpData.priceModel === RtbCampaignPlanType.RS_CPM;
    return {
      title: i18n.t<string>('campaignSummary.titles.basicSetting'),
      backStep: this.campaignSetupStepIndex,
      backSubStep: CampaignSetupTab.BASIC,
      data: _.omitBy({
        general: {
          title: i18n.t<string>('campaignSummary.titles.generalInformation'),
          content: _.compact(_.concat([
            {
              label: i18n.t<string>('campaignSummary.labels.orderName'),
              value: this.flowModel.order.projectName
            },
            {
              label: i18n.t<string>('campaignSummary.labels.campaignName'),
              value: campaignSetUpData.name
            },
            {
              label: i18n.t<string>('campaignSummary.labels.schedule'),
              value: `${campaignSetUpData.startDate} ~ ${campaignSetUpData.endDate}`
            },
            {
              label: i18n.t<string>('campaignSummary.labels.pricingModel'),
              value: this.getCampaignPlanTypeValue(campaignSetUpData.priceModel)
            },
            showBidPriceInGeneral && campaignSetUpData.bidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP ? {
              label: i18n.t<string>('campaignForm.labels.bidControl'),
              value: formatPriceWithCurrency(this.flowModel.order.currency, +(campaignSetUpData.bidPrice)),
              postText: this.campaignManager.getCampaignAnalyticsHint(this.flowModel.state.campaignAnalytics, 'bidPrice')
            } : undefined,
            showDeliverType ? {
              label: i18n.t('campaignSummary.labels.deliverType'),
              value: this.getDeliverTypeValue(campaignSetUpData.deliverType)
            } : undefined,
            showTags ? {
              label: i18n.t<string>('campaignSummary.labels.tags'),
              value: this.getTags(campaignSetUpData.tags)
            } : undefined
          ], priceModelRelatedSummaryData))
        },
        optimizeSetting: showOptimizeSection ? {
          title: i18n.t<string>('campaignForm.titles.optimization'),
          content: _.compact([
            {
              label: i18n.t<string>('campaignForm.labels.optimizationGoal'),
              value: i18n.t<string>(`optimizationGoal.${campaignSetUpData.optimize.toLowerCase()}`)
            },
            !showBidPriceInGeneral && campaignSetUpData.bidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP ? {
              label: i18n.t<string>('campaignForm.labels.bidControl'),
              value: formatPriceWithCurrency(this.flowModel.order.currency, +(campaignSetUpData.bidPrice))
            } : undefined,
            {
              label: i18n.t<string>('campaignForm.labels.billingEvent'),
              value: campaignSetUpData.priceModel === RtbCampaignPlanType.RS ?
                i18n.t<string>(`rtbCampaign.billingEvent.${campaignSetUpData.priceModel}.${campaignSetUpData.optimize.toLowerCase()}`) :
                i18n.t<string>(`rtbCampaign.billingEvent.${campaignSetUpData.priceModel}`)
            },
            campaignSetUpData.frequency && !_.isEmpty(campaignSetUpData.frequency) ? {
              label: i18n.t<string>('campaignForm.labels.frequencyControl'),
              value: this.getSessionCappaingCountValue(+campaignSetUpData.frequency.maxFrequency, +campaignSetUpData.frequency.intervalDays)
            } : undefined,
            campaignSetUpData.dayPart && {
              label: i18n.t<string>('campaignSummary.labels.dayPart'),
              value: this.getDayPartValue(campaignSetUpData.dayPart)
            }
          ])
        } : undefined
      }, _.isUndefined)
    };
  }

  getLimitationsSummaryData (): any {
    const limitations = this.flowModel.state.campaign.limitations;
    const summaryData = this.flowModel.getLimitationsSummaryData(limitations);
    return {
      title: i18n.t<string>('campaignSummary.titles.targetSetting'),
      backStep: this.campaignSetupStepIndex,
      backSubStep: CampaignSetupTab.LIMITATION,
      data: summaryData
    };
  }

  getPriceModelRelatedSummaryData (priceModel) {
    const currency = this.flowModel.order.currency;
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const needShowOrderPrice = priceModel !== RtbCampaignPlanType.RS;
    const needShowDailyTargetBudget = true;
    return _.compact([
      (needShowOrderPrice && campaignSetUpData.orderPrice) && {
        label: i18n.t<string>('l1Object.labels.bidStrategy.lowest_cost_with_bid_cap'),
        // i18n.t<string>('campaignSummary.labels.orderPrice', { model: this.getCampaignPlanTypeValue(priceModel) }),
        value: `${currency} ${campaignSetUpData.orderPrice}`
      },
      {
        label: i18n.t<string>('campaignSummary.labels.campaignBudget'),
        value: `${currency} ${campaignSetUpData.budget}`,
        postText: this.campaignManager.getCampaignAnalyticsHint(this.flowModel.state.campaignAnalytics, 'lifetimeBudgetRank')
      },
      (needShowDailyTargetBudget) && {
        label: i18n.t<string>('campaignSummary.labels.budgetDistribution'),
        value: this.getOptimizeBudgetValue(campaignSetUpData.dailyTargetBudget),
        postText: this.campaignManager.getCampaignAnalyticsHint(this.flowModel.state.campaignAnalytics, 'dailyBudgetRank')
      }
    ]);
  }

  getOptimizeBudgetValue (dailyTargetBudget) {
    const currency = this.flowModel ? this.flowModel.order.currency : Currency.NTD;
    if (dailyTargetBudget === null || dailyTargetBudget === undefined || dailyTargetBudget.toString() === '0') {
      return i18n.t<string>('campaignSummary.labels.budgetScheduleDistribution');
    } else {
      return i18n.t<string>('campaignSummary.labels.budgetDailyDistribution', { currency: currency, budget: dailyTargetBudget });
    }
  }

  getDayPartValue (dayPart) {
    let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
    return Object.keys(dayPartValue).map(day => {
      return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t<string>('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
    }).join('\r\n');
  }

  getSessionCappaingCountValue (maxFrequency: number, intervalDays: number) {
    return i18n.t<string>('campaignForm.descriptions.frequencyControl', {
      event: i18n.t<string>('campaignForm.descriptions.impression'),
      eventPlural: +(maxFrequency) > 1 ? 's' : '',
      interval_days: intervalDays,
      max_frequency: maxFrequency,
      unit: i18n.t<string>('common.units.day'),
      unitPlural: +(intervalDays) > 1 ? 's' : ''
    });
  }

  getCampaignPlanTypeValue (campaignPlanType) {
    return i18n.t<string>(`campaign.labels.${campaignPlanType}`);
  }

  getAdTypeValue (adType) {
    switch (adType) {
      case AdType.DISPLAY:
        return i18n.t<string>('campaignSummary.labels.adTypeDisplay');
      case AdType.KEYWORD:
        return i18n.t<string>('campaignSummary.labels.adTypeKeyword');
      default:
        return adType;
    }
  }

  getDeliverTypeValue (deliverType) {
    switch (deliverType) {
      case DeliverType.ACCELERATED:
        return i18n.t<string>('campaignSummary.labels.accelerated');
      default:
        return i18n.t<string>('campaignSummary.labels.standard');
    }
  }

  getTags (tags) {
    if (!tags || tags.length === 0) {
      return i18n.t<string>('summary.titles.noData');
    }

    return tags.join(',');
  }

  getCreativeDeliverTypeValue (creativeDeliverType) {
    switch (creativeDeliverType) {
      case CreativeDeliverType.OPTIMIZE:
        return i18n.t<string>('campaignSummary.labels.creativeOptimizeDelivery');
      default:
        return i18n.t<string>('campaignSummary.labels.creativeUniformDelivery');
    }
  }

  submit = async (afterSubmit?: (campaignId: number) => void) => {
    if (this.flowModel.type === 'edit') {
      await this.updateCampaign(this.flowModel.state.campaign, this.flowModel.order, this.flowModel.l1Object);
    } else if (this.flowModel.type === 'split') {
      await this.splitCampaign(this.flowModel.state.campaign, this.flowModel.order, this.flowModel.campaignId, this.flowModel.l1Object, afterSubmit);
    } else {
      await this.createCampaign(this.flowModel.state.campaign, this.flowModel.order, this.flowModel.l1Object, afterSubmit);
    }
  }

  splitCampaign = async (campaign: any, order: Order, campaignId, l1Object?: L1Object, afterSubmit?: (campaignId: number) => void) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      const newCampaignId = await this.campaignManager.splitCampaign(campaign, campaignId, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      if (afterSubmit) {
        afterSubmit(newCampaignId);
      } else {
        this.redirectToLastPage();
      }
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  createCampaign = async (campaign: any, order: Order, l1Object?: L1Object, afterSubmit?: (campaignId: number) => void) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      let newCampaignId;
      if (this.flowModel.isPmp) {
        const creative = await this.creativeSummaryModel!.getJsonToSubmit();
        newCampaignId = await this.campaignManager.createPmpCampaign(campaign, l1ObjectId!, this.flowModel.state.pmp!.id, creative);
      } else {
        newCampaignId = await this.campaignManager.createCampaign(campaign, l1ObjectId);
      }
      if (afterSubmit) {
        afterSubmit(newCampaignId);
      } else {
        toast.success(i18n.t<string>('common.messages.succeeded'));
        this.redirectToLastPage();
      }
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  updateCampaign = async (campaign: any, order: Order, l1Object?: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      await this.campaignManager.updateCampaign(campaign, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  redirectToLastPage = () => {
    const order = this.flowModel.order;
    const l1Object = this.flowModel.l1Object;
    const l1ObjectId = _.get(l1Object, 'l1ObjectId');
    const redirectPath = l1ObjectId ?
      `/orders/${order.orderNumber}/campaign-groups/${l1ObjectId}` :
      `/orders/${order.orderNumber}`;
    this.flowModel.setFinishedRedirectPath(redirectPath);
  }

  onShowAddSaveTargetingModal = (showModal: boolean) => {
    this.showAddSaveTargetingModal = showModal;
    this.updateState(false);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
