/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo } from 'react';
import TagsInput from 'react-tagsinput-special';
import { Button, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPlus, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import _ from 'lodash';
import 'react-tagsinput-special/react-tagsinput.css';
import styles from './tags.module.scss';
import { SelectOptions } from '../commonType';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import i18n from 'i18n';

const cx = classNames.bind(styles);

function defaultRenderTag (
  disableReason: string | undefined,
  suggestions: SelectOptions[] | undefined,
  props
) {
  const {
    tag,
    key,
    onRemove,
    classNameRemove,
    getTagDisplayValue,
    disabled,
    className,
    ...other
  } = props;

  const clickCallback = (e) => {
    onRemove(key);
  };
  const valueIsString = typeof tag === 'string';
  const tagValue = valueIsString ? tag : _.get(tag, 'value', '');
  const tagLabel = _.get(tag, 'label', tagValue);
  const tagIsError =
    _.get(tag, 'isError', false) ||
    (suggestions && suggestions.length > 0
      ? !suggestions.some((suggestion) => suggestion.value === tagValue)
      : false);

  const tagClassName = cx(className, { errorTag: tagIsError, disabled });
  const removeButtonClass = cx(classNameRemove, {
    disabled: disabled
  });
  return (
    <span key={key} className={tagClassName} {...other}>
      {getTagDisplayValue(tagLabel)}
      {disabled && disableReason ? (
        <IconWithTooltip
          className={removeButtonClass}
          icon={faTimesCircle}
          tooltipProps={{
            id: `tagDisableTip-${key}`,
            tooltip: i18n.t<string>(disableReason)
          }}
        />
      ) : (
        <a
          className={removeButtonClass}
          onClick={disabled ? undefined : clickCallback}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </a>
      )}
    </span>
  );
}

function defaultRenderInput (props) {
  let { onChange, value, addTag, ...other } = props;
  return (
    <input type='text' onChange={onChange} value={value} {...other} />
  );
}

function emptyInput (props) {
  return null;
}

const defaultProps = {
  value: [],
  onChange: _.noop,
  operate: '',
  addAction: _.noop,
  showPlus: false,
  disableInput: false,
  disabled: false,
  addOnBlur: true,
  suggestions: []
};

type TagsProps = {
  value: SelectOptions[] | string[];
  onChange: (tags: any[], changed: any[], changedIndexes: number[]) => void;
  disableInput?: boolean;
  operate?: any;
  addAction?: any;
  showPlus?: boolean;
  disabled?: boolean;
  disableReason?: string;
  addOnBlur?: boolean;
  suggestions?: SelectOptions[];
  placeholder?: string;
};

const Tags: React.FunctionComponent<
  TagsProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  value,
  onChange: handleChange,
  operate,
  addOnBlur,
  addAction,
  showPlus,
  placeholder,
  className: parentClassName,
  disableInput,
  disabled,
  disableReason,
  suggestions
}) => {
  const inputClass = cx({
    'react-tagsinput': true,
    [operate]: true,
    disabled
  });
  const containerClass = cx(parentClassName, {
    tagContainer: true
  });
  const needShowPlus = showPlus && !disabled;
  const buttonClass = cx('limitaionPlus', {
    showPlus: needShowPlus,
    noshowPlus: !needShowPlus
  });
  const renderInput = disableInput ? emptyInput : defaultRenderInput;
  const clickFun = useCallback((e) => {
    if (typeof e.target.className !== 'object' && e.target.className !== 'react-tagsinput-tag') {
      addAction(e);
    }
  }, [addAction]);

  const [inputValue, setInputValue] = React.useState('');
  const [showSuggestions, setShowSuggestions] = React.useState(false);

  const filteredSuggestions = useMemo(() => {
    if (!suggestions) {
      return [];
    }
    return suggestions.filter((suggestion) => {
      const isExist = value.some((item) => item.toString() === suggestion.value.toString());
      if (isExist) {
        return false;
      }
      return suggestion.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        suggestion.value.toString().toLowerCase().includes(inputValue.toLowerCase());
    });
  }, [value, inputValue, suggestions]);

  const renderSuggestions = useCallback(() => {
    return filteredSuggestions.map((suggestion) => {
      // onMouseDown's default event is blur event,
      // invoke preventDefault to prevent onBlur event to be triggered
      const onMouseDown = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const newValue: any = _.uniq([...value, suggestion.value]);
        handleChange(newValue);
        setInputValue('');
        setShowSuggestions(false);
      };
      return (
        <NavDropdown.Item
          key={suggestion.value}
          onMouseDown={onMouseDown}
        >
          {suggestion.label}
        </NavDropdown.Item>
      );
    });
  }, [value, filteredSuggestions, handleChange]);

  const onChangeInput = useCallback((input) => {
    setShowSuggestions(true);
    setInputValue(input);
  }, []);

  const triggerSuggestionMenu = useCallback((e) => {
    if (e.target.className === 'react-tagsinput-input') {
      setShowSuggestions(prev => !prev);
    }
  }, []);

  const closeSuggestionsMenu = useCallback(() => {
    if (!_.isEmpty(inputValue)) {
      const newValue: any = _.uniq([...value, inputValue]);
      handleChange(newValue);
      setInputValue('');
    }
    setShowSuggestions(false);
  }, [inputValue, value, handleChange]);

  return (
    <div
      className={containerClass}
      onClick={disabled ? undefined : clickFun}
      onMouseDown={triggerSuggestionMenu}
      onBlur={closeSuggestionsMenu}
    >
      <TagsInput
        value={value}
        onChange={handleChange}
        renderTag={_.partial(defaultRenderTag, disableReason, suggestions)}
        className={inputClass}
        inputProps={{ placeholder }}
        renderInput={renderInput}
        addOnBlur={addOnBlur}
        disabled={disabled}
        inputValue={inputValue}
        onChangeInput={onChangeInput}
      />
      <Button variant='link' className={buttonClass} onClick={addAction}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      {filteredSuggestions.length > 0 &&
        <>
          <NavDropdown id='suggestions' title='' show={showSuggestions}>
            {renderSuggestions()}
          </NavDropdown>
          <FontAwesomeIcon icon={faCaretDown} className={styles.caretDown}/>
        </>
      }
    </div>
  );
};

Tags.defaultProps = defaultProps;

export default Tags;
