import { SelectOptions } from 'components/commonType';
import { ProductFilterWebService, RestfulProductFilterWebService } from 'ws/ProductFilterWebService';

export interface ProductFilterManager {
  getVendorNumberOptions (agencyId?: number, showVendorNumber?: boolean): Promise<SelectOptions[]>;
  getBrandOptionsByVendorNumbers (vendorNumbers?: string[]): Promise<SelectOptions[]>;
  getBrandOptionsByVendorNumber (vendorNumber: string): Promise<SelectOptions[]>;
  getCategories (vendorNumber: string, brand?: string): Promise<string[]>;
}

export class DefaultProductFilterManager implements ProductFilterManager {
  webService: ProductFilterWebService;

  constructor (webService: ProductFilterWebService = new RestfulProductFilterWebService()) {
    this.webService = webService;
  }

  async getVendorNumberOptions (agencyId?: number, showVendorNumber?: boolean): Promise<SelectOptions[]> {
    return this.webService.getVendorNumberOptions(agencyId, showVendorNumber);
  }

  async getBrandOptionsByVendorNumbers (vendorNumbers?: string[]): Promise<SelectOptions[]> {
    return (await this.webService.getBrandsByVendorNumbers(vendorNumbers)).map((brand) => ({
      label: brand,
      value: brand
    }));
  }

  async getBrandOptionsByVendorNumber (vendorNumber: string): Promise<SelectOptions[]> {
    return (await this.webService.getBrandsByVendorNumber(vendorNumber)).map((brand) => ({
      label: brand,
      value: brand
    }));
  }

  async getCategories (vendorNumber: string, brand?: string): Promise<string[]> {
    return this.webService.getCategories(vendorNumber, brand);
  }
}
