import React from 'react';
import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import styles from './rtbCampaignSummaryStep.module.scss';
import { RtbCampaignSummaryStepProps, RtbCampaignSummaryStepState } from './RtbCampaignSummaryStepModel';
import { Button, Tabs, Tab } from 'react-bootstrap';
import i18n from 'i18n';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ImagePreview } from 'components/Image/ImagePreview';
import { alertMessage } from 'components/AlertDialog';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { PmpSummary } from './PmpSummary';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { DefaultRtbCampaignBasicFormValidator } from '../RtbCampaignForm/RtbCampaignBasicFormValidator';
import { CreateRtbCampaignSetupFlowStep } from '../RtbCampaignSetupFlowStep';
import _ from 'lodash';
import warningIcon from 'assets/warning.svg';
import { SaveTAModal } from './SaveTAModal';

enum CampaignSummaryTab {
  CAMPAIGN,
  CREATIVE
}

export class RtbCampaignSummaryStep extends React.Component<RtbCampaignSummaryStepProps, RtbCampaignSummaryStepState & {
  creativeLoading?: boolean,
  creativeSummaryData?: any
}> {

  handler?: number;
  creativeSummaryDataHanlder?: number;

  constructor (props) {
    super(props);
    this.state = props.model.state;
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.initCreativeSummary();
    this.props.model.flowModel.fetchCampaignAnalytics();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      if (prevProps.model.creativeSummaryModel) {
        prevProps.model.creativeSummaryModel.event.remove(this.creativeSummaryDataHanlder);
      }
      this.initCreativeSummary();
      this.props.model.flowModel.fetchCampaignAnalytics();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
    this.creativeSummaryDataHanlder && this.props.model.creativeSummaryModel && this.props.model.creativeSummaryModel.event.remove(this.creativeSummaryDataHanlder);
  }

  initCreativeSummary () {
    if (this.props.model.creativeSummaryModel) {
      this.creativeSummaryDataHanlder = this.props.model.creativeSummaryModel.event.add(model => {
        this.setState(state => ({
          ...state,
          creativeLoading: model.state.loading,
          creativeSummaryData: model.state.basicSummaryData
        }));
      });
      this.props.model.creativeSummaryModel.initSummaryData();
    }
  }

  renderSummaryDetails = (data) => {
    const details = Object.keys(data).map((key) => {
      if (!data[key]) {
        return <div key={key}/>;
      }
      const content = data[key].content;
      const fieldsWithPostText = content.filter((field: any) => field.postText);
      fieldsWithPostText.forEach((field: any) => {
        field.postComponent = (
          <div className={styles.campaignAnalyticsHint}>
            <img
              src={warningIcon}
              alt='warning'
            />
            {field.postText}
          </div>
        );
      });
      return (
        <SummaryDetail
          key={key}
          title={data[key].title}
          data={content}
          prefixColor={data[key].titlePrefixColor}
        />
      );
    });
    return <div>{details}</div>;
  }

  renderSummaryTitle = (summaryData) => {
    return (
      <div className={styles.categoryTitle}>
        {summaryData.title}
        { summaryData.backStep !== undefined &&
          <div className={styles.backBtn} onClick={this.props.model.goStep.bind(undefined, summaryData.backStep, summaryData.backSubStep)}>
            {i18n.t<string>('campaignSummary.labels.backButtonTitle')}
          </div>
        }
      </div>
    );
  }

  renderSummary = (summaryData, renderOtherInfo?: any) => {
    if (!summaryData) {
      return <div/>;
    }
    return (
      <section>
        {this.renderSummaryTitle(summaryData)}
        <div className={styles.summaryDataContent}>
          {this.renderSummaryDetails(summaryData.data)}
          {this.renderSmartHelperData(summaryData)}
        </div>
        {renderOtherInfo && renderOtherInfo()}
      </section>
    );
  }

  renderImageSummary = (summaryData) => {
    const imageData = summaryData.data.imageData;
    return (
      <div>
        {this.renderSummaryTitle(summaryData)}
        <ImagePreview
          size={{ width: 150, height: 150 }}
          url={imageData.url}
          file={imageData.file}
          config={summaryData.data.config}
        />
      </div>
    );
  }

  renderMediaSummary (summaryData) {
    if (!this.props.model.creativeSummaryModel) {
      return <div/>;
    }
    const MediaSummary = this.props.model.creativeSummaryModel.mediaSummaryComponent;
    if (!MediaSummary) {
      return <div />;
    }
    return (
      <div>
        {this.renderSummaryTitle(summaryData)}
        <MediaSummary mediaSummary={summaryData.data}/>
      </div>
    );
  }

  renderSmartHelperData = (summaryData) => {
    const model = this.props.model;
    const smartHelperData = model.getSmartHelperData();
    if (!summaryData || summaryData.title !== i18n.t<string>('campaignSummary.titles.basicSetting') || _.isEmpty(smartHelperData)) {
      return undefined;
    }
    return (
      <div className={styles.smartHelperData}>
        <div className={styles.smartHelperTitle}>
          {i18n.t<string>('campaignSummary.titles.smartHelper')}
        </div>
        <div className={styles.smartHelperContent}>
          {smartHelperData.map((data, index) => (
            <div key={index} className={styles.dataBlock}>
              <div className={styles.blockTitle}>
                {data.title}
              </div>
              <div className={styles.blockContent}>
                {data.value}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.saveTaHint}>
          {i18n.t<string>('campaignSummary.labels.saveTargeting')}
        </div>
        <button
          className={'btn btn-secondary btn-sm'}
          onClick={_.partial(model.onShowAddSaveTargetingModal, true)}
        >
          {i18n.t<string>('campaignSummary.buttons.saveTargeting')}
        </button>
      </div>
    );
  }

  renderCampaignSummary (errors) {
    const model = this.props.model;
    const isPmp = model.flowModel.isPmp;
    return (
      <>
        <div className={styles.title}>
          {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
        </div>
        <div className={styles.content}>
          {this.renderSummary(model.getAdTypeSummaryData())}
          {
            isPmp ?
            <PmpSummary
              spaceOptions={model.flowModel.state.pmpSpaceOptions}
              pmp={model.flowModel.state.pmp!}
              errors={errors}
              renderSummary={this.renderSummary}
            /> :
            <>
              <div className={styles.basicSummary}>
                {this.renderSummary(model.getSetUpSummaryData())}
              </div>
              <div className={styles.limitationSummary}>
                {this.renderSummary(model.getLimitationsSummaryData())}
              </div>
            </>
          }
        </div>
      </>
    );
  }

  render () {

    const onTabSelect = (tab) => {
      if (tab === null) {
        return;
      }
      model.goSubStep(+tab);
    };

    const model = this.props.model;
    const hasCreative = !!model.creativeSummaryModel;
    const submit = () => model.submit();
    const campaign = model.flowModel.state.campaign;
    const l1Object = model.flowModel.l1Object;
    const objectType = model.flowModel.objectType;
    const addCreative = model.flowModel.addCreative;
    const submitAndAddCreative = () => model.submit(addCreative);
    const canGoAddCreativePage = campaign.basic.adType !== AdType.KEYWORD && addCreative;
    const validator = new DefaultRtbCampaignBasicFormValidator(
      'create',
      campaign,
      () => ({ min: 1 }),
      model.flowModel.addonFeatureManager,
      () => [RtbCampaignPlanType.FCPM, RtbCampaignPlanType.FCPC],
      (priceModel: RtbCampaignPlanType) => {
        if (priceModel === RtbCampaignPlanType.FCPM) return [L2ObjectOptimizationGoal.IMPRESSIONS];
        if (priceModel === RtbCampaignPlanType.FCPC) return [L2ObjectOptimizationGoal.CLICKS];
        return [];
      }
    );

    const errors = validator.validate(l1Object, campaign.basic, model.flowModel.order, false);
    const hasError = !_.isEmpty(errors) && (errors.dayRange || errors.budget);
    const mediaSummaryData = _.defaultTo(model.creativeSummaryModel?.mediaSummaryData, {});
    return (
      <div className={styles.container}>
        {(model.state.loading || this.state.creativeLoading) && <LoadingIndicator recordEveryTime/>}
        {model.state.showPublishBindingFailed && alertMessage(
          i18n.t<string>('common.warning'), i18n.t<string>('campaignSetupFlow.messages.publishBindingFailed'), model.redirectToLastPage
        )}
        {model.state.showAddSaveTargetingModal && (
          <SaveTAModal
            campaign={model.flowModel.state.campaign}
            savedTargetingList={model.flowModel.state.savedTargetingList}
            closeAddSaveTargetingModal={model.onShowAddSaveTargetingModal.bind(undefined, false)}
          />
        )}
        {
          hasCreative ?
            <Tabs
              id='campaign-summary-tab'
              activeKey={model.subStepIndex}
              onSelect={onTabSelect}
            >
              <Tab eventKey={CampaignSummaryTab.CAMPAIGN} title={i18n.t<string>('stepSideBar.labels.campaignSettingSummary')}>
                {this.renderCampaignSummary(errors)}
              </Tab>
              {this.state.creativeSummaryData &&
                <Tab eventKey={CampaignSummaryTab.CREATIVE} title={i18n.t<string>('stepSideBar.labels.creativeSettingSummary')}>
                  <div className={styles.title}>
                    {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
                  </div>
                  <div className={styles.content}>
                    {
                      this.renderSummary({
                        ...this.state.creativeSummaryData,
                        backStep: CreateRtbCampaignSetupFlowStep.SETUP_CREATIVE
                      })
                    }
                    {this.renderMediaSummary({
                      ...mediaSummaryData,
                      backStep: CreateRtbCampaignSetupFlowStep.SETUP_CREATIVE
                    })}
                  </div>
                </Tab>
              }
            </Tabs> :
            this.renderCampaignSummary(errors)
        }
        <div className={styles.buttonArea}>
          {hasCreative ?
            model.subStepIndex === CampaignSummaryTab.CREATIVE ?
              <Button disabled={hasError} variant='primary' style={{ marginRight: '20px' }} size='sm' onClick={submit}>
                {i18n.t<string>('common.buttons.submit')}
              </Button> :
              <Button disabled={hasError} variant='primary' style={{ marginRight: '20px' }} size='sm' onClick={_.partial(model.goSubStep, CampaignSummaryTab.CREATIVE)}>
                {i18n.t<string>('common.buttons.nextPage')}
              </Button> :
            <>
              <Button variant='primary' size='sm' onClick={submit}>
                {objectType === 'campaign' ? i18n.t<string>('common.buttons.submit') : i18n.t<string>('common.buttons.publish')}
              </Button>
              {canGoAddCreativePage && (
                <Button
                  variant='secondary'
                  size='sm'
                  onClick={submitAndAddCreative}
                >
                  {i18n.t<string>('campaign.buttons.saveAndBindCreative')}
                </Button>
              )}
            </>
          }
          <Button variant='secondary' size='sm' onClick={model.goLast}>
            {i18n.t<string>('campaign.buttons.back')}
          </Button>
        </div>
      </div>
    );
  }
}
