import { AxiosInstance } from 'axios';
import client from './RestClient';
import { SelectOptions } from 'components/commonType';

export interface ProductFilterWebService {
  getVendorNumberOptions (agencyId?: number, showVendorNumber?: boolean): Promise<SelectOptions[]>;
  getBrandsByVendorNumbers (vendorNumbers?: string[]): Promise<string[]>;
  getBrandsByVendorNumber (vendorNumber: string): Promise<string[]>;
  getCategories (vendorNumber: string, brand?: string): Promise<string[]>;
}

export class RestfulProductFilterWebService implements ProductFilterWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getVendorNumberOptions (agencyId?: number, showVendorNumber: boolean = false): Promise<SelectOptions[]> {
    const response = await this.restClient.get(
      '/productFilter/vendors',
      agencyId
        ? {
          params: {
            agencyId
          }
        }
      : undefined
    );
    return showVendorNumber
      ? response.data.records.map((option) => ({
        label: option.value,
        value: option.value
      }))
      : response.data.records;
  }

  async getBrandsByVendorNumbers (vendorNumbers?: string[]): Promise<string[]> {
    const response = await this.restClient.get(
      '/productFilter/vendors/brands',
      vendorNumbers
        ? {
          params: {
            vendorNumbers: vendorNumbers.join(',')
          }
        }
        : undefined
    );
    return response.data.records;
  }

  async getBrandsByVendorNumber (vendorNumber: string): Promise<string[]> {
    const response = await this.restClient.get(`/productFilter/vendors/${vendorNumber}/brands`);
    return response.data.records;
  }

  async getCategories (vendorNumber: string, brand?: string): Promise<string[]> {
    if (brand) {
      const response = await this.restClient.get(`/productFilter/vendors/${vendorNumber}/brands/${brand}/categories`);
      return response.data.records;
    }
    const response = await this.restClient.get(`/productFilter/vendors/${vendorNumber}/categories`);
    return response.data.records;
  }
}
