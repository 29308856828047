import { EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { Product, SuggestKeywords } from 'core/product/Product';
import { useCallAPI } from 'hooks/useCallAPI';
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './defaultSearchKeywordsComponent.module.scss';
import i18n from 'i18n';
import { Form } from 'react-bootstrap';
import Tags from 'components/Tags/Tags';
import { SelectOptions } from 'components/commonType';
import classnames from 'classnames/bind';
import { compact, every, flatten, flow, get, intersection, partial, trim, uniq, xor } from 'lodash';
import { LoadingIndicator } from 'components/LoadingIndicator';
import closeSmallIcon from 'assets/close-small.svg';
import addIcon from 'assets/add.svg';
import { StickableBootstrapTable } from 'components/Table/StickableBootstrapTable';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import formatters from './listFormatters';
import { ProductListOverview } from 'containers/RetailRMN/Products/ProductListOverview';
import { getLimitationValueContent } from 'utils/LimitationUtil';
import { OPERATE } from 'enum/Operate';
import { KeywordsProposalsModal } from './KeywordsProposalsModal';
import Button from 'components/Button/Button';

const cx = classnames.bind(styles);

export type SearchKeywordsComponentProps = {
  limitationModel: EditLimitationModel;
  products: Product[];
  adRequestSourceManager?: AdRequestSourceManager;
};

export enum SuggestKeywordsListColumns {
  SUGGEST_KEYWORD = 'suggestKeyword',
  BID_PRICE = 'bidPrice',
  KEYWORD_SEARCH_VOLUME = 'keywordSearchVolume',
  LAST_7_DAY_GROWTH_RATE = 'last7DayGrowthRate',
  LAST_30_DAY_GROWTH_RATE = 'last30DayGrowthRate',
  KEYWORD_AD_COMPETITION = 'keywordAdCompetition'
}

const defaultAdRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();

export const SearchKeywordsComponent: React.FC<SearchKeywordsComponentProps> = ({
  limitationModel,
  products,
  adRequestSourceManager = defaultAdRequestSourceManager
}) => {

  const keywordInputRef = useRef<HTMLInputElement>(null);
  const searchKeywords = getLimitationValueContent(limitationModel.limitationValue, OPERATE.INCLUDE, 'searchKeywords'); // 自訂的關鍵字
  const recommendedKeywords = getLimitationValueContent(limitationModel.limitationValue, OPERATE.INCLUDE, 'recommendedKeywords'); // 系統推薦的關鍵字
  const initKeywords = uniq([...searchKeywords, ...recommendedKeywords]);
  const [errors, setErrors] = useState<any>(limitationModel.state.errors);
  const [inited, setInited] = useState<boolean>(every([initKeywords.length > 0, get(errors, `${OPERATE.INCLUDE}.recommendedKeywords`) === undefined], Boolean));
  const [keywords, setKeywords] = useState<string[]>(initKeywords);
  const [trendingKeywords, setTrendingKeywords] = useState<string[]>([]);
  const [productsHotKeywords, setProductsHotKeywords] = useState<SuggestKeywords[]>([]);
  const [showInputProposalsModal, setShowInputProposalsModal] = useState<boolean>(false);
  const { callAPIs, loading } = useCallAPI();

  const preselectedKeywords = useMemo(() => {
    return uniq([
      ...intersection(productsHotKeywords.map(keywordData => keywordData.suggestKeyword), keywords),
      ...intersection(trendingKeywords, keywords)
    ]);
  }, [productsHotKeywords, trendingKeywords, keywords]);

  const inputKeywords = useMemo(() => {
    return xor(preselectedKeywords, keywords);
  }, [preselectedKeywords, keywords]);

  const productHotKeywordsVersion = useMemo(() => {
    return productsHotKeywords.length > 0 ? productsHotKeywords[0].keywordVersion : '';
  }, [productsHotKeywords]);

  const uniqKeywords = useCallback((list: SuggestKeywords[]) => {
    const uniqMap: {[key: string]: SuggestKeywords} = {};
    list.forEach(suggestKeyword => {
      const { suggestKeyword: keyword, bidPrice } = suggestKeyword;
      const existSuggestKeyword = uniqMap[keyword];
      if (!existSuggestKeyword || (existSuggestKeyword && +(existSuggestKeyword.bidPrice) < +bidPrice)) {
        uniqMap[keyword] = suggestKeyword;
      }
    });
    return [...Object.values(uniqMap)];
  }, []);

  const fetchTrendingKeywords = useCallback(async () => {
    callAPIs([
      () => (adRequestSourceManager.getTrendingKeywords())
    ], (trendingKeywords: string[]) => {
      const getTrendingKeywords = flow([compact, uniq]);
      setTrendingKeywords(getTrendingKeywords(trendingKeywords));
    }, (error: any) => {
      console.error('fetchTrendingKeywords error', error);
      setTrendingKeywords([]);
    });
  }, [callAPIs, adRequestSourceManager]);

  const fetchProductHotKeywords = useCallback(async () => {
    callAPIs([
      () => adRequestSourceManager.getProductHotKeywords(products.map(product => product.productId))
    ], (productsHotKeywords: SuggestKeywords[]) => {
      const pickFirst200 = (list: SuggestKeywords[]) => list.slice(0, 200);
      const getSuggestKeywords = flow([compact, flatten, uniqKeywords, pickFirst200]);
      setProductsHotKeywords(getSuggestKeywords(productsHotKeywords));
    }, (error: any) => {
      console.error('fetchProductHotKeywords error', error);
      setProductsHotKeywords([]);
    });
  }, [products, adRequestSourceManager, uniqKeywords, callAPIs]);

  const inputTagsValue = useMemo(() => {
    return inputKeywords.map(keyword => ({ value: keyword, label: keyword }));
  }, [inputKeywords]);

  const preselectedTagsValue = useMemo(() => {
    return preselectedKeywords.map(keyword => ({ value: keyword, label: keyword, extra: { version: productHotKeywordsVersion } }));
  }, [preselectedKeywords, productHotKeywordsVersion]);

  const firstMount = useRef(true);
  const isComposing = useRef(false);

  useEffect(() => {
    // only do when dependencies change
    if (firstMount.current) {
      return;
    }
    if (!inited) {
      const pickFirst30 = (list: SuggestKeywords[]) => list.slice(0, 30);
      const getSuggestKeywords = flow([uniqKeywords, pickFirst30]);
      const keywords = getSuggestKeywords(productsHotKeywords).map(keyword => keyword.suggestKeyword);
      setInited(true);
      setKeywords(prevKeywords => uniq([...prevKeywords, ...keywords]));
    }
  }, [inited, productsHotKeywords, uniqKeywords]);

  useEffect(() => {
    // only do when dependencies change
    if (firstMount.current || !inited) {
      return;
    }
    limitationModel.setLimitation(OPERATE.INCLUDE, 'searchKeywords', inputTagsValue);
    limitationModel.setLimitation(OPERATE.INCLUDE, 'recommendedKeywords', preselectedTagsValue);
  }, [inited, limitationModel, inputTagsValue, preselectedTagsValue]);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
  }, []);

  useEffect(() => {
    fetchTrendingKeywords();
  }, [fetchTrendingKeywords]);

  useEffect(() => {
    fetchProductHotKeywords();
  }, [fetchProductHotKeywords]);

  const addKeyword = useCallback((searchKeywords: string[]): void => {
    setKeywords(keywords => uniq([...keywords, ...searchKeywords]));
  }, []);

  const handleAddSearchString = useCallback((): void => {
    const inputRef = keywordInputRef.current;
    if (!inputRef) {
      return;
    }
    addKeyword(compact(inputRef.value.split(',').map(trim)));
    inputRef.value = '';
  }, [addKeyword]);

  const handleRemoveAllInputKeywords = useCallback((): void => {
    setKeywords(preselectedKeywords);
  }, [preselectedKeywords]);

  const handleRemoveAllPreselectedKeywords = useCallback((): void => {
    setKeywords(inputKeywords);
  }, [inputKeywords]);

  const handleOnInputTagsChange = useCallback((value) => {
    setKeywords([...value.map((option: SelectOptions) => option.value), ...preselectedKeywords]);
  }, [preselectedKeywords]);

  const handleOnPreselectedTagsChange = useCallback((value) => {
    setKeywords([...value.map((option: SelectOptions) => option.value), ...inputKeywords]);
  }, [inputKeywords]);

  const handleOnClickCol = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target instanceof HTMLElement && e.target.className !== 'object' && e.target.className !== 'react-tagsinput-tag') {
      keywordInputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    const handler = limitationModel.event.add(model => {
      setErrors(model.state.errors);
    });
    return () => {
      limitationModel.event.remove(handler);
    };
  }, [limitationModel]);

  const renderTrendingKeywordsList = useMemo(() => {
    const filteredTrendingKeywords: string[] = trendingKeywords.filter(keyword => !keywords.includes(keyword));
    return (
      <div className={styles.trendingKeywordsListArea}>
        <div className={styles.keywordsListArea}>
          <div className={styles.descript}>
            {i18n.t<string>('limitation.labels.trendingKeywordsDes')}
          </div>
          <div className={styles.keywordsList}>
            {filteredTrendingKeywords.map((keyword, index) => (
              <div key={index} className={styles.capsule} onClick={partial(addKeyword, [keyword])}>
                <span>{keyword}</span>
                <img
                  src={addIcon}
                  alt='addTrendingKeyword'
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }, [trendingKeywords, keywords, addKeyword]);

  const renderSuggestKeywordsList = useMemo(() => {
    const filteredProductsHotKeywords: SuggestKeywords[] = productsHotKeywords.filter(keywordData => !keywords.includes(keywordData.suggestKeyword));
    const filteredKeywords: string[] = filteredProductsHotKeywords.map(keywordData => keywordData.suggestKeyword);
    const onClickAll = () => addKeyword(filteredKeywords);
    const columnDefinition = (columnName, sortable: boolean = false, customLabel?: string): ColumnDefinition => ({
      ...sortableColumn(columnName, customLabel ? customLabel : `suggestKeywordsList.labels.${columnName}`, sortable),
      classes: () => styles[columnName],
      headerClasses : () => styles[columnName]
    });
    const keywordColumn = renderColumn({
      ...columnDefinition(SuggestKeywordsListColumns.SUGGEST_KEYWORD),
      formatExtraData: {
        onClick: (searchKeyword: string) => addKeyword([searchKeyword])
      }
    },
    formatters.nameFormatter,
    partial(
      formatters.nameHeaderFormatter,
      filteredKeywords.length > 0,
      onClickAll
    ));
    const columns = compact([
      keywordColumn,
      renderColumn(columnDefinition(SuggestKeywordsListColumns.BID_PRICE), formatters.bidPriceFormatter),
      renderColumn(columnDefinition(SuggestKeywordsListColumns.KEYWORD_SEARCH_VOLUME), formatters.keywordSearchVolumeFormatter),
      renderColumn(columnDefinition(SuggestKeywordsListColumns.LAST_7_DAY_GROWTH_RATE), formatters.growthRateFormatter),
      renderColumn(columnDefinition(SuggestKeywordsListColumns.LAST_30_DAY_GROWTH_RATE), formatters.growthRateFormatter),
      renderColumn(columnDefinition(SuggestKeywordsListColumns.KEYWORD_AD_COMPETITION), formatters.keywordAdCompetitionFormatter)
    ]);
    return (
      <div className={styles.suggestKeywordsListArea}>
        {loading && <LoadingIndicator />}
        <div className={styles.contentArea}>
          <StickableBootstrapTable
            stickFirstColumn
            scrollByElementControl={false}
            keyField={SuggestKeywordsListColumns.SUGGEST_KEYWORD}
            data={filteredProductsHotKeywords}
            columns={columns}
            noDataHint={'limitation.labels.suggestKeywordsNoDataHint'}
            remote={true}
            hidePagination
          />
        </div>
      </div>
    );
  }, [loading, addKeyword, keywords, productsHotKeywords]);

  const inputKeywordError = get(errors, `${OPERATE.INCLUDE}.searchKeywords`);
  const preselectedKeywordError = get(errors, `${OPERATE.INCLUDE}.recommendedKeywords`);
  const tagsClassName = useMemo(() => cx(styles.tags), []);
  const inputColClassName = useMemo(() => cx(styles.col, {
    hasError: inputKeywordError !== undefined
  }), [inputKeywordError]);
  const preselectedColClassName = useMemo(() => cx(styles.col, {
    hasError: preselectedKeywordError !== undefined
  }), [preselectedKeywordError]);

  const handleComposition = useCallback((composition: boolean) => {
    isComposing.current = composition;
  }, []);

  const onEnterPress = useCallback((e: KeyboardEvent<HTMLInputElement>): void => {
    if (isComposing.current) return; // Prevent triggering during IME composition
    if (e.key.toLowerCase() === 'enter' && e.shiftKey === false) {
      e.preventDefault();
      handleAddSearchString();
    }
  }, [handleAddSearchString]);

  const renderErrorTip = useCallback((error: string | Array<string>) => {
    if (error) {
      if (Array.isArray(error)) {
        return error.map((err, index) => <div key={index} className={'errorTip'}>{err}</div>);
      } else {
        return <div className={'errorTip'}>{error}</div>;
      }
    }
  }, []);

  return (
    <fieldset>
      {(!inited && loading) && <LoadingIndicator/>}
      <legend>
        <span>{i18n.t<string>('limitation.labels.selectedProducts')}</span>
      </legend>
      <div className={styles.selectedProductsArea}>
        <ProductListOverview products={products} />
      </div>
      <legend>
        <span>{i18n.t<string>('limitation.labels.searchKeywordsTitle')}</span>
      </legend>
      <div className={styles.keywordSettingContainer}>
        <div className={styles.inputArea}>
          <div className={styles.descript}>
            {i18n.t<string>('limitation.labels.searchKeywordsInputDes')}
          </div>
          <div className={inputColClassName} onClick={handleOnClickCol}>
            {inputTagsValue.length > 0 &&
              <Tags
                className={tagsClassName}
                value={inputTagsValue}
                onChange={handleOnInputTagsChange}
              />
            }
            <Form.Control
              ref={keywordInputRef}
              type='text'
              name='searchKeywords'
              autoComplete='off'
              onKeyDown={onEnterPress}
              onCompositionStart={partial(handleComposition, true)}
              onCompositionEnd={partial(handleComposition, false)}
              placeholder={i18n.t<string>('limitation.placeholders.searchKeywords')}
            />
            <div className={styles.clearButton} onClick={handleRemoveAllInputKeywords}>
              <img src={closeSmallIcon} alt={'close'}/>
            </div>
          </div>
          {renderErrorTip(inputKeywordError)}
          <div className={styles.keywordsProposalsArea}>
            <Button
              className={styles.proposalsButton}
              secondary
              disabled={loading || inputKeywords.length === 0}
              onClick={partial(setShowInputProposalsModal, true)}
            >
              {i18n.t<string>('limitation.buttons.keywordsProposals')}
            </Button>
            {
              inputKeywords.length > 0 &&
              <span className={styles.keywordsProposalsDes}>
                {i18n.t<string>('limitation.labels.keywordsProposalsDes')}
              </span>
            }
          </div>
        </div>
        <div className={styles.preselectArea}>
          <div className={styles.titleArea}>
            <div className={styles.title}>
              {i18n.t<string>('limitation.labels.suggestKeywordsTitle')}
            </div>
            <div className={styles.subTitle}>
              {i18n.t<string>('limitation.labels.suggestKeywordsSubTitle')}
            </div>
          </div>
          <div className={preselectedColClassName}>
            {preselectedTagsValue.length > 0 &&
              <Tags
                className={tagsClassName}
                value={preselectedTagsValue}
                onChange={handleOnPreselectedTagsChange}
              />
            }
            <div className={styles.clearButton} onClick={handleRemoveAllPreselectedKeywords}>
              <img src={closeSmallIcon} alt={'close'}/>
            </div>
          </div>
          {renderErrorTip(preselectedKeywordError)}
        </div>
        {renderTrendingKeywordsList}
        {renderSuggestKeywordsList}
      </div>
      {showInputProposalsModal &&
        <KeywordsProposalsModal
          dispatchShowModal={setShowInputProposalsModal}
          addKeyword={addKeyword}
          inputKeywords={inputKeywords}
          preselectedKeywords={preselectedKeywords}
          trendingKeywords={trendingKeywords}
          adRequestSourceManager={defaultAdRequestSourceManager}
        />
      }
    </fieldset>
  );
};
