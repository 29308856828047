import { useState, useEffect, useCallback, useContext } from 'react';
import { ProductFilter } from './ProductFilter';
import { Product } from 'core/product/Product';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import formatters from './listFormatters';
import _, { camelCase } from 'lodash';
import styles from './productList.module.scss';
import { ProductListDataContext, ProductListDataContextType } from './ProductListDataContext';
import i18n from 'i18n';

export enum ProductListColumns {
  ID = 'productId',
  NAME = 'name',
  IMAGE = 'imgLink',
  CATEGORY = 'category',
  LISTPRICE = 'listPrice',
  AVAILABILITY = 'availability'
}

export enum ProductListType {
  BASIC = 'basic'
}

export type ProductListState = {
  readonly selectedProductListType: ProductListType;
};

export const useProductListModel = (
  filterSet: ProductFilter,
  productList: Product[],
  allProducts: Product[]
) => {

  const productListDataContext: ProductListDataContextType = useContext(ProductListDataContext);
  const { selectedProducts: initSelectedProducts, handleSelectedProducts, handleFilterChanged } = productListDataContext;
  const availbleProducts = _.uniqBy(allProducts.concat(initSelectedProducts), 'productId');
  const [selectedProducts, setSelectedProducts] = useState<string[]>(initSelectedProducts.map(product => product.productId));
  const [state, setState] = useState({
    selectedProductListType: ProductListType.BASIC
  } as ProductListState);

  useEffect(() => {
    handleFilterChanged(filterSet);
  }, [filterSet, handleFilterChanged]);

  const columnDefinition = (columnName, sortable: boolean = false, customLabel?: string): ColumnDefinition => ({
    ...sortableColumn(columnName, customLabel ? customLabel : `productList.labels.${columnName}`, sortable),
    classes: () => styles[columnName],
    headerClasses : () => styles[columnName]
  });

  const onSelect = (productId: string) => {
    const product = availbleProducts.find(product => product.productId === productId);
    if (!product) {
      return;
    }
    if (selectedProducts.indexOf(productId) > -1) {
      setSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(id => id !== productId));
      handleSelectedProducts(prevSelectedProducts => prevSelectedProducts.filter(product => product.productId !== productId));
    } else {
      setSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, productId]);
      handleSelectedProducts(prevSelectedProducts => [...prevSelectedProducts, product]);
    }
  };

  const selectProductsAfterFilter = selectedProducts.filter(id => productList.find(product => product.productId === id));

  const onSelectAll = () => {
    if (selectProductsAfterFilter.length === productList.length) {
      const newSelectedProducts = selectedProducts.filter(id => !productList.find(product => product.productId === id));
      setSelectedProducts(newSelectedProducts);
      handleSelectedProducts(selectedProducts => selectedProducts.filter(selectedProduct => !productList.find(product => product.productId === selectedProduct.productId)));
    } else {
      const newSelectedProducts = _.uniq([...selectedProducts, ...productList.map(product => product.productId)]);
      setSelectedProducts(newSelectedProducts);
      handleSelectedProducts(selectedProducts => _.uniqBy([...selectedProducts, ...productList], 'productId'));
    }
  };

  const handleRemoveSelect = useCallback(() => {
    setSelectedProducts([]);
    handleSelectedProducts(() => []);
  }, [setSelectedProducts, handleSelectedProducts]);

  const onProductListTypeChange = (selectedProductListType) => {
    setState(prevState => ({ ...prevState, selectedProductListType }));
  };

  const idColumn = renderColumn({
    ...columnDefinition(ProductListColumns.ID),
    formatExtraData: {
      // currentUrl: match.url,
      selectedProducts,
      onSelect
    }
  },
  formatters.nameFormatter,
  _.partial(
    formatters.nameHeaderFormatter,
    productList.length,
    selectProductsAfterFilter,
    selectedProducts.length > selectProductsAfterFilter.length,
    onSelectAll
  ));

  const basicColumns = _.compact([
    idColumn,
    renderColumn(columnDefinition(ProductListColumns.IMAGE), formatters.imageFormatter),
    renderColumn(columnDefinition(ProductListColumns.CATEGORY)),
    renderColumn(columnDefinition(ProductListColumns.LISTPRICE)),
    renderColumn(columnDefinition(ProductListColumns.AVAILABILITY), value => i18n.t<string>(`productList.labels.${camelCase(value)}`))
  ]);

  const typeColumnsMap = {
    [ProductListType.BASIC]: basicColumns
  };

  return {
    state,
    selectedProducts,
    availbleProducts,
    columns: typeColumnsMap[state.selectedProductListType],
    onProductListTypeChange,
    handleRemoveSelect,
    handleRemoveProduct: onSelect
  };
};
