import { AdType, LegacyOptimize } from 'core/rtbCampaign/RtbCampaign';

export enum BidPriceConstraintType {
  AUTO_BID_CAP = 'AUTO_BID_CAP',
  BID_FLOOR = 'BID_FLOOR'
}

export type BidAdjustmentJob = {
  id: number;
  scheduledTime: string;
  finished: boolean;
  purpose: string;
  creator: string;
  vendorNumbers?: string[];
} & BidAdjustment;

export type BidAdjustment = {
  bidPrice: number;
  adType: AdType;
  constraintType: BidPriceConstraintType;
  optimizeType: LegacyOptimize;
};

export enum ChooseVendorStrategy {
  ALL = 'all',
  PARTIAL = 'partial'
}

export type BidAdjustmentJobFormData = {
  scheduledTime: string;
  purpose: string;
  vendorNumbers: string[];
  isInstant: boolean;
  chooseVendorStrategy: ChooseVendorStrategy;
  adjustments: BidAdjustment[];
};
