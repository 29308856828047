import display from 'assets/campaign/adType-display.png';
import display2x from 'assets/campaign/adType-display@2x.png';
import { useCallback, useMemo, useState } from 'react';
import { CreateRtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import styles from './chooseCreatingMethodStep.module.scss';
import i18n from 'i18n';
import { CampaignAdTypeButton } from '../CampaignAdTypeButton/CampaignAdTypeButton';
import { DefaultCampaignAdTypeButtonModel } from '../CampaignAdTypeButton/CampaignAdTypeButtonModel';
import { SavedTAManagement } from 'containers/Limitations/SavedTAManagement/SavedTAManagement';

export type ChooseCreatingMethodStepProps = {
  campaignSetupFlowPageModel: CreateRtbCampaignSetupFlowPageModel;
  goNext: () => void;
};

export const ChooseCreatingMethodStep: React.FC<ChooseCreatingMethodStepProps> = ({
  campaignSetupFlowPageModel,
  goNext
}) => {

  const [showTAManagement, setShowTAManagement] = useState(false);

  const importButtonModel = useMemo(() => {
    return new DefaultCampaignAdTypeButtonModel(
      {
        icon: display,
        icon2x: display2x,
        title: i18n.t<string>('campaign.buttons.importSavedTargeting'),
        description: i18n.t<string>(
          'campaign.buttons.importSavedTargetingDescription'
        )
      },
      false,
      () => {
        setShowTAManagement(true);
      }
    );
  }, []);

  const createNewButtonModel = useMemo(() => {
    return new DefaultCampaignAdTypeButtonModel(
      {
        icon: display,
        icon2x: display2x,
        title: i18n.t<string>('campaign.buttons.createNewButton'),
        description: i18n.t<string>(
          'campaign.buttons.createNewButtonDescription'
        )
      },
      false,
      () => {
        campaignSetupFlowPageModel.createFromScratch();
        goNext();
      }
    );
  }, [campaignSetupFlowPageModel, goNext]);

  const renderButtons = useCallback(() => {
    return (
      <>
        <CampaignAdTypeButton model={createNewButtonModel}/>
        <CampaignAdTypeButton model={importButtonModel}/>
      </>
    );
  }, [importButtonModel, createNewButtonModel]);

  const onSavedTAManagementClose = useCallback(async (needRefreshSavedTargeting: boolean) => {
    if (needRefreshSavedTargeting) {
      await campaignSetupFlowPageModel.refreshSavedKeywordTargetings();
    }
    setShowTAManagement(false);
  }, [campaignSetupFlowPageModel]);

  return (
    <div className={styles.chooseCreatingMethodStep}>
      <div className={styles.title}>
        {i18n.t<string>('campaign.labels.creatingMethodPageTitle')}
      </div>
      <div className={styles.buttonArea}>{renderButtons()}</div>
      {showTAManagement && (
        <SavedTAManagement
          savedTargetingList={campaignSetupFlowPageModel.savedTargetingList}
          onClose={onSavedTAManagementClose}
          onImport={campaignSetupFlowPageModel.createFromSavedTargeting}
          getLimitationsSummaryData={campaignSetupFlowPageModel.getLimitationsSummaryData}
          goNext={goNext}
        />
      )}
    </div>
  );
};
